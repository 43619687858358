import SchemePanel from '../../../assets/svgs/SchemePanel';
import SchemeDataLabel from '../../../assets/svgs/SchemeDataLabel';
import Tooltip from '../Tooltip';
import Box from '@mui/material/Box';
import SchemeRightArrow from '../../../assets/svgs/SchemeRightArrow';
import SchemeHouse from '../../../assets/svgs/SchemeHouse';
import SchemeLeftArrow from '../../../assets/svgs/SchemeLeftArrow';
import SchemeTower from '../../../assets/svgs/SchemeTower';
import React from 'react';
import { makeStyles } from '@mui/styles';

const SvgScheme = props => {
    const classes = useStyles();

    return (
        <div className={classes.scheme}>
            <div className={classes.schemeLayer}>
                <div style={{ height: 55 }} />
                <SchemePanel width={props.width} height={props.height} />
                <SchemeDataLabel
                    width={props.width}
                    height={55}
                    title={'Production'}
                    data={props.homeNetworkStats.prodPV}
                />
            </div>
            <div className={classes.arrowLayer}>
                <div
                    style={{
                        height: '20%',
                    }}
                />
                <Tooltip title={'Autoconsommation'}>
                    <Box
                        sx={{
                            height: '20%',
                        }}
                    >
                        <SchemeRightArrow
                            width={props.arrowWidth}
                            color={props.arrowColor}
                        />
                    </Box>
                </Tooltip>
            </div>
            <div className={classes.schemeLayer}>
                <Tooltip
                    title={
                        'Le taux d’autoconsommation est le rapport entre l’électricité produite par vos panneaux solaires immédiatement consommée et le total de la production d’électricité autoproduite.\n' +
                        'Plus ce taux est élevé, plus vous optimisez votre production d’électricité pour alimenter votre domicile et donc plus vous faites des économies sur vos factures d’électricité'
                    }
                >
                    <div>
                        <SchemeDataLabel
                            width={props.width}
                            height={55}
                            title={'AutoConsommation'}
                            data={`${
                                props.homeNetworkStats.autoConsoValue || 0
                            } kWh - ${
                                props.homeNetworkStats.autoConsoRate || 0
                            }%`}
                            noUnit
                        />
                    </div>
                </Tooltip>
                <SchemeHouse width={props.width} height={props.height} />
                <SchemeDataLabel
                    width={props.width}
                    height={55}
                    title={'Consommation'}
                    data={props.homeNetworkStats.consoFoyer}
                />
            </div>
            <div className={classes.arrowLayer}>
                <Tooltip title={'Vers Foyer'}>
                    <Box
                        sx={{
                            height: '20%',
                        }}
                    >
                        <div style={{ transform: 'scaleX(-1)' }}>
                            <SchemeLeftArrow
                                width={props.arrowWidth}
                                color={props.arrowColor}
                            />
                        </div>
                    </Box>
                </Tooltip>
                <Tooltip title={'Renvoi'}>
                    <Box
                        sx={{
                            height: '20%',
                        }}
                    >
                        <div style={{ transform: 'scaleX(-1)' }}>
                            <SchemeRightArrow
                                width={props.arrowWidth}
                                color={props.arrowColor}
                            />
                        </div>
                    </Box>
                </Tooltip>
            </div>
            <div className={classes.schemeLayer}>
                <SchemeDataLabel
                    width={props.width}
                    height={55}
                    title={'Renvoi'}
                    data={props.homeNetworkStats.exported}
                />
                <SchemeTower width={props.width} height={props.height} />
                <SchemeDataLabel
                    width={props.width}
                    height={55}
                    title={'Fournisseur'}
                    data={props.homeNetworkStats.imported}
                />
            </div>
        </div>
    );
};
const useStyles = makeStyles(theme => ({
    scheme: {
        display: 'flex',
        alignSelf: 'center',
    },
    schemeLayer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        height: 250,
        justifyContent: 'space-evenly',
        marginLeft: 4,
        marginRight: 4,
    },
    arrowLayer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
        marginTop: 24,
    },
}));

export default SvgScheme;
