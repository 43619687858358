import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { getPlanningEventId } from 'utils';
import api from '../../data/planning/api';

const adapter = createEntityAdapter({
    selectId: task => getPlanningEventId(task),
});
const selectors = adapter.getSelectors(({ planningEvents }) => planningEvents);

const slice = createSlice({
    name: 'planningEvents',
    initialState: adapter.getInitialState(),
    reducers: {
        setAll: adapter.setAll,
        updateOne: adapter.updateOne,
        updateMany: adapter.updateMany,
        removeOne: adapter.removeOne,
        removeMany: adapter.removeMany,
        setLastEvents: (state, action) => {
            state.lastEvents = action.payload;
        },
    },
});

export const planningEventsActions = slice.actions;
export const { setLastEvents } = slice.actions;
export const planningEventsReducer = slice.reducer;

export const selectEventsByProduct = (state, product) => {
    let productEvents = [];

    Object.values(state.planningEvents.entities).map(planningEvent => {
        if (planningEvent.product_identifier === product.product_identifier) {
            return productEvents.push(planningEvent);
        }
        return null;
    });
    return productEvents;
};

export const selectLastEvents = state => ({
    lastEvents: state.planningEvents.lastEvents,
});
export const planningEventsSelectors = { ...selectors };

export const getPlanningEvents = () => async dispatch => {
    const planningEvents = await api.all();

    dispatch(planningEventsActions.setAll(planningEvents.planning_events));
};
