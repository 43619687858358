import React from 'react';
import Grid from 'data/components/Grid';
import { makeStyles } from '@mui/styles';

export default function InputWrapper({ children, ...props }) {
    const classes = useStyle();
    return (
        <Grid item xs={6} {...props}>
            <div className={classes.container}>{children}</div>
        </Grid>
    );
}

const useStyle = makeStyles({
    container: {
        height: 48,
        padding: '0 8px',
        marginBottom: 8,
    },
});
