import React, { useState } from 'react';
import InputWrapper from '../InputWrapper';
import InputSwitch from 'data/components/InputSwitch';
import Grid from 'data/components/Grid';
import t from 'utils/Translator';

export default function InputSecurityState({ formik }) {
    const [value, setValue] = useState(false);

    const handleChange = ({ target: { checked } }) => {
        formik.setFieldValue('security_state', checked ? 'ON' : 'OFF');
        setValue(checked);
    };

    return (
        <InputWrapper>
            <Grid
                container
                alignItems="flex-end"
                justifyContent="space-between"
            >
                <div>{t.get('inputs.securityState')}</div>
                <InputSwitch
                    name="security_state"
                    label="security_state"
                    onChange={handleChange}
                    value={value}
                    checked={value}
                />
            </Grid>
        </InputWrapper>
    );
}
