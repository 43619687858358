import ListItem from 'data/components/ListItem';
import { makeStyles } from '@mui/styles';
import { setSwitchState } from 'MiddlewareSocket/emitters/widgets';
import Grid from 'data/components/Grid';
import React from 'react';
import ProductLabel from '../ProductLabel';
import { hasPowerData } from '../utils/productPredicates';
import GroupInputSwitch from 'data/components/ProductsList/ProductsGroup/components/GroupInputSwitch';
import { useTheme } from '@mui/material/styles';

export default function Switch({ product }) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <ListItem>
            <Grid container alignItems="center">
                <Grid item xs={7}>
                    <ProductLabel product={product} />
                </Grid>
                <Grid item xs={5} className={classes.container}>
                    <Grid
                        container
                        justifyContent="flex-end"
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            {hasPowerData(product) &&
                                product.value === 'ON' && (
                                    <div style={{ color: theme.palette.primary.main }}>
                                        {product.power ?? 0} W
                                    </div>
                                )}
                        </Grid>
                        <Grid item>
                            <GroupInputSwitch
                                product={product}
                                func={setSwitchState}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}

const useStyles = makeStyles(theme => ({
    container: {},
}));
