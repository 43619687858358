import React from 'react';
import { TextField } from '@mui/material';
import InputWrapper from '../InputWrapper';
import { makeStyles } from '@mui/styles';
import t from 'utils/Translator';

export default function InputYear({ formik }) {
    const classes = useStyle();
    return (
        <InputWrapper>
            <TextField
                fullWidth
                className={classes.input}
                type="number"
                name="year"
                label={t.get('inputs.year')}
                value={formik.values.year}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </InputWrapper>
    );
}

const useStyle = makeStyles({
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
});
