import React from 'react';
import InputWrapper from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/InputWrapper';
import { TextField } from '@mui/material';
import Button from 'data/components/Button';
import { modulesLinkRemove } from 'store/products/thunks';
import { useDispatch, useSelector } from 'react-redux';
import FormDivider from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/FormDivider';
import t from 'utils/Translator';

export default function UnlinkModule({ product }) {
    const master = useSelector(
        state => state.products.entities[product.master_from_master_slave],
    );
    const dispatch = useDispatch();

    const handleClick = async () => {
        await dispatch(
            modulesLinkRemove({
                product,
            }),
        );
    };
    return (
        <>
            <FormDivider />
            <InputWrapper>
                <TextField
                    disabled
                    fullWidth
                    name="ass"
                    label="Master"
                    value={master.label}
                />
            </InputWrapper>
            <InputWrapper>
                <Button onClick={handleClick} fullWidth variant="contained">
                    {t.get('inputs.unlink')}
                </Button>
            </InputWrapper>
        </>
    );
}
