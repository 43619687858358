import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

const SchemeDataLabel = props => {
    const classes = useStyles(props);
    return (
        <Box className={classes.container}>
            {props.title}
            <Box className={classes.content}>
                <Typography className={classes.data}>
                    {props.data || 0} {!props.noUnit && 'kWh'}
                </Typography>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: theme.palette.primary.main,
        height: props => props.height,
        width: props => props.width,
        paddingBottom: 4,
        marginTop: 12,
        margin: 4,
    },
    content: {
        backgroundColor: '#fff',
        height: '70%',
        width: '95%',
    },
    data: {
        color: '#000',
        textAlign: 'center',
    },
}));

SchemeDataLabel.propTypes = propTypes;
SchemeDataLabel.defaultProps = defaultProps;

export default SchemeDataLabel;
