import React, { useState } from 'react';
import SidePanel from 'data/components/SidePanel';
import PlanningEvents from './PlanningEvents';
import Scenarios from './Scenarios';
import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';
import ProductsListContainer from 'data/components/ProductsListContainer';
import { selectBoxStatus } from '../../../store/box/boxSlice';
import { makeStyles } from '@mui/styles';

export default function Dashboard({ sidePanelContainerRef }) {
    const classes = useStyles();
    const [sidePanelIsOpen, setSidePanelIsOpen] = useState(true);
    const boxStatus = useSelector(selectBoxStatus);

    const productsByCategories = useSelector(
        productsSelectors.selectProductsByCategories,
        () => true,
    );
    const productsCategories =
        productsByCategories[0] &&
        Object.assign({}, productsByCategories[0].groups);

    const filteredGroups = useSelector(
        productsSelectors.selectProductsByFavorites,
        () => true,
    );

    return (
        <>
            {boxStatus !== 'connected' && (
                <div className={classes.boxDisconnected} />
            )}

            <ProductsListContainer
                filteredGroups={filteredGroups}
                sidePanelIsOpen={sidePanelIsOpen}
                setSidePanelIsOpen={setSidePanelIsOpen}
                sidePanelContainerRef={sidePanelContainerRef}
                dashboard
            />
            {boxStatus === 'connected' && (
                <SidePanel
                    sidePanelContainerRef={sidePanelContainerRef}
                    sidePanelIsOpen={sidePanelIsOpen}
                    setSidePanelIsOpen={setSidePanelIsOpen}
                >
                    <Scenarios productsCategories={productsCategories} />
                    <PlanningEvents />
                </SidePanel>
            )}
        </>
    );
}

const useStyles = makeStyles(theme => ({
    boxDisconnected: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.background.default,
        opacity: 0.7,
        width: '100%',
        height: '100%',
    },
}));
