import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const OppositionIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 244 300">
            <g fill={props.color}>
                <path d="M93.29,300C52.56,300,5.14,290.52,0,216.59l0-.49V84.54a24.09,24.09,0,0,1,38.75-19.1V48.26A24.06,24.06,0,0,1,77.44,29.15V26.49a26.5,26.5,0,0,1,53,0v13.6A26.47,26.47,0,0,1,174,60.35v96.08a88.52,88.52,0,0,1,21.56-21.1c8.8-6,19.75-9.53,29.3-9.56a18.22,18.22,0,0,1,16.09,8.9h0c4.34,7.28,3.48,17.36,1.22,21.54C239.54,161,236.46,166,231.52,170c-3.85,3.14-8,8.71-11.67,15.67-1.84,3.5-11.95,19.49-30,47.78-5.88,9.24-11,17.24-12.92,20.37C161,282.3,128.93,300,93.29,300Zm-79-84.15C16.3,244.19,25,263.18,41,273.91c15.54,10.46,36.12,11.8,52.31,11.8,30.53,0,57.85-14.95,71.29-39l.17-.28c1.92-3.1,7.09-11.23,13.08-20.65,10.94-17.17,27.46-43.13,29.39-46.78,4.62-8.74,9.9-15.68,15.27-20.06,3.1-2.54,5.14-5.95,6.94-9.26.41-1.36.61-5.32-.79-7.68a3.9,3.9,0,0,0-3.79-1.93c-6.6,0-15,2.82-21.32,7.12s-15.57,12.29-23.41,26A17.45,17.45,0,0,1,168,181.81l-8.15,1.3-.12-8.25c0-.52,0-1.1,0-1.67v-.81c0-1.41,0-3,0-4.73V60.35a12.21,12.21,0,0,0-24.42,0v75a9.56,9.56,0,1,1-19.12,0V26.49a12.21,12.21,0,0,0-24.41,0V135.33a9.56,9.56,0,0,1-19.12,0V48.26a9.79,9.79,0,0,0-19.58,0V145a9.56,9.56,0,0,1-19.12,0V84.54a9.81,9.81,0,0,0-19.62,0Z" />
            </g>
        </svg>
    );
};

OppositionIcon.propTypes = propTypes;
OppositionIcon.defaultProps = defaultProps;

export default OppositionIcon;
