import moment from 'moment';
import 'moment/locale/fr';
import Translator from './js-translator';

moment.locale('fr');

const t = new Translator();

t.load('fr', require('../lang/fr.json'));

export const translateError = (path, params) =>
    t.get(path, params, 'label.error');

export default t;
