import Panel from './Panel';
import RightArrow from './RightArrow';
import ElectricTower from './ElectricTower';
import Home from './Home';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Text from '../Text';
import PropTypes from 'prop-types';
import { withTooltip } from '../../../utils';
import { selectGeneratorsIds } from '../../../store/generators/generatorSlice';
import { useSelector } from 'react-redux';

const propTypes = {
    color: PropTypes.string,
    homeNetworkStats: PropTypes.shape({}),
};

const defaultProps = {
    color: '#fff',
    homeNetworkStats: {
        prodPV: 0,
        autoConsoRate: 0,
        autoConsoValue: 0,
        exported: 0,
        imported: 0,
        exportType: 'renvoi',
        consoFoyer: 0,
    },
};

const Scheme = props => {
    const classes = useStyles(props);
    const generators = useSelector(selectGeneratorsIds);

    return generators.length >= 1 ? (
        <div className={classes.scheme}>
            <div className={classes.firstLayer}>
                {withTooltip(
                    <div className={classes.panel}>
                        <Panel height={25} width={35} color={props.color} />
                        <Text className={classes.text}>
                            {props.homeNetworkStats.prodPV || 0} kWh
                        </Text>
                    </div>,
                    'Production PV',
                )}

                {withTooltip(
                    <div className={classes.electricTower}>
                        <ElectricTower
                            height={25}
                            width={35}
                            color={props.color}
                        />
                        <Text className={classes.text}>
                            {props.homeNetworkStats.imported || 0} kWh
                        </Text>
                    </div>,
                    'Fournisseur',
                )}
            </div>
            <div className={classes.secondLayer}>
                <div className={classes.upperLeftArrow}>
                    <RightArrow height={20} width={40} color={props.color} />
                </div>
                <div className={classes.upperRightArrow}>
                    <RightArrow height={20} width={40} color={props.color} />
                </div>
            </div>
            {withTooltip(
                <div className={classes.thirdLayer}>
                    <Home height={30} width={40} color={props.color} />
                    <Text className={classes.text}>
                        {props.homeNetworkStats.consoFoyer || 0} kWh
                    </Text>
                </div>,
                'Consommation électrique',
            )}

            <div className={classes.fourthLayer}>
                <div className={classes.downArrow}>
                    <RightArrow height={20} width={40} color={props.color} />
                </div>
            </div>
            {withTooltip(
                <div className={classes.lastTower}>
                    <ElectricTower height={25} width={35} color={props.color} />
                    <Text className={classes.text}>
                        {props.homeNetworkStats.exported || 0} kWh
                    </Text>
                </div>,
                'Renvoi / Revente',
            )}
        </div>
    ) : (
        withTooltip(
            <div className={classes.thirdLayer}>
                <Home height={80} width={80} color={props.color} />
                <Text className={classes.text}>
                    {props.homeNetworkStats.consoFoyer || 0} kWh
                </Text>
            </div>,
            'Consommation électrique',
        )
    );
};

const useStyles = makeStyles(theme => ({
    scheme: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 4,
    },
    firstLayer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    secondLayer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
    },
    thirdLayer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 8,
    },
    fourthLayer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: 8,
        marginBottom: 8,
    },
    upperLeftArrow: {
        transform: 'rotate(60deg)',
        margin: 4,
        marginRight: 4,
    },
    upperRightArrow: {
        transform: 'rotate(120deg)',
        margin: 4,
        marginRight: 4,
    },
    downArrow: {
        transform: 'rotate(90deg)',
        marginBottom: 4,
        marginRight: 4,
    },
    lastTower: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    panel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    electricTower: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontWeight: 800,
        fontSize: 15,
        color: props => (props.header ? '#fff' : theme.palette.text.primary),
    },
}));

Scheme.propTypes = propTypes;
Scheme.defaultProps = defaultProps;

export default Scheme;
