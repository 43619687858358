import React, { useEffect, useState } from 'react';
import { setSwitchState } from 'MiddlewareSocket/emitters/widgets';
import Select from '../Select';
import { useAsync } from 'utils/hooks/useAsync';
import { isDisconnected } from 'utils';

export default function SelectMode({ product }) {
    const { execute, error, isLoading } = useAsync(setSwitchState);
    const [value, setValue] = useState('OFF');

    useEffect(() => {
        setValue(product.value || 'OFF');
    }, [product.value]);

    useEffect(() => {
        if (error) {
            setValue(product.value);
        }
    }, [error]);

    const handleChange = async ({ target: { value } }) => {
        setValue(value);
        await execute(product, value);
    };
    return (
        <Select
            disabled={isLoading || isDisconnected(product)}
            onChange={handleChange}
            value={value}
            options={[
                { value: 'HG', label: 'Hors-gel' },
                { value: 'ON', label: 'Confort' },
                { value: 'ECO', label: 'Eco' },
                { value: 'OFF', label: 'OFF' },
            ]}
        />
    );
}
