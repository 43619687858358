import TemperaturesIcon from '../../../assets/svgs/TemperaturesIcon';
import ShuttersIcon from '../../../assets/svgs/ShuttersIcon';
import SecuritiesIcon from '../../../assets/svgs/SecuritiesIcon';
import LightingIcon from '../../../assets/svgs/LightingIcon';
import SwitchesIcon from '../../../assets/svgs/SwitchesIcon';
import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '../ListItem';
import ListItemText from '../ListItemText';

const groups = {
    shutterstore: {
        icon: <ShuttersIcon color={'black'} width={40} height={40} />,
    },
    switch: {
        icon: <SwitchesIcon color={'black'} width={40} height={40} />,
    },
    ecs: {
        icon: <SwitchesIcon color={'black'} width={40} height={40} />,
    },
    lighting: {
        icon: <LightingIcon color={'black'} width={40} height={40} />,
    },
    thermostat: {
        icon: <TemperaturesIcon color={'black'} width={40} height={40} />,
    },
    ventilation: {
        icon: <TemperaturesIcon color={'black'} width={40} height={40} />,
    },
    security: {
        icon: <SecuritiesIcon color={'black'} width={40} height={40} />,
    },
    socket: {
        icon: <SwitchesIcon color={'black'} width={40} height={40} />,
    },
};

const ProductIcon = props => {
    const classes = useStyles();

    const Product = groups[props.product.module_type]?.icon;

    return (
        <ListItem style={classes.container}>
            <ListItemAvatar children={Product} style={classes.icon} />
            <ListItemText primary={props.product.label} style={classes.label} />
        </ListItem>
    );
};

const useStyles = theme => ({
    container: {
        height: 100,
        width: 120,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    label: {
        textAlign: 'center',
    },
});

export default ProductIcon;
