import { createTheme } from '@mui/material/styles';

const light = createTheme({
    palette: {
        primary: {
            main: '#d62e1d',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#111',
        },
        mode: 'light',
    },
});

const dark = createTheme({
    palette: {
        primary: {
            main: '#d62e1d',
        },
        secondary: {
            main: '#fff',
        },
        dark: {
            main: '#111',
        },
        background: {
            default: '#444',
        },
        text: {
            primary: '#fff',
        },
        backgroundImage: {
            color: '#0000',
        },
        mode: 'dark',
    },
});

const themes = {
    light,
    dark,
};

export default themes;
