import { getModuleKey } from 'utils';

export const getForm = ({ formik, product }) => {
    const touchedKeys = Object.keys(formik.touched);
    const values = touchedKeys.reduce((acc, key) => {
        acc[key] = formik.values[key];
        if (key === 'remote_ref') acc['remote'] = formik.values['remote'];
        return acc;
    }, {});

    return {
        product_identifier: product.product_identifier,
        product_module_code: product.code,
        product_type: product.product_type,
        product_module: values,
    };
};

export const formatPayload = payload => {
    return Object.entries(payload).reduce((acc, [key, value]) => {
        if (value?.last_data?.instant?.watt) {
            acc[key].instant = value.last_data.instant.watt;
        }
        return acc;
    }, payload);
};

export const getClampCurrentSlaveIds = (entities, master) => {
    const masterKey = getModuleKey(master);
    return Object.entries(entities).reduce(
        (acc, [key, { id, clamp_master }]) => {
            if (clamp_master === masterKey && id !== master.id) {
                acc.push(id);
            }
            return acc;
        },
        [],
    );
};

export const getModuleCurrentSlaveIds = (entities, master) => {
    const masterKey = getModuleKey(master);
    return Object.entries(entities).reduce(
        (acc, [key, { id, master_from_master_slave }]) => {
            if (master_from_master_slave === masterKey && id !== master.id) {
                acc.push(id);
            }
            return acc;
        },
        [],
    );
};
export const getProbeCurrentSlaveIds = (entities, master) => {
    const masterKey = getModuleKey(master);
    return Object.entries(entities).reduce(
        (acc, [key, { id, thermostatic_master }]) => {
            if (thermostatic_master === masterKey && id !== master.id) {
                acc.push(id);
            }
            return acc;
        },
        [],
    );
};

export const removeClampSlaves = (state, master) => {
    const masterKey = getModuleKey(master);
    return Object.entries(state.entities).reduce((acc, [key, product]) => {
        if (product.clamp_master === masterKey && key !== masterKey) {
            state.entities[key].clamp_master = null;
        }
        return acc;
    }, []);
};

export const addClampSlaves = (state, master, slaveKeys) => {
    slaveKeys.forEach(key => {
        state.entities[key].clamp_master = getModuleKey(master);
    });
};

export const handleIsMasterClamp = (state, master, slaveKeys) => {
    const masterKey = getModuleKey(master);
    if (slaveKeys.length > 0) {
        state.entities[masterKey] = {
            ...master,
            is_master_clamp: true,
            clamp_master: masterKey,
        };
    } else {
        state.entities[masterKey] = {
            ...master,
            is_master_clamp: false,
            clamp_master: null,
        };
    }
};

export const removeModuleSlaves = (state, master) => {
    const masterKey = getModuleKey(master);
    return Object.entries(state.entities).reduce((acc, [key, product]) => {
        if (
            product.master_from_master_slave === masterKey &&
            key !== masterKey
        ) {
            state.entities[key].master_from_master_slave = null;
        }
        return acc;
    }, []);
};

export const addModuleSlaves = (state, master, slaveKeys) => {
    slaveKeys.forEach(key => {
        state.entities[key].master_from_master_slave = getModuleKey(master);
    });
};

export const handleIsMasterModule = (state, master, slaveKeys) => {
    const masterKey = getModuleKey(master);
    if (slaveKeys.length > 0) {
        state.entities[masterKey] = {
            ...master,
            is_master_from_master_slave: true,
            master_from_master_slave: masterKey,
        };
    } else {
        state.entities[masterKey] = {
            ...master,
            is_master_from_master_slave: false,
            master_from_master_slave: null,
        };
    }
};

export const removeProbeSlaves = (state, master) => {
    const masterKey = getModuleKey(master);
    return Object.entries(state.entities).reduce((acc, [key, product]) => {
        if (product.thermostatic_master === masterKey && key !== masterKey) {
            state.entities[key].thermostatic_master = null;
        }
        return acc;
    }, []);
};

export const addProbeSlaves = (state, master, slaveKeys) => {
    slaveKeys.forEach(key => {
        state.entities[key].thermostatic_master = getModuleKey(master);
    });
};

export const handleIsMasterProbe = (state, master, slaveKeys) => {
    const masterKey = getModuleKey(master);
    if (slaveKeys.length > 0) {
        state.entities[masterKey] = {
            ...master,
            is_master_thermostatic: true,
            thermostatic_master: masterKey,
        };
    } else {
        state.entities[masterKey] = {
            ...master,
            is_master_thermostatic: false,
            thermostatic_master: null,
        };
    }
};

export const getSlaveIdsFromKeys = (entities, slaveKeys) => {
    return slaveKeys.map(key => entities[key].id);
};

export const getMasterIdFromKey = (entities, masterKey) => {
    return entities[masterKey].id;
};

export const getMasterIdentifierFromKey = (entities, masterKey) => {
    return entities[masterKey].identifier;
};

const masterHasSlave = (state, masterKey) => {
    return Object.entries(state.entities).some(
        ([key, product]) =>
            product.clamp_master === masterKey && key !== masterKey,
    );
};
const moduleMasterHasSlave = (state, masterKey) => {
    return Object.entries(state.entities).some(
        ([key, product]) =>
            product.master_from_master_slave === masterKey && key !== masterKey,
    );
};
const probeMasterHasSlave = (state, masterKey) => {
    return Object.entries(state.entities).some(
        ([key, product]) =>
            product.thermostatic_master === masterKey && key !== masterKey,
    );
};

export const handlePending = state => {
    state.error = null;
    state.loading = true;
};

export const handleRejected = (state, action) => {
    state.error = action?.error?.message || 'error';
    state.loading = false;
};
export const handleFulfilledProductAPI = (state, action) => {
    const { product_module } = action.payload;
    state.entities[getModuleKey(product_module)] = product_module;
    state.loading = false;
};
export const handleFulfilledProductEvent = (state, action) => {
    const { changes, product } = action.payload;
    state.entities[getModuleKey(product)] = { ...product, ...changes };
    state.loading = false;
};
export const handleFulfilledClampsAdd = (state, action) => {
    const { master, slaveKeys } = action.payload;
    removeClampSlaves(state, master);
    addClampSlaves(state, master, slaveKeys);
    handleIsMasterClamp(state, master, slaveKeys);

    state.loading = false;
};
export const handleFulfilledClampsRemove = (state, action) => {
    const { product } = action.payload;
    const productKey = getModuleKey(product);
    const masterKey = state.entities[productKey].clamp_master;

    state.entities[productKey].clamp_master = null;

    if (!masterHasSlave(state, masterKey)) {
        state.entities[masterKey].clamp_master = null;
        state.entities[masterKey].is_master_clamp = false;
    }
    state.loading = false;
};

export const handleFulfilledModulesAdd = (state, action) => {
    const { master, slaveKeys } = action.payload;
    removeModuleSlaves(state, master);
    addModuleSlaves(state, master, slaveKeys);
    handleIsMasterModule(state, master, slaveKeys);

    state.loading = false;
};
export const handleFulfilledModulesRemove = (state, action) => {
    const { product } = action.payload;
    const productKey = getModuleKey(product);
    const masterKey = state.entities[productKey].master_from_master_slave;

    state.entities[productKey].master_from_master_slave = null;

    if (!moduleMasterHasSlave(state, masterKey)) {
        state.entities[masterKey].master_from_master_slave = null;
        state.entities[masterKey].is_master_from_master_slave = false;
    }
    state.loading = false;
};

export const handleFulfilledProbesAdd = (state, action) => {
    const { master, slaveKeys } = action.payload;
    removeProbeSlaves(state, master);
    addProbeSlaves(state, master, slaveKeys);
    handleIsMasterProbe(state, master, slaveKeys);

    state.loading = false;
};

export const handleFulfilledProbesRemove = (state, action) => {
    const { product } = action.payload;
    const productKey = getModuleKey(product);
    const masterKey = state.entities[productKey].thermostatic_master;

    state.entities[productKey].thermostatic_master = null;

    if (!probeMasterHasSlave(state, masterKey)) {
        state.entities[masterKey].thermostatic_master = null;
        state.entities[masterKey].is_master_thermostatic = false;
    }
    state.loading = false;
};
