import { FormControl as MUIFormControl, FormLabel } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

export default function FormControl({ children, label, ...props }) {
    const classes = useStyles();

    return (
        <MUIFormControl {...props}>
            <FormLabel color="primary" focused className={classes.label}>
                {label}
            </FormLabel>
            {children}
        </MUIFormControl>
    );
}

const useStyles = makeStyles(theme => ({
    label: {
        margin: '4px 0',
    },
}));
