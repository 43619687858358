/**
 * Renvoie la valeur contenue dans l'objet au chemin spécifié
 *
 * @param {Object} object          L'objet dans lequel aller chercher la valeur
 * @param {Array} keys          Un tableau de clés permettant de localiser dans l'objet la valeur recherchée
 * @param {*} defaultValue      Valeur à renvoyer si la valeur n'est pas définie
 *
 * @return {*} Valeur trouvée dans l'objet, ou $defaultValue
 */
const getIn = (object, keys, defaultValue = undefined) => {
    let target = object;

    for (const key of Object.values(keys)) {
        if (target && target[key] !== undefined) {
            target = target[key];
        } else {
            return defaultValue;
        }
    }

    return target;
};

module.exports = getIn;
