import MUIListItem from '@mui/material/ListItem';
import React from 'react';
import { makeStyles } from '@mui/styles';

export default function ListItem(props) {
    const classes = useStyles();
    return <MUIListItem className={classes.container} {...props} />;
}

const useStyles = makeStyles(theme => ({
    container: {
        height: 50,
    },
}));
