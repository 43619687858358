import MUITabs from '@mui/material/Tabs';
import MUITab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

export default function NavBarTabs({ tabs, ...props }) {
    const classes = useStyles();

    return (
        <MUITabs
            centered
            indicatorColor="primary"
            textColor="primary"
            {...props}
        >
            {tabs.map(tab => (
                <MUITab
                    key={tab.label}
                    label={
                        <Typography className={classes.label}>
                            {tab.label}
                        </Typography>
                    }
                    value={tab.value}
                    component={Link}
                    to={tab.value}
                    onClick={() => window.dispatchEvent(new Event('popstate'))}
                />
            ))}
        </MUITabs>
    );
}

const useStyles = makeStyles(theme => ({
    label: {
        color:
            theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.text.primary,
    },
}));
