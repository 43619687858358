import React from 'react';
import { getModuleKey } from 'utils';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';
import { modulesLinkAdd } from 'store/products/thunks';
import InputLinkProduct from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkProduct';
import t from 'utils/Translator';

const getSlaves = (products, product) => {
    return Object.entries(products).reduce((acc, [moduleKey, module]) => {
        if (
            getModuleKey(product) !== moduleKey &&
            module.master_from_master_slave === getModuleKey(product)
        ) {
            acc.push(moduleKey);
        }
        return acc;
    }, []);
};

export default function LinkModule({ product }) {
    const products = _.omit(
        useSelector(state => productsSelectors.selectModules(state, product)),
        [getModuleKey(product)],
    );
    const slaves = getSlaves(products, product);
    const optionIsDisabled = (products, moduleKey, masterKey) => {
        const { master_from_master_slave } = products[moduleKey];
        return (
            master_from_master_slave && master_from_master_slave !== masterKey
        );
    };

    return (
        <InputLinkProduct
            label={t.get('inputs.linkModules')}
            product={product}
            products={products}
            isMaster={product.is_master_from_master_slave}
            slaves={slaves}
            optionIsDisabled={optionIsDisabled}
            submitFunc={modulesLinkAdd}
        />
    );
}
