import React from 'react';
import PowerButtonIcon from 'assets/svgs/PowerButton';
import Button from '../Button';
import { useTheme } from '@mui/material/styles';

const ModuleStateSelector = props => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.container}>
            <Button onClick={() => props.onClick()}>
                <PowerButtonIcon
                    color={props.stateValue === 'ON' ? theme.palette.primary.main : 'red'}
                    width={40}
                    height={40}
                />
            </Button>
        </div>
    );
};

const useStyles = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'red',
        width: '100%',
    },
});

export default ModuleStateSelector;
