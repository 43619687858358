import React from 'react';
import InputWrapper from '../InputWrapper';
import Select from 'data/components/Select';
import t from 'utils/Translator';

export default function InputOperatingMode({ formik }) {
    return (
        <InputWrapper>
            <Select
                fullWidth
                id="operating_mode"
                name="operating_mode"
                label={t.get('inputs.operatingMode')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.operating_mode}
                options={[
                    { value: 'split', label: 'Split' },
                    { value: 'pac', label: 'PaC' },
                ]}
            />
        </InputWrapper>
    );
}
