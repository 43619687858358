import React from 'react';
import InputWrapper from '../InputWrapper';
import Grid from 'data/components/Grid';
import InputSwitch from 'data/components/InputSwitch';
import { TextField } from '@mui/material';
import t from 'utils/Translator';

export default function InputIdle({ formik }) {
    const toggle = formik.values.idle_toggle;
    return (
        <>
            <InputWrapper xs={toggle ? 12 : 6}>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    <div>{t.get('inputs.idle')}</div>
                    <InputSwitch
                        name="idle_toggle"
                        onChange={formik.handleChange}
                        value={toggle}
                        checked={toggle}
                    />
                </Grid>
            </InputWrapper>
            {toggle && (
                <>
                    <InputWrapper>
                        <TextField
                            fullWidth
                            name="idle_time"
                            label={t.get('inputs.idleTime')}
                            value={formik.values.idle_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TextField
                            fullWidth
                            name="idle_power"
                            label={t.get('inputs.idlePower')}
                            value={formik.values.idle_power}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </InputWrapper>
                </>
            )}
        </>
    );
}
