import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

const RightArrow = props => {
    return (
        <svg
            width={props.width}
            height={props.height}
            id="prefix__Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            {...props}
        >
            <defs>
                <style>{'.prefix__cls-1{fill:#898989}'}</style>
            </defs>
            <path
                fill={props.color}
                className="prefix__cls-1"
                d="M256 512C114.84 512 0 397.16 0 256S114.84 0 256 0s256 114.84 256 256-114.84 256-256 256zm0-500.62C121.12 11.38 11.38 121.12 11.38 256S121.12 500.62 256 500.62 500.62 390.88 500.62 256 390.88 11.38 256 11.38z"
            />
            <path
                fill={props.color}
                className="prefix__cls-1"
                d="M278.76 330a5.69 5.69 0 01-4-9.71L339 256l-64.24-64.24a5.69 5.69 0 018-8L351 252a5.69 5.69 0 010 8l-68.27 68.27a5.67 5.67 0 01-3.97 1.73z"
            />
            <path
                fill={props.color}
                className="prefix__cls-1"
                d="M347 261.69H119.47a5.69 5.69 0 010-11.38H347a5.69 5.69 0 010 11.38z"
            />
        </svg>
    );
};

RightArrow.propTypes = propTypes;
RightArrow.defaultProps = defaultProps;

export default RightArrow;
