import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api from '../../data/generators/api';

const adapter = createEntityAdapter();
const selectors = adapter.getSelectors(({ generators }) => generators);
export const slice = createSlice({
    name: 'generators',
    initialState: adapter.getInitialState(),
    reducers: {
        setAll: adapter.setAll,
        setOne: adapter.setOne,
        updateOne: adapter.updateOne,
        removeOne: adapter.removeOne,
    },
});

export const generatorsActions = slice.actions;
export const generatorsReducer = slice.reducer;
export const generatorsSelectors = { ...selectors };
export const selectGeneratorsIds = state => state.generators.ids;
export const selectGenerators = state => state.generators;

export const updateGenerator = (generatorId, generator) => async dispatch => {
    try {
        const updatedGenerator = await api.updateGenerator(
            generatorId,
            generator,
        );

        await dispatch(slice.reducers.updateOne(updatedGenerator));
    } catch (e) {
        console.log('an error occurred while updating user', e);
    }
};

export const createGenerator = generator => async dispatch => {
    try {
        const createdGenerator = await api.createGenerator(generator);

        await dispatch(slice.reducers.setOne(createdGenerator));
    } catch (e) {
        console.log('an error occurred while updating user', e);
    }
};

export const deleteGenerator = generatorId => async dispatch => {
    try {
        await api.deleteGenerator(generatorId);

        await dispatch(slice.reducers.removeOne(generatorId));
    } catch (e) {
        console.log('an error occurred while updating user', e);
    }
};
