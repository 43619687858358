import Modal from './Modal';
import ModalCGU from './modalCGU';
import ModalRGPD from './modalRGPD';
import React, { useEffect, useState } from 'react';
import useModal from '../../../utils/hooks/useModal';
import { getFromLS, setToLS } from 'utils/storage';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/auth/authSlice';

export default function Modals() {
    const user = useSelector(selectCurrentUser);
    const [CGUIsOpen, toggleCGU] = useModal();
    const [RGPDIsOpen, toggleRGPD] = useModal();
    const [logged, setLogged] = useState(true);

    useEffect(() => {
        if (user && !loggedOnce(user.id)) {
            setLogged(false);
            toggleCGU();
            toggleRGPD();
        }
    }, []);

    useEffect(() => {
        if (user && !logged && !CGUIsOpen && !RGPDIsOpen) {
            let loggedUsers = getFromLS('loggedOnce') || [];
            setToLS('loggedOnce', [...loggedUsers, user.id]);
        }
    }, [logged, CGUIsOpen, RGPDIsOpen]);

    const loggedOnce = username => {
        let loggedUsers = getFromLS('loggedOnce') || [];
        return loggedUsers.includes(username);
    };

    return (
        <>
            <Modal open={CGUIsOpen} closeFunction={toggleCGU}>
                <ModalCGU />
            </Modal>
            <Modal open={RGPDIsOpen} closeFunction={toggleRGPD}>
                <ModalRGPD />
            </Modal>
        </>
    );
}
