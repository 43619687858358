import React from 'react';
import InputWrapper from '../InputWrapper';
import { TextField } from '@mui/material';
import t from 'utils/Translator';

export default function InputConversionRate({ formik }) {
    return (
        <InputWrapper>
            <TextField
                fullWidth
                name="conversionRate"
                label={t.get('inputs.conversionRate')}
                value={formik.values.threshold}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </InputWrapper>
    );
}
