import Http from 'utils/Http';

const api = {
    getGenerators: () =>
        Http.get('generators/get-all').then(resp => {
            return resp.generators;
        }),
    createGenerator: generator =>
        Http.post('create-generator', {
            orientation: generator.orientation,
            power: generator.power,
            roof_pitch: generator.roof_pitch,
            module_type: generator.module_type,
        }).then(resp => {
            return resp;
        }),
    updateGenerator: (generatorId, generator) =>
        Http.post(`update-generator/${generatorId}`, {
            orientation: generator.orientation,
            power: generator.power,
            roof_pitch: generator.roof_pitch,
            module_type: generator.module_type,
        }).then(resp => {
            return resp;
        }),
    deleteGenerator: generatorId =>
        Http.post('delete-generator', { id: generatorId }).then(resp => {
            return resp;
        }),
};

export default api;
