import React from 'react';
import InputWrapper from '../InputWrapper';
import Select from 'data/components/Select';
import t from 'utils/Translator';

export default function InputMeasuresType({ formik }) {
    return (
        <InputWrapper>
            <Select
                fullWidth
                id="line_type_diversity"
                name="line_type_diversity"
                label={t.get('inputs.measuresType')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.line_type_diversity}
                options={[
                    { value: 'watt', label: 'watt' },
                    { value: 'water', label: 'water' },
                    { value: 'gas', label: 'gas' },
                ]}
            />
        </InputWrapper>
    );
}
