import MUITooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import React from 'react';

export default function Tooltip({ children, ...props }) {
    return (
        <MUITooltipWithStyles placement="top" {...props}>
            {children}
        </MUITooltipWithStyles>
    );
}

const MUITooltipWithStyles = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 14,
    },
}))(MUITooltip);
