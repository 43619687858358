import * as React from "react"
import PropTypes from "prop-types";

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

const SchemeHouse = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 114.89" {...props}>
        <g
            data-name="Calque 2"
            style={{
                isolation: "isolate",
            }}
        >
            <g data-name="Calque 1">
                <g
                    style={{
                        mixBlendMode: "multiply",
                    }}
                >
                    <path fill="#fff" d="M70 114.89 0 74.69l70-40.2 70 40.2-70 40.2z" />
                    <path
                        fill="#fefefe"
                        d="M70 114.81.14 74.69 70 34.57l69.86 40.12L70 114.81z"
                    />
                    <path
                        fill="#fcfcfc"
                        d="M70 114.73.28 74.69 70 34.65l69.73 40.04L70 114.73z"
                    />
                    <path
                        fill="#fbfbfb"
                        d="M70 114.65.41 74.69 70 34.73l69.59 39.96L70 114.65z"
                    />
                    <path
                        fill="#f9f9f9"
                        d="M70 114.57.55 74.69 70 34.81l69.45 39.88L70 114.57z"
                    />
                    <path
                        fill="#f8f7f8"
                        d="M70 114.49.69 74.69 70 34.88l69.31 39.81L70 114.49z"
                    />
                    <path
                        fill="#f7f6f6"
                        d="M70 114.41.82 74.69 70 34.96l69.18 39.73L70 114.41z"
                    />
                    <path
                        fill="#f5f4f5"
                        d="M70 114.33.96 74.69 70 35.04l69.04 39.65L70 114.33z"
                    />
                    <path
                        fill="#f4f3f3"
                        d="M70 114.25 1.1 74.69 70 35.12l68.9 39.57L70 114.25z"
                    />
                    <path
                        fill="#f2f1f2"
                        d="M70 114.17 1.24 74.69 70 35.2l68.76 39.49L70 114.17z"
                    />
                    <path
                        fill="#f1f0f0"
                        d="M70 114.1 1.38 74.69 70 35.28l68.63 39.41L70 114.1z"
                    />
                    <path
                        fill="#efeeef"
                        d="M70 114.02 1.51 74.69 70 35.36l68.49 39.33L70 114.02z"
                    />
                    <path
                        fill="#eeeced"
                        d="M70 113.94 1.65 74.69 70 35.44l68.35 39.25L70 113.94z"
                    />
                    <path
                        fill="#ecebec"
                        d="M70 113.86 1.79 74.69 70 35.52l68.21 39.17L70 113.86z"
                    />
                    <path
                        fill="#ebe9ea"
                        d="M70 113.78 1.93 74.69 70 35.6l68.08 39.09L70 113.78z"
                    />
                    <path
                        fill="#e9e8e9"
                        d="M70 113.7 2.06 74.69 70 35.68l67.94 39.01L70 113.7z"
                    />
                    <path
                        fill="#e8e6e7"
                        d="M70 113.62 2.2 74.69 70 35.76l67.8 38.93L70 113.62z"
                    />
                    <path
                        fill="#e6e5e6"
                        d="M70 113.54 2.34 74.69 70 35.83l67.66 38.86L70 113.54z"
                    />
                    <path
                        fill="#e5e3e4"
                        d="M70 113.47 2.48 74.69 70 35.91l67.53 38.78L70 113.47z"
                    />
                    <path
                        fill="#e3e2e3"
                        d="M70 113.39 2.61 74.69 70 35.99l67.39 38.7L70 113.39z"
                    />
                    <path
                        fill="#e2e0e1"
                        d="M70 113.31 2.75 74.69 70 36.07l67.25 38.62L70 113.31z"
                    />
                    <path
                        fill="#e0dfe0"
                        d="M70 113.23 2.89 74.69 70 36.15l67.11 38.54L70 113.23z"
                    />
                    <path
                        fill="#dfddde"
                        d="M70 113.15 3.03 74.69 70 36.23l66.97 38.46L70 113.15z"
                    />
                    <path
                        fill="#dedcdd"
                        d="M70 113.07 3.16 74.69 70 36.31l66.84 38.38L70 113.07z"
                    />
                    <path
                        fill="#dcdadb"
                        d="M70 112.99 3.3 74.69 70 36.39l66.7 38.3-66.7 38.3z"
                    />
                    <path
                        fill="#dbd9da"
                        d="M70 112.91 3.44 74.69 70 36.47l66.56 38.22L70 112.91z"
                    />
                    <path
                        fill="#d9d7d8"
                        d="M70 112.83 3.58 74.69 70 36.55l66.43 38.14L70 112.83z"
                    />
                    <path
                        fill="#d8d6d7"
                        d="M70 112.75 3.71 74.69 70 36.62l66.29 38.07L70 112.75z"
                    />
                    <path
                        fill="#d6d4d6"
                        d="M70 112.67 3.85 74.69 70 36.7l66.15 37.99L70 112.67z"
                    />
                    <path
                        fill="#d5d3d4"
                        d="M70 112.6 3.99 74.69 70 36.78l66.01 37.91L70 112.6z"
                    />
                    <path
                        fill="#d4d2d3"
                        d="M70 112.52 4.13 74.69 70 36.86l65.87 37.83L70 112.52z"
                    />
                    <path
                        fill="#d2d0d1"
                        d="M70 112.44 4.26 74.69 70 36.94l65.74 37.75L70 112.44z"
                    />
                    <path
                        fill="#d1cfd0"
                        d="M70 112.36 4.4 74.69 70 37.02l65.6 37.67L70 112.36z"
                    />
                    <path
                        fill="#d0cdce"
                        d="M70 112.28 4.54 74.69 70 37.1l65.46 37.59L70 112.28z"
                    />
                    <path
                        fill="#cecccd"
                        d="M70 112.2 4.68 74.69 70 37.18l65.32 37.51L70 112.2z"
                    />
                    <path
                        fill="#cdcbcc"
                        d="M70 112.12 4.82 74.69 70 37.26l65.19 37.43L70 112.12z"
                    />
                    <path
                        fill="#cbc9ca"
                        d="M70 112.04 4.95 74.69 70 37.34l65.05 37.35L70 112.04z"
                    />
                    <path
                        fill="#cac8c9"
                        d="M70 111.96 5.09 74.69 70 37.41l64.91 37.28L70 111.96z"
                    />
                    <path
                        fill="#c9c6c7"
                        d="M70 111.89 5.23 74.69 70 37.49l64.77 37.2L70 111.89z"
                    />
                    <path
                        fill="#c8c5c6"
                        d="M70 111.81 5.37 74.69 70 37.57l64.64 37.12L70 111.81z"
                    />
                    <path
                        fill="#c6c4c5"
                        d="M70 111.73 5.5 74.69 70 37.65l64.5 37.04L70 111.73z"
                    />
                    <path
                        fill="#c5c2c3"
                        d="M70 111.65 5.64 74.69 70 37.73l64.36 36.96L70 111.65z"
                    />
                    <path
                        fill="#c4c1c2"
                        d="M70 111.57 5.78 74.69 70 37.81l64.22 36.88L70 111.57z"
                    />
                    <path
                        fill="#c2c0c1"
                        d="M70 111.49 5.92 74.69 70 37.89l64.09 36.8L70 111.49z"
                    />
                    <path
                        fill="#c1bebf"
                        d="M70 111.41 6.05 74.69 70 37.97l63.95 36.72L70 111.41z"
                    />
                    <path
                        fill="#c0bdbe"
                        d="M70 111.33 6.19 74.69 70 38.05l63.81 36.64L70 111.33z"
                    />
                    <path
                        fill="#bebcbd"
                        d="M70 111.25 6.33 74.69 70 38.13l63.67 36.56L70 111.25z"
                    />
                    <path
                        fill="#bdbabb"
                        d="M70 111.17 6.47 74.69 70 38.2l63.54 36.49L70 111.17z"
                    />
                    <path
                        fill="#bcb9ba"
                        d="M70 111.1 6.6 74.69 70 38.28l63.4 36.41L70 111.1z"
                    />
                    <path
                        fill="#bab8b9"
                        d="M70 111.02 6.74 74.69 70 38.36l63.26 36.33L70 111.02z"
                    />
                    <path
                        fill="#b9b6b7"
                        d="M70 110.94 6.88 74.69 70 38.44l63.12 36.25L70 110.94z"
                    />
                    <path
                        fill="#b8b5b6"
                        d="M70 110.86 7.02 74.69 70 38.52l62.99 36.17L70 110.86z"
                    />
                    <path
                        fill="#b7b4b5"
                        d="M70 110.78 7.15 74.69 70 38.6l62.85 36.09L70 110.78z"
                    />
                    <path
                        fill="#b5b2b3"
                        d="M70 110.7 7.29 74.69 70 38.68l62.71 36.01L70 110.7z"
                    />
                    <path
                        fill="#b4b1b2"
                        d="M70 110.62 7.43 74.69 70 38.76l62.57 35.93L70 110.62z"
                    />
                    <path
                        fill="#b3b0b1"
                        d="M70 110.54 7.57 74.69 70 38.84l62.44 35.85L70 110.54z"
                    />
                    <path
                        fill="#b1aeb0"
                        d="M70 110.46 7.7 74.69 70 38.92l62.3 35.77L70 110.46z"
                    />
                    <path
                        fill="#b0adae"
                        d="M70 110.39 7.84 74.69 70 38.99l62.16 35.7L70 110.39z"
                    />
                    <path
                        fill="#afacad"
                        d="M70 110.31 7.98 74.69 70 39.07l62.02 35.62L70 110.31z"
                    />
                    <path
                        fill="#aeaaac"
                        d="M70 110.23 8.12 74.69 70 39.15l61.88 35.54L70 110.23z"
                    />
                    <path
                        fill="#aca9aa"
                        d="M70 110.15 8.25 74.69 70 39.23l61.75 35.46L70 110.15z"
                    />
                    <path
                        fill="#aba8a9"
                        d="M70 110.07 8.39 74.69 70 39.31l61.61 35.38L70 110.07z"
                    />
                    <path
                        fill="#aaa7a8"
                        d="M70 109.99 8.53 74.69 70 39.39l61.47 35.3L70 109.99z"
                    />
                    <path
                        fill="#a9a5a7"
                        d="M70 109.91 8.67 74.69 70 39.47l61.34 35.22L70 109.91z"
                    />
                    <path
                        fill="#a7a4a5"
                        d="M70 109.83 8.8 74.69 70 39.55l61.2 35.14L70 109.83z"
                    />
                    <path
                        fill="#a6a3a4"
                        d="M70 109.75 8.94 74.69 70 39.63l61.06 35.06L70 109.75z"
                    />
                    <path
                        fill="#a5a2a3"
                        d="M70 109.67 9.08 74.69 70 39.71l60.92 34.98L70 109.67z"
                    />
                    <path
                        fill="#a4a0a1"
                        d="M70 109.59 9.22 74.69 70 39.78l60.78 34.91L70 109.59z"
                    />
                    <path
                        fill="#a29fa0"
                        d="M70 109.52 9.36 74.69 70 39.86l60.65 34.83L70 109.52z"
                    />
                    <path
                        fill="#a19e9f"
                        d="M70 109.44 9.49 74.69 70 39.94l60.51 34.75L70 109.44z"
                    />
                    <path
                        fill="#a09d9e"
                        d="M70 109.36 9.63 74.69 70 40.02l60.37 34.67L70 109.36z"
                    />
                    <path
                        fill="#9f9b9d"
                        d="M70 109.28 9.77 74.69 70 40.1l60.23 34.59L70 109.28z"
                    />
                    <path
                        fill="#9e9a9b"
                        d="M70 109.2 9.9 74.69 70 40.18l60.1 34.51L70 109.2z"
                    />
                    <path
                        fill="#9c999a"
                        d="M70 109.12 10.04 74.69 70 40.26l59.96 34.43L70 109.12z"
                    />
                    <path
                        fill="#9b9899"
                        d="M70 109.04 10.18 74.69 70 40.34l59.82 34.35L70 109.04z"
                    />
                    <path
                        fill="#9a9698"
                        d="M70 108.96 10.32 74.69 70 40.42l59.68 34.27L70 108.96z"
                    />
                    <path
                        fill="#999596"
                        d="M70 108.88 10.46 74.69 70 40.5l59.55 34.19L70 108.88z"
                    />
                    <path
                        fill="#989495"
                        d="M70 108.81 10.59 74.69 70 40.58l59.41 34.11L70 108.81z"
                    />
                    <path
                        fill="#969394"
                        d="M70 108.73 10.73 74.69 70 40.65l59.27 34.04L70 108.73z"
                    />
                    <path
                        fill="#959293"
                        d="M70 108.65 10.87 74.69 70 40.73l59.13 33.96L70 108.65z"
                    />
                    <path
                        fill="#949092"
                        d="M70 108.57 11.01 74.69 70 40.81l59 33.88-59 33.88z"
                    />
                    <path
                        fill="#938f90"
                        d="m70 108.49-58.86-33.8L70 40.89l58.86 33.8L70 108.49z"
                    />
                    <path
                        fill="#928e8f"
                        d="M70 108.41 11.28 74.69 70 40.97l58.72 33.72L70 108.41z"
                    />
                    <path
                        fill="#918d8e"
                        d="M70 108.33 11.42 74.69 70 41.05l58.58 33.64L70 108.33z"
                    />
                    <path
                        fill="#8f8c8d"
                        d="M70 108.25 11.56 74.69 70 41.13l58.45 33.56L70 108.25z"
                    />
                    <path
                        fill="#8e8a8c"
                        d="M70 108.17 11.69 74.69 70 41.21l58.31 33.48L70 108.17z"
                    />
                    <path
                        fill="#8d898a"
                        d="m70 108.09-58.17-33.4L70 41.29l58.17 33.4L70 108.09z"
                    />
                    <path
                        fill="#8c8889"
                        d="M70 108.02 11.97 74.69 70 41.37l58.03 33.32L70 108.02z"
                    />
                    <path
                        fill="#8b8788"
                        d="M70 107.94 12.11 74.69 70 41.45l57.9 33.24L70 107.94z"
                    />
                    <path
                        fill="#8a8687"
                        d="M70 107.86 12.24 74.69 70 41.52l57.76 33.17L70 107.86z"
                    />
                    <path
                        fill="#888586"
                        d="M70 107.78 12.38 74.69 70 41.6l57.62 33.09L70 107.78z"
                    />
                    <path
                        fill="#878485"
                        d="M70 107.7 12.52 74.69 70 41.68l57.48 33.01L70 107.7z"
                    />
                    <path
                        fill="#868284"
                        d="M70 107.62 12.66 74.69 70 41.76l57.34 32.93L70 107.62z"
                    />
                    <path
                        fill="#858182"
                        d="M70 107.54 12.79 74.69 70 41.84l57.21 32.85L70 107.54z"
                    />
                    <path
                        fill="#848081"
                        d="M70 107.46 12.93 74.69 70 41.92l57.07 32.77L70 107.46z"
                    />
                    <path
                        fill="#837f80"
                        d="M70 107.38 13.07 74.69 70 42l56.93 32.69L70 107.38z"
                    />
                    <path
                        fill="#827e7f"
                        d="M70 107.31 13.21 74.69 70 42.08l56.8 32.61L70 107.31z"
                    />
                    <path
                        fill="#817d7e"
                        d="M70 107.23 13.34 74.69 70 42.16l56.66 32.53L70 107.23z"
                    />
                    <path
                        fill="#7f7c7d"
                        d="M70 107.15 13.48 74.69 70 42.23l56.52 32.46L70 107.15z"
                    />
                    <path
                        fill="#7e7b7c"
                        d="M70 107.07 13.62 74.69 70 42.31l56.38 32.38L70 107.07z"
                    />
                    <path
                        fill="#7d7a7b"
                        d="m70 106.99-56.24-32.3L70 42.39l56.25 32.3L70 106.99z"
                    />
                    <path
                        fill="#7c787a"
                        d="M70 106.91 13.89 74.69 70 42.47l56.11 32.22L70 106.91z"
                    />
                    <path
                        fill="#7b7778"
                        d="M70 106.83 14.03 74.69 70 42.55l55.97 32.14L70 106.83z"
                    />
                    <path
                        fill="#7a7677"
                        d="M70 106.75 14.17 74.69 70 42.63l55.83 32.06L70 106.75z"
                    />
                    <path
                        fill="#797576"
                        d="M70 106.67 14.31 74.69 70 42.71l55.69 31.98L70 106.67z"
                    />
                    <path
                        fill="#787475"
                        d="m70 106.59-55.55-31.9L70 42.79l55.56 31.9L70 106.59z"
                    />
                    <path
                        fill="#777374"
                        d="M70 106.52 14.58 74.69 70 42.87l55.42 31.82L70 106.52z"
                    />
                    <path
                        fill="#757273"
                        d="M70 106.44 14.72 74.69 70 42.95l55.28 31.74L70 106.44z"
                    />
                    <path
                        fill="#747172"
                        d="M70 106.36 14.86 74.69 70 43.02l55.14 31.67L70 106.36z"
                    />
                    <path
                        fill="#737071"
                        d="M70 106.28 14.99 74.69 70 43.1l55.01 31.59L70 106.28z"
                    />
                    <path
                        fill="#726f70"
                        d="M70 106.2 15.13 74.69 70 43.18l54.87 31.51L70 106.2z"
                    />
                    <path
                        fill="#716e6f"
                        d="M70 106.12 15.27 74.69 70 43.26l54.73 31.43L70 106.12z"
                    />
                    <path
                        fill="#706d6e"
                        d="M70 106.04 15.41 74.69 70 43.34l54.59 31.35L70 106.04z"
                    />
                    <path
                        fill="#6f6c6d"
                        d="M70 105.96 15.54 74.69 70 43.42l54.46 31.27L70 105.96z"
                    />
                    <path
                        fill="#6e6b6c"
                        d="M70 105.88 15.68 74.69 70 43.5l54.32 31.19L70 105.88z"
                    />
                    <path
                        fill="#6d6a6b"
                        d="M70 105.81 15.82 74.69 70 43.58l54.18 31.11L70 105.81z"
                    />
                    <path
                        fill="#6c6969"
                        d="M70 105.73 15.96 74.69 70 43.66l54.04 31.03L70 105.73z"
                    />
                    <path
                        fill="#6b6868"
                        d="M70 105.65 16.1 74.69 70 43.74l53.91 30.95L70 105.65z"
                    />
                    <path
                        fill="#6a6667"
                        d="M70 105.57 16.23 74.69 70 43.82l53.77 30.87L70 105.57z"
                    />
                    <path
                        fill="#696566"
                        d="m70 105.49-53.63-30.8L70 43.9l53.63 30.79L70 105.49z"
                    />
                    <path
                        fill="#686465"
                        d="M70 105.41 16.51 74.69 70 43.97l53.49 30.72L70 105.41z"
                    />
                    <path
                        fill="#676364"
                        d="M70 105.33 16.65 74.69 70 44.05l53.36 30.64L70 105.33z"
                    />
                    <path
                        fill="#666263"
                        d="M70 105.25 16.78 74.69 70 44.13l53.22 30.56L70 105.25z"
                    />
                    <path
                        fill="#656162"
                        d="M70 105.17 16.92 74.69 70 44.21l53.08 30.48L70 105.17z"
                    />
                    <path
                        fill="#646061"
                        d="m70 105.09-52.94-30.4L70 44.29l52.94 30.4L70 105.09z"
                    />
                    <path
                        fill="#635f60"
                        d="M70 105.02 17.2 74.69 70 44.37l52.81 30.32L70 105.02z"
                    />
                    <path
                        fill="#625f5f"
                        d="M70 104.94 17.33 74.69 70 44.45l52.67 30.24L70 104.94z"
                    />
                    <path
                        fill="#615e5e"
                        d="M70 104.86 17.47 74.69 70 44.53l52.53 30.16L70 104.86z"
                    />
                    <path
                        fill="#605d5d"
                        d="M70 104.78 17.61 74.69 70 44.61l52.39 30.08L70 104.78z"
                    />
                    <path
                        fill="#5f5c5c"
                        d="M70 104.7 17.75 74.69l52.25-30 52.25 30L70 104.7z"
                    />
                    <path
                        fill="#5e5b5c"
                        d="M70 104.62 17.88 74.69 70 44.76l52.12 29.93L70 104.62z"
                    />
                    <path
                        fill="#5d5a5b"
                        d="M70 104.54 18.02 74.69 70 44.84l51.98 29.85L70 104.54z"
                    />
                    <path
                        fill="#5c595a"
                        d="M70 104.46 18.16 74.69 70 44.92l51.84 29.77L70 104.46z"
                    />
                    <path
                        fill="#5b5859"
                        d="M70 104.38 18.3 74.69 70 45l51.7 29.69L70 104.38z"
                    />
                    <path
                        fill="#5a5758"
                        d="M70 104.3 18.43 74.69 70 45.08l51.57 29.61L70 104.3z"
                    />
                    <path
                        fill="#595657"
                        d="M70 104.22 18.57 74.69 70 45.16l51.43 29.53L70 104.22z"
                    />
                    <path
                        fill="#585556"
                        d="M70 104.15 18.71 74.69 70 45.24l51.29 29.45L70 104.15z"
                    />
                    <path
                        fill="#575455"
                        d="M70 104.07 18.85 74.69 70 45.32l51.16 29.37L70 104.07z"
                    />
                    <path
                        fill="#565354"
                        d="m70 103.99-51.02-29.3L70 45.4l51.02 29.29L70 103.99z"
                    />
                    <path
                        fill="#565253"
                        d="M70 103.91 19.12 74.69 70 45.48l50.88 29.21L70 103.91z"
                    />
                    <path
                        fill="#555152"
                        d="M70 103.83 19.26 74.69 70 45.55l50.74 29.14L70 103.83z"
                    />
                    <path
                        fill="#545151"
                        d="M70 103.75 19.4 74.69 70 45.63l50.6 29.06L70 103.75z"
                    />
                    <path
                        fill="#535050"
                        d="M70 103.67 19.54 74.69 70 45.71l50.47 28.98L70 103.67z"
                    />
                    <path
                        fill="#524f50"
                        d="m70 103.59-50.33-28.9L70 45.79l50.33 28.9L70 103.59z"
                    />
                    <path
                        fill="#514e4f"
                        d="M70 103.52 19.81 74.69 70 45.87l50.19 28.82L70 103.52z"
                    />
                    <path
                        fill="#504d4e"
                        d="M70 103.44 19.95 74.69 70 45.95l50.05 28.74L70 103.44z"
                    />
                    <path
                        fill="#4f4c4d"
                        d="M70 103.36 20.09 74.69 70 46.03l49.92 28.66L70 103.36z"
                    />
                    <path
                        fill="#4e4b4c"
                        d="M70 103.28 20.22 74.69 70 46.11l49.78 28.58L70 103.28z"
                    />
                    <path
                        fill="#4d4a4b"
                        d="M70 103.2 20.36 74.69 70 46.19l49.64 28.5L70 103.2z"
                    />
                    <path
                        fill="#4c4a4a"
                        d="M70 103.12 20.5 74.69 70 46.27l49.5 28.42L70 103.12z"
                    />
                    <path
                        fill="#4b4949"
                        d="M70 103.04 20.64 74.69 70 46.34l49.37 28.35L70 103.04z"
                    />
                    <path
                        fill="#4a4848"
                        d="M70 102.96 20.77 74.69 70 46.42l49.23 28.27L70 102.96z"
                    />
                    <path
                        fill="#4a4748"
                        d="M70 102.88 20.91 74.69 70 46.5l49.09 28.19L70 102.88z"
                    />
                    <path
                        fill="#494647"
                        d="M70 102.8 21.05 74.69 70 46.58l48.95 28.11L70 102.8z"
                    />
                    <path
                        fill="#484546"
                        d="M70 102.72 21.19 74.69 70 46.66l48.82 28.03L70 102.72z"
                    />
                    <path
                        fill="#474445"
                        d="M70 102.65 21.32 74.69 70 46.74l48.68 27.95L70 102.65z"
                    />
                    <path
                        fill="#464344"
                        d="M70 102.57 21.46 74.69 70 46.82l48.54 27.87L70 102.57z"
                    />
                    <path
                        fill="#454343"
                        d="m70 102.49-48.4-27.8L70 46.9l48.4 27.79-48.4 27.8z"
                    />
                    <path
                        fill="#444242"
                        d="M70 102.41 21.74 74.69 70 46.98l48.27 27.71L70 102.41z"
                    />
                    <path
                        fill="#434142"
                        d="M70 102.33 21.87 74.69 70 47.06l48.13 27.63L70 102.33z"
                    />
                    <path
                        fill="#424041"
                        d="M70 102.25 22.01 74.69 70 47.13l47.99 27.56L70 102.25z"
                    />
                    <path
                        fill="#413f40"
                        d="M70 102.17 22.15 74.69 70 47.21l47.85 27.48L70 102.17z"
                    />
                    <path
                        fill="#403e3f"
                        d="m70 102.09-47.71-27.4L70 47.29l47.72 27.4L70 102.09z"
                    />
                    <path
                        fill="#3f3e3e"
                        d="M70 102.01 22.42 74.69 70 47.37l47.58 27.32L70 102.01z"
                    />
                    <path
                        fill="#3f3d3d"
                        d="M70 101.94 22.56 74.69 70 47.45l47.44 27.24L70 101.94z"
                    />
                    <path
                        fill="#3e3c3d"
                        d="M70 101.86 22.7 74.69 70 47.53l47.3 27.16L70 101.86z"
                    />
                    <path
                        fill="#3d3b3c"
                        d="M70 101.78 22.84 74.69 70 47.61l47.17 27.08L70 101.78z"
                    />
                    <path
                        fill="#3c3a3b"
                        d="M70 101.7 22.97 74.69l47.03-27 47.03 27L70 101.7z"
                    />
                    <path
                        fill="#3b393a"
                        d="M70 101.62 23.11 74.69 70 47.77l46.89 26.92L70 101.62z"
                    />
                    <path
                        fill="#3a3939"
                        d="M70 101.54 23.25 74.69 70 47.85l46.75 26.84L70 101.54z"
                    />
                    <path
                        fill="#393838"
                        d="M70 101.46 23.39 74.69 70 47.92l46.61 26.77L70 101.46z"
                    />
                    <path
                        fill="#383738"
                        d="M70 101.38 23.52 74.69 70 48l46.48 26.69L70 101.38z"
                    />
                    <path
                        fill="#383637"
                        d="M70 101.3 23.66 74.69 70 48.08l46.34 26.61L70 101.3z"
                    />
                    <path
                        fill="#373536"
                        d="M70 101.22 23.8 74.69 70 48.16l46.2 26.53L70 101.22z"
                    />
                    <path
                        fill="#363435"
                        d="M70 101.15 23.94 74.69 70 48.24l46.06 26.45L70 101.15z"
                    />
                    <path
                        fill="#353434"
                        d="M70 101.07 24.07 74.69 70 48.32l45.93 26.37L70 101.07z"
                    />
                    <path
                        fill="#343334"
                        d="m70 100.99-45.79-26.3L70 48.4l45.79 26.29L70 100.99z"
                    />
                    <path
                        fill="#333233"
                        d="M70 100.91 24.35 74.69 70 48.48l45.65 26.21L70 100.91z"
                    />
                    <path
                        fill="#333132"
                        d="M70 100.83 24.49 74.69 70 48.56l45.51 26.13L70 100.83z"
                    />
                    <path
                        fill="#323131"
                        d="M70 100.75 24.63 74.69 70 48.64l45.38 26.05L70 100.75z"
                    />
                    <path
                        fill="#313031"
                        d="M70 100.67 24.76 74.69 70 48.72l45.24 25.97L70 100.67z"
                    />
                    <path
                        fill="#302f30"
                        d="m70 100.59-45.1-25.9L70 48.79l45.1 25.9-45.1 25.9z"
                    />
                    <path
                        fill="#2f2e2f"
                        d="M70 100.51 25.04 74.69 70 48.87l44.96 25.82L70 100.51z"
                    />
                    <path
                        fill="#2f2e2e"
                        d="M70 100.44 25.18 74.69 70 48.95l44.83 25.74L70 100.44z"
                    />
                    <path
                        fill="#2e2d2d"
                        d="M70 100.36 25.31 74.69 70 49.03l44.69 25.66L70 100.36z"
                    />
                    <path
                        fill="#2d2c2d"
                        d="M70 100.28 25.45 74.69 70 49.11l44.55 25.58L70 100.28z"
                    />
                    <path
                        fill="#2c2b2c"
                        d="M70 100.2 25.59 74.69 70 49.19l44.41 25.5L70 100.2z"
                    />
                    <path
                        fill="#2b2b2b"
                        d="M70 100.12 25.73 74.69 70 49.27l44.28 25.42L70 100.12z"
                    />
                    <path
                        fill="#2b2a2b"
                        d="M70 100.04 25.86 74.69 70 49.35l44.14 25.34L70 100.04z"
                    />
                    <path
                        fill="#2a292a"
                        d="M70 99.96 26 74.69l44-25.26 44 25.26-44 25.27z"
                    />
                    <path
                        fill="#292929"
                        d="M70 99.88 26.14 74.69 70 49.51l43.86 25.18L70 99.88z"
                    />
                    <path
                        fill="#282828"
                        d="M70 99.8 26.28 74.69 70 49.59l43.73 25.1L70 99.8z"
                    />
                    <path
                        fill="#282728"
                        d="M70 99.72 26.41 74.69 70 49.66l43.59 25.03L70 99.72z"
                    />
                    <path
                        fill="#272627"
                        d="M70 99.64 26.55 74.69 70 49.74l43.45 24.95L70 99.64z"
                    />
                    <path
                        fill="#262626"
                        d="M70 99.57 26.69 74.69 70 49.82l43.31 24.87L70 99.57z"
                    />
                    <path
                        fill="#252525"
                        d="m70 99.49-43.17-24.8L70 49.9l43.18 24.79L70 99.49z"
                    />
                    <path
                        fill="#252425"
                        d="M70 99.41 26.96 74.69 70 49.98l43.04 24.71L70 99.41z"
                    />
                    <path
                        fill="#242424"
                        d="M70 99.33 27.1 74.69 70 50.06l42.9 24.63L70 99.33z"
                    />
                    <path
                        fill="#232323"
                        d="M70 99.25 27.24 74.69 70 50.14l42.76 24.55L70 99.25z"
                    />
                    <path
                        fill="#222223"
                        d="M70 99.17 27.38 74.69 70 50.22l42.63 24.47L70 99.17z"
                    />
                    <path
                        fill="#222"
                        d="m70 99.09-42.49-24.4L70 50.3l42.49 24.39L70 99.09z"
                    />
                    <path
                        fill="#212121"
                        d="M70 99.01 27.65 74.69 70 50.38l42.35 24.31L70 99.01z"
                    />
                    <path
                        fill="#202020"
                        d="M70 98.94 27.79 74.69 70 50.45l42.21 24.24L70 98.94z"
                    />
                    <path
                        fill="#1f2020"
                        d="M70 98.86 27.93 74.69 70 50.53l42.08 24.16L70 98.86z"
                    />
                    <path
                        fill="#1f1f1f"
                        d="M70 98.78 28.06 74.69 70 50.61l41.94 24.08L70 98.78z"
                    />
                    <path
                        fill="#1e1e1e"
                        d="M70 98.7 28.2 74.69l41.8-24 41.8 24L70 98.7z"
                    />
                    <path
                        fill="#1d1e1e"
                        d="M70 98.62 28.34 74.69 70 50.77l41.66 23.92L70 98.62z"
                    />
                    <path
                        fill="#1d1d1d"
                        d="M70 98.54 28.48 74.69 70 50.85l41.53 23.84L70 98.54z"
                    />
                </g>
                <path
                    fill="#dbdbdb"
                    d="m69.55 108.41-58.68-33.7v-6.7h117.37v6.7l-58.69 33.7z"
                />
                <path
                    fill="#abb8b7"
                    d="M69.55 108.41v-6.71l58.69-33.69v6.7l-58.69 33.7z"
                />
                <path
                    fill="#d1debd"
                    d="M69.55 101.7 10.87 68.01l58.68-33.7 58.69 33.7-58.69 33.69z"
                />
                <path
                    fill="#0e7166"
                    opacity={0.15}
                    style={{
                        mixBlendMode: "multiply",
                    }}
                    d="M33.56 68.54 22 61.61l36.48-20.94 30.6 16.52-33.54 21.25-21.98-9.9z"
                />
                <path
                    d="M34.45 41.19c-.65 0-1.17.27-1.17.6v18a.3.3 0 0 0 .1.23 1.06 1.06 0 0 0 .45.25v.05a2.31 2.31 0 0 0 .62.09c.65 0 1.18-.26 1.18-.59v-18c0-.36-.53-.63-1.18-.63Z"
                    fill="#d35515"
                />
                <path
                    d="M34.32 31.28c-2.84 0-7.61 14.08-7.61 17.12 0 2.11 1.87 6.35 7.61 6.35 5.44 0 7.61-4.24 7.61-6.35 0-3.04-5.51-17.12-7.61-17.12Z"
                    fill="#1c998b"
                />
                <path
                    d="M34.59 48.65c.65-5.93-.27-17.37-.27-17.37-2.85 0-7.68 14.08-7.61 17.12.08 3.25 7.47 4.1 7.88.25Z"
                    fill="#69bcb1"
                />
                <path
                    d="M30.36 47.9c-1.42 0-2.77-.76-2.76-1.49C27.61 44 30.73 36 33 32.54c1.27-1.47.19 10.26-.36 14.17-.16 1.17-1.75 1.21-2.24 1.19Z"
                    fill="#82c8bd"
                />
                <path
                    d="M34.9 52.2c-4.92.6-7.25-.8-7.25-.8 1.06 1.73 3.13 3.35 6.67 3.35 5.15 0 7.36-3.79 7.59-6-.55 1.25-3.6 3.03-7.01 3.45Z"
                    fill="#0e7166"
                />
                <path
                    d="M32.34 37.49c-.08.23-.7 3.81-2 3.39-.4-.14-.16-1.87.54-3.87s1.56-3.54 2-3.4.12 1.87-.54 3.88ZM103.06 48.02l-.69-.55-9.3 5.33v13.65l.7.38 9.29-18.81z"
                    fill="#fff"
                />
                <path
                    fill="#dbdbdb"
                    d="M103.06 61.5V47.86l-9.29 5.32v13.65l9.29-5.33z"
                />
                <path
                    fill="#576573"
                    d="m94.84 53.54 7.14-4.09v11.7l-7.14 4.09v-11.7z"
                />
                <path fill="#dbdbdb" d="M98.36 51.14v12.4l.53-.29V50.83l-.53.31z" />
                <path
                    fill="#a2d1cb"
                    d="m71.32 40.88 39 22.35-39 22.35-39.01-22.35 39.01-22.35z"
                />
                <path
                    fill="#97c2bd"
                    d="m32.31 67.01 39.01 22.35v-3.78L32.31 63.23v3.78z"
                />
                <path fill="#87aeaa" d="m71.32 89.36 39-22.35v-3.78l-39 22.35v3.78z" />
                <path
                    d="M42.78 52.28v8.16l.61.35V52c-.21.06-.39.17-.61.28ZM39.06 54.24v8.15l.61.36v-8.82ZM40.89 53.3v8.15l.6.35V53c-.2.08-.41.18-.6.3Z"
                    fill="#dbdbdb"
                />
                <path
                    fill="#dbdbdb"
                    d="m36.76 61.95.85.5L59 50.03l-.85-.49-21.39 12.41z"
                />
                <path
                    fill="#abb8b7"
                    d="m37.59 64.22 21.42-12.43-.01-1.76-21.39 12.42-.02 1.77z"
                />
                <path
                    fill="#dbdbdb"
                    d="m36.62 54.91.86.5L58.87 43l-.86-.49-21.39 12.4z"
                />
                <path
                    fill="#abb8b7"
                    d="m37.46 57.18 21.41-12.42V43L37.48 55.41l-.02 1.77z"
                />
                <path fill="#abb8b7" d="m38.18 39.15-.86.49v24.72l.86-.49V39.15z" />
                <path fill="#dbdbdb" d="M35.8 38.73v24.76l1.52.87V39.64l-1.52-.91z" />
                <path
                    d="M39.67 54.66v8.09l.34-.2v-8ZM41.49 53.71v8.09l.35-.2v-8ZM43.39 52.7v8.09l.34-.21v-8ZM71.32 83.59V58.86l35.53-20.36v24.73L71.32 83.59z"
                    fill="#abb8b7"
                />
                <path
                    fill="#dbdbdb"
                    d="M53.55 22.98 35.78 38.5v6.33l8.75 5v18.41l26.79 15.35V58.86L53.55 22.98z"
                />
                <path
                    fill="#b8dcd3"
                    d="m54.07 20.36 18.87 38.12 35.53-20.36L89.61 0 54.07 20.36z"
                />
                <path
                    fill="#0e7166"
                    d="M34.16 37.76v.99l1.62.93V38.5l17.77-15.52 17.77 35.88v1.18l1.62.93v-2.49L54.07 20.36l-19.91 17.4z"
                />
                <path
                    fill="#1c998b"
                    d="M72.94 60.97v-2.49l35.53-20.36v2.49L72.94 60.97z"
                />
                <path
                    fill="#fff"
                    d="m87.4 57.17-.69-.54-9.3 5.32v13.66l.69.37 9.3-18.81z"
                />
                <path fill="#dbdbdb" d="M87.4 70.67V57.01l-9.3 5.32v13.65l9.3-5.31z" />
                <path fill="#576573" d="m79.18 62.7 7.14-4.11v11.72l-7.14 4.09V62.7z" />
                <path fill="#dbdbdb" d="M82.7 60.29v12.42l.53-.31V59.99l-.53.3z" />
                <path
                    fill="#fff"
                    d="m103.65 47.86-.69-.54-9.3 5.32v13.65l.7.38 9.29-18.81z"
                />
                <path
                    fill="#dbdbdb"
                    d="M103.65 61.34V47.7l-9.29 5.32v13.65l9.29-5.33z"
                />
                <path fill="#576573" d="m95.43 53.38 7.15-4.1v11.7l-7.15 4.1v-11.7z" />
                <path fill="#dbdbdb" d="M98.95 50.98v12.41l.54-.3V50.67l-.54.31z" />
                <path
                    fill="#fff"
                    d="m54.46 57.17.69-.54 9.3 5.32v13.66l-.69.37-9.3-18.81z"
                />
                <path fill="#efefef" d="M54.46 70.67V57.01l9.3 5.32v13.65l-9.3-5.31z" />
                <path fill="#576573" d="m62.68 62.7-7.14-4.11v11.72l7.14 4.09V62.7z" />
                <path fill="#efefef" d="M59.16 60.29v12.42l-.54-.31V59.99l.54.3z" />
                <path
                    fill="#fff"
                    d="m47.71 32.15.69-.54 5.43 3.04v13.43l-.69.38-5.43-16.31z"
                />
                <path
                    fill="#efefef"
                    d="M47.71 45.58V32.16l5.43 3.04v13.42l-5.43-3.04z"
                />
                <path fill="#576573" d="m52.06 35.4-3.28-1.82v11.48l3.28 1.81V35.4z" />
                <path
                    fill="#eff3f3"
                    d="m30.41 68.21 3.39-1.74 10.67 5.9-3.39 1.91-10.67-6.07z"
                />
                <path
                    fill="#abb8b7"
                    d="m41.08 75.86 3.39-1.92v-1.57l-3.39 1.91v1.58z"
                />
                <path
                    fill="#bec4c8"
                    d="m41.08 75.86-10.67-6.07v-1.58l10.67 6.07v1.58z"
                />
                <path
                    d="M116.4 53.59c-.65 0-1.18.26-1.18.6v17.94a.44.44 0 0 0 .1.25 1.11 1.11 0 0 0 .46.25 1.92 1.92 0 0 0 .62.1c.65 0 1.17-.27 1.17-.6V54.19c0-.34-.52-.6-1.17-.6Z"
                    fill="#d35515"
                />
                <path
                    d="M116.27 43.67c-2.85 0-7.61 14.08-7.61 17.12 0 2.11 1.87 6.35 7.61 6.35 5.44 0 7.6-4.24 7.6-6.35 0-3.04-5.5-17.12-7.6-17.12Z"
                    fill="#1c998b"
                />
                <path
                    d="M116.54 61.05c.65-5.94-.28-17.38-.28-17.38-2.85 0-7.68 14.08-7.6 17.12.08 3.26 7.46 4.1 7.88.26Z"
                    fill="#69bcb1"
                />
                <path
                    d="M112.31 60.29c-1.43 0-2.77-.76-2.77-1.48 0-2.44 3.14-10.41 5.36-13.87 1.28-1.47.19 10.25-.36 14.17-.16 1.16-1.75 1.21-2.23 1.18Z"
                    fill="#82c8bd"
                />
                <path
                    d="M116.84 64.59c-4.92.6-7.25-.79-7.25-.79 1.07 1.73 3.13 3.34 6.68 3.34 5.14 0 7.36-3.8 7.58-6-.55 1.22-3.59 3.03-7.01 3.45Z"
                    fill="#0e7166"
                />
                <path
                    d="M114.28 49.88c-.68 2-1 3-1.4 2.84s-.7-1.32 0-3.33 1.56-3.53 2-3.39.12 1.87-.6 3.88Z"
                    fill="#fff"
                />
                <path
                    d="M43.35 32.89a1.21 1.21 0 1 0 1.2-1.2 1.2 1.2 0 0 0-1.2 1.2Z"
                    fill="#abb8b7"
                />
                <path
                    d="M44.55 31.69a1.45 1.45 0 0 1 .3 0 1.22 1.22 0 0 1 .22.67 1.19 1.19 0 0 1-1.2 1.19 1.35 1.35 0 0 1-.29-.05 1.16 1.16 0 0 1 1-1.86Z"
                    fill="#dbdbdb"
                />
            </g>
        </g>
    </svg>
)

SchemeHouse.propTypes = propTypes;
SchemeHouse.defaultProps = defaultProps;

export default SchemeHouse;
