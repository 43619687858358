import { makeStyles } from '@mui/styles';
import Grid from '../Grid';
import Button from '../Button';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { getMode, isDisconnected } from 'utils';
import {
    setSDM18HCLConfig,
    setSDM19TMTSetPoint,
} from 'MiddlewareSocket/emitters/widgets';
import { isHeatAndCool_temperature } from 'store/products/utils/predicates';
import { useAsync } from 'utils/hooks/useAsync';
import { Add, Remove } from '@mui/icons-material';
import React from 'react';

export default function SetPoint({ product }) {
    const { execute, error, isLoading } = useAsync(
        isHeatAndCool_temperature(product)
            ? setSDM18HCLConfig
            : setSDM19TMTSetPoint,
    );
    const classes = useStyles();
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(product?.consign_temp || 0);
    }, [product?.consign_temp]);

    useEffect(() => {
        if (error) {
            setValue(product?.consign_temp || 0);
        }
    }, [error]);

    const handleClick = async type => {
        let newValue = value;
        type === 'add' ? ++newValue : --newValue;
        setValue(newValue);
        debounceEvent(newValue);
    };

    const sendEvent = async newValue => {
        if (isHeatAndCool_temperature(product)) {
            await execute(
                product,
                getMode(product),
                product.ventilation,
                newValue,
            );
        } else {
            await execute(product, value);
        }
    };

    const debounceEvent = useCallback(debounce(sendEvent, 1000), []);

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={3} container alignItems="center">
                <Button
                    className={classes.button}
                    disableRipple
                    size="small"
                    variant="contained"
                    onClick={handleClick}
                    disabled={isLoading || isDisconnected(product)}
                >
                    <Remove fontSize="small" />
                </Button>
            </Grid>
            <Grid
                item
                container
                xs={4}
                justifyContent="center"
                className={classes.spanContainer}
            >
                <span className={classes.span}>{value}</span>
            </Grid>
            <Grid item xs={3} container alignItems="center">
                <Button
                    className={classes.button}
                    size="small"
                    variant="contained"
                    onClick={() => handleClick('add')}
                    disabled={isLoading || isDisconnected(product)}
                >
                    <Add fontSize="small" />
                </Button>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    button: { width: 15, minWidth: 0, height: 20, minHeight: 0 },
    span: { color: theme.palette.primary.main },
    spanContainer: { width: 5 },
}));
