import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const AccessIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 185.35">
            <g fill={props.color}>
                <path d="M150,0C86.69,0,29.38,35.46,0,92.67c29.38,57.21,86.69,92.68,150,92.68s120.62-35.47,150-92.68C270.62,35.46,213.31,0,150,0ZM121.12,29.51A32.48,32.48,0,1,1,88.64,62,32.51,32.51,0,0,1,121.12,29.51Zm154,63.16-1.22,1.93a151,151,0,0,1-46.06,46.32,144.73,144.73,0,0,1-155.76,0A151.13,151.13,0,0,1,26.06,94.6l-1.22-1.93,1.22-1.92A151.13,151.13,0,0,1,72.12,44.43q1.81-1.16,3.66-2.25L84.46,37,81,46.49a73.42,73.42,0,1,0,138,0L215.54,37l8.68,5.2q1.84,1.09,3.66,2.25a151.13,151.13,0,0,1,46.06,46.32Z" />
            </g>
        </svg>
    );
};

AccessIcon.propTypes = propTypes;
AccessIcon.defaultProps = defaultProps;

export default AccessIcon;
