import React from 'react';
import FormControl from 'data/components/FormControl';
import RadioGroup from 'data/components/RadioGroup';
import Grid from 'data/components/Grid';
import { makeStyles } from '@mui/styles';
import t from 'utils/Translator';
import Typography from '@mui/material/Typography';

export default function ProductsFilter({ value, onChange }) {
    const classes = useStyles();
    return (
        <Grid container className={classes.container}>
            <FormControl
                label={
                    <Typography className={classes.label}>
                        {t.get('filters.label')}
                    </Typography>
                }
            >
                <RadioGroup
                    value={value}
                    onChange={onChange}
                    radios={[
                        {
                            value: 'all',
                            label: (
                                <Typography className={classes.label}>
                                    {t.get('filters.all')}
                                </Typography>
                            ),
                        },
                        {
                            value: 'types',
                            label: (
                                <Typography className={classes.label}>
                                    {t.get('filters.types')}
                                </Typography>
                            ),
                        },
                        {
                            value: 'categories',
                            label: (
                                <Typography className={classes.label}>
                                    {t.get('filters.categories')}
                                </Typography>
                            ),
                        },
                    ]}
                />
            </FormControl>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: 24,
        color: theme.palette.common.black,
    },
    label: {
        color: theme.palette.text.primary,
    },
}));
