import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { getAPWifiProductsList } from '../../../../MiddlewareSocket/emitters/widgets';
import Stepper from '@mui/material/Stepper';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import InclusionWizardLastStep from './components/inclusionWizardLastStep';
import InclusionWizardFirstStep from './components/inclusionWizardFirstStep';
import InclusionWizardSecondStep from './components/inclusionWizardSecondStep';
import { withStyles } from '@mui/styles';

const InclusionModal = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [APList, setAPList] = useState([]);
    const [WifiList, setWifiList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [focusedProduct, setFocusedProduct] = useState({});
    const apListIntervalRef = useRef(null);

    useEffect(() => {
        apListIntervalRef.current = getAPList();

        return () => clearInterval(apListIntervalRef.current);
    }, []);

    const steps = ['Sélectionnez un module', 'Connectez le module', 'Terminé'];

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getAPList = () => {
        return setInterval(async () => {
            const apList = await getAPWifiProductsList();

            if (!apList.ap_list) {
                setAPList([]);
            } else if (apList?.ap_list.length !== APList.length) {
                setAPList(apList.ap_list);
            }

            if (!apList.wifi_list) {
                setWifiList([]);
            } else if (apList?.wifi_list.length !== WifiList.length) {
                setWifiList(apList.wifi_list);
            }
        }, 10000);
    };

    const StyledStepLabel = withStyles(theme => ({
        label: {
            color: theme.palette.text.primary,
        },
    }))(StepLabel);

    return (
        <Box className={classes.container}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StyledStepLabel {...labelProps}>
                                {label}
                            </StyledStepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div className={classes.content}>
                {activeStep === 0 && (
                    <InclusionWizardFirstStep
                        APList={APList}
                        handleNext={handleNext}
                        apListIntervalRef={apListIntervalRef}
                        setFocusedProduct={setFocusedProduct}
                    />
                )}

                {activeStep === 1 && (
                    <InclusionWizardSecondStep
                        focusedProduct={focusedProduct}
                        WifiList={WifiList}
                        handleNext={handleNext}
                        setLoading={setLoading}
                    />
                )}

                {activeStep === 2 && (
                    <InclusionWizardLastStep
                        loading={loading}
                        setLoading={setLoading}
                    />
                )}
            </div>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={activeStep === 2 ? handleReset : handleBack}
                    sx={{ mr: 1 }}
                >
                    {activeStep === 2 ? 'Reset' : 'Back'}
                </Button>

                <Box
                    sx={{
                        flex: '1 1 auto',
                    }}
                />

                {activeStep === 2 && (
                    <Button
                        disabled
                        type="submit"
                        onClick={props.closeFunction}
                    >
                        Finish
                    </Button>
                )}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        height: 360,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 360,
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default InclusionModal;
