import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@mui/styles';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

function TemperaturesIcon(props) {
    const StyledSvgIcon = withStyles(theme => ({
        root: {
            height: props.height,
            width: props.width,
        },
    }))(SvgIcon);

    return (
        <StyledSvgIcon
            viewBox="0 0 300 260.91"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.color}
                d="M274.06 260.92h-61.19c-2.43 0-4.4-3.1-4.4-7v-11.71c0-3.84 2-7 4.4-7h61.19c2.43 0 4.4 3.12 4.4 7v11.75c0 3.85-2 7-4.4 7M88.4 260.92H27.21c-2.43 0-4.4-3.1-4.4-7v-11.71c0-3.84 2-7 4.4-7H88.4c2.43 0 4.4 3.12 4.4 7v11.75c0 3.85-2 7-4.4 7"
            />
            <path
                fill={props.color}
                d="M123.42 100.61a15.74 15.74 0 0 0-7.51-5 2.48 2.48 0 0 0 .86-.38c3.67-2.75 7-5.92 10.8-8.48a33.35 33.35 0 0 1 28.77-4.63c7.56 2.09 12.43 7.06 14.41 14 2.92 10.28 1.55 20.37-3 30.13-2.73 5.8-11.39 9-17.34 3.88-4-3.45-7.87-7.09-11.63-10.8a28.88 28.88 0 0 0-12-7.5 14.6 14.6 0 0 0-3.33-11.31M134 69.34a74.57 74.57 0 0 1-8 9c-2.56 2.66-5.51 5-8 7.72a19.2 19.2 0 0 0-4.63 9.06 16.27 16.27 0 0 0-12.23 3.13 15.11 15.11 0 0 0-4.61 5.75 1.84 1.84 0 0 0-.34-.84c-2.76-3.43-6-6.51-8.62-10-5.25-7.09-7.42-15-6-23.71 1.38-8.25 5.73-14.58 14-17.19a43.45 43.45 0 0 1 35.06 3.52A9.53 9.53 0 0 1 134 69.34M50.67 121.56c-2.77-10.29-1.45-20.41 3.33-30.1 2.69-5.47 10.94-8.41 16.85-3.89A36.71 36.71 0 0 1 74 89.99c3.73 3.56 7.33 7.27 11.14 10.76a23.92 23.92 0 0 0 10.64 5.66 14.59 14.59 0 0 0 3 13.07 15.6 15.6 0 0 0 5.34 4.11 6.59 6.59 0 0 0-1.55 1.1 71.41 71.41 0 0 1-8.67 6.69 33.75 33.75 0 0 1-28.23 4.65c-7.91-2.08-13-7.18-15-14.49M88.32 147.23a36.79 36.79 0 0 1 2.68-3.14c3.67-3.53 7.49-6.93 11.09-10.52a21.31 21.31 0 0 0 5.45-8.8 16.3 16.3 0 0 0 13.37-2.92 15.07 15.07 0 0 0 4.9-6.44 6.36 6.36 0 0 0 1 1.23 68 68 0 0 1 7 8.28c5.93 8.13 7.9 17 5.27 26.77a19.87 19.87 0 0 1-14.87 14.77c-11 2.85-21.73 1.56-32-3.17-5.6-2.58-8.57-10.34-3.86-16"
            />
            <ellipse
                fill={props.color}
                cx={110.36}
                cy={110.02}
                rx={16.33}
                ry={15.6}
            />
            <path
                fill={props.color}
                d="M110.37 127.9c-10.26 0-18.61-8-18.61-17.88s8.35-17.88 18.61-17.88 18.61 8 18.61 17.88-8.35 17.88-18.61 17.88zm0-31.21c-7.75 0-14.05 6-14.05 13.32s6.3 13.32 14.05 13.32 14-6 14-13.32-6.26-13.31-14-13.31z"
            />
            <path
                fill={props.color}
                d="M287.08 0H12.92A12.92 12.92 0 0 0 0 12.93v192.12a12.92 12.92 0 0 0 12.92 12.92h274.16A12.92 12.92 0 0 0 300 205.05V12.93A12.92 12.92 0 0 0 287.08 0zM110.72 184.94a76 76 0 1 1 76-76 76 76 0 0 1-76 76z"
            />
        </StyledSvgIcon>
    );
}

TemperaturesIcon.propTypes = propTypes;
TemperaturesIcon.defaultProps = defaultProps;

export default TemperaturesIcon;
