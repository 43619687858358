import React from 'react';
import InputWrapper from '../InputWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { probesLinkRemove } from 'store/products/thunks';
import { TextField } from '@mui/material';
import Button from 'data/components/Button';
import FormDivider from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/FormDivider';
import t from 'utils/Translator';

export default function InputLinkWire({ product }) {
    const master = useSelector(
        state => state.products.entities[product.thermostatic_master],
    );
    const dispatch = useDispatch();
    const handleClick = async () => {
        await dispatch(
            probesLinkRemove({
                product,
            }),
        );
    };
    if (!master) return null;
    return (
        <>
            <FormDivider />
            <InputWrapper>
                <TextField
                    disabled
                    fullWidth
                    name="ass"
                    label={t.get('inputs.master')}
                    value={master.label}
                />
            </InputWrapper>
            <InputWrapper>
                <Button onClick={handleClick} fullWidth variant="contained">
                    {t.get('inputs.unlink')}
                </Button>
            </InputWrapper>
        </>
    );
}
