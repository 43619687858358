import React from 'react';
import Select from 'data/components/Select';
import InputWrapper from '../InputWrapper';
import t from 'utils/Translator';

export default function InputModuleType({ formik }) {
    return (
        <InputWrapper>
            <Select
                fullWidth
                id="module_type"
                name="module_type"
                label={t.get('inputs.moduleType')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.module_type}
                options={[
                    { value: 'socket', label: 'Prise' },
                    { value: 'lighting', label: 'Éclairage' },
                    { value: 'shutterstore', label: 'Volet Roulant' },
                    { value: 'thermostat', label: 'Chauffage' },
                    { value: 'security', label: 'Sécurité' },
                    { value: 'ecs', label: 'ECS' },
                    { value: 'ventilation', label: 'Ventilation' },
                ]}
            />
        </InputWrapper>
    );
}
