import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@mui/styles';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

function ShuttersIcon(props) {
    const StyledSvgIcon = withStyles(theme => ({
        root: {
            height: props.height,
            width: props.width,
        },
    }))(SvgIcon);

    return (
        <StyledSvgIcon
            viewBox="0 0 300 300"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.color}
                d="M22.65,275.86H135.24V96H22.65ZM125.82,120.64a3.35,3.35,0,0,1-.12,4.57L85,165.73a3,3,0,0,1-2.11.89,3,3,0,0,1-2.24-1,3.36,3.36,0,0,1,.12-4.57l40.66-40.52A3,3,0,0,1,125.82,120.64ZM52.45,167.39l40.12-42.21a3,3,0,0,1,4.35,0,3.36,3.36,0,0,1,0,4.58L56.8,172a3,3,0,0,1-4.35,0A3.35,3.35,0,0,1,52.45,167.39Z"
            />
            <path
                fill={props.color}
                d="M164.76,275.86H277.35V96H164.76Zm82.72-155.22a3.35,3.35,0,0,1-.11,4.57l-40.66,40.52a3,3,0,0,1-4.35-.12,3.35,3.35,0,0,1,.12-4.57l40.66-40.52A3,3,0,0,1,247.48,120.64Zm-73.37,46.75,40.13-42.21a3,3,0,0,1,4.35,0,3.36,3.36,0,0,1,0,4.58L178.46,172a3,3,0,0,1-4.35,0A3.35,3.35,0,0,1,174.11,167.39Z"
            />
            <path
                fill={props.color}
                d="M285,0H15A15,15,0,0,0,0,15V285a15,15,0,0,0,15,15H285a15,15,0,0,0,15-15V15A15,15,0,0,0,285,0ZM15,13H285a2,2,0,0,1,2,2V25H13V15A2,2,0,0,1,15,13ZM13,48.71V37H287V48.71ZM287,60.78V72.35H13V60.78Zm-6.62,225.38H161.7a6.92,6.92,0,0,1-6.73-7.08V96H145V279.08a6.92,6.92,0,0,1-6.73,7.08H19.59a6.92,6.92,0,0,1-6.74-7.08L13,84.42H287l.11,194.66A6.92,6.92,0,0,1,280.41,286.16Z"
            />
        </StyledSvgIcon>
    );
}

ShuttersIcon.propTypes = propTypes;
ShuttersIcon.defaultProps = defaultProps;

export default ShuttersIcon;
