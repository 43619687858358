import React from 'react';
import LinkModule from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkModule/components/LinkModule';
import UnlinkModule from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkModule/components/UnlinkModule';

export default function InputLinkModule({ product }) {
    const { is_master_from_master_slave, master_from_master_slave } = product;

    const canSelect = () => {
        return is_master_from_master_slave || !master_from_master_slave;
    };

    return (
        <>
            {canSelect() ? (
                <LinkModule product={product} />
            ) : (
                <UnlinkModule product={product} />
            )}
        </>
    );
}
