import React from 'react';
import t from 'utils/Translator';

const MessageCard = props => {
    console.log('les messages non lues ', props.message);

    return (
        <div>
            <p>{props.message ? props.message.texte : 'Aucun Message'}</p>

            <button onClick={props.hide}>
                <p>{t.get('rgpd-modal.body.button.close')}</p>
            </button>
        </div>
    );
};

export default MessageCard;
