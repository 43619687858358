import React from 'react';
import Grid from 'data/components/Grid';
import Button from 'data/components/Button';

export default function SubmitButton({ handleSubmit, disabled }) {
    return (
        <Grid
            container
            justifyContent="flex-end"
            item
            xs={12}
            style={{ paddingRight: 8 }}
        >
            <div>
                <Button
                    disabled={disabled}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    OK
                </Button>
            </div>
        </Grid>
    );
}
