import React from 'react';
import Select from 'data/components/Select';
import InputWrapper from '../InputWrapper';
import t from 'utils/Translator';

export default function InputPhase({ formik }) {
    return (
        <InputWrapper>
            <Select
                id="fhe_product_mode"
                name="fhe_product_mode"
                label={t.get('inputs.phase')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fhe_product_mode}
                options={[
                    { value: 'triphase', label: 'triphase' },
                    { value: 'monophase', label: 'monophase' },
                ]}
            />
        </InputWrapper>
    );
}
