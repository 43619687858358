import React from 'react';
import { makeStyles } from '@mui/styles';
import FacebookIcon from 'assets/svgs/Facebook';
import TwitterIcon from 'assets/svgs/Twitter';
import LinkedinIcon from 'assets/svgs/Linkedin';
import useModal from 'utils/hooks/useModal';
import ModalRGPD from 'data/components/Modals/modalRGPD';
import Modal from 'data/components/Modals/Modal';
import ModalCGU from 'data/components/Modals/modalCGU';
import YoutubeIcon from 'assets/svgs/Youtube';
import Text from 'data/components/Text';

const Footer = props => {
    const classes = useStyles();
    const [RGPDIsOpen, toggleRGPD] = useModal();
    const [CGUIsOpen, toggleCGU] = useModal();

    return (
        <div
            className={
                props.headerExpanded
                    ? classes.containerExpanded
                    : classes.container
            }
        >
            <div className={classes.copyrights}>
                <Text className={classes.copyrightsText}>
                    Copyrights © 2021 – FHE FRANCE
                </Text>
                <Text className={classes.allRightsReserved}>
                    Tous droits réservés
                </Text>
            </div>
            <div className={classes.RGPDCGU}>
                <div onClick={toggleRGPD} className={classes.text}>
                    RGPD / CGU
                </div>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.ecologie.gouv.fr/reglementation-thermique-rt2012"
                    style={{ textDecoration: 'none', color: 'white' }}
                >
                    Consulter les RT2012
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://fhe-france.com/confidentialite"
                    style={{ textDecoration: 'none', color: 'white' }}
                >
                    Consulter la politique de confidentialité
                </a>
            </div>
            <div className={classes.socialMedia}>
                <div className={classes.buttonContainer}>
                    <a
                        href="https://www.facebook.com/FHE.France/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FacebookIcon width={30} height={30} />
                    </a>
                    <a
                        href="https://twitter.com/FHE_France"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TwitterIcon width={30} height={30} />
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCevsyFrMSyHlnhmV3nk3zsw"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <YoutubeIcon width={30} height={30} />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/fhe-group/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LinkedinIcon width={30} height={30} />
                    </a>
                </div>
                <Text className={classes.cornerRightText}>
                    Entreprise Française
                </Text>
            </div>
            <Modal
                open={RGPDIsOpen}
                closeFunction={() => {
                    toggleRGPD();
                    toggleCGU();
                }}
            >
                <ModalRGPD />
            </Modal>
            <Modal open={CGUIsOpen} closeFunction={toggleCGU}>
                <ModalCGU />
            </Modal>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        zIndex: 1300,
        position: 'absolute',
        backgroundColor: '#333',
        bottom: -120,
        width: '100%',
        padding: 50,
        height: 100,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    containerExpanded: {
        zIndex: 9999,
        position: 'absolute',
        backgroundColor: '#333',
        bottom: -120,
        width: '100%',
        padding: 50,
        height: 100,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    copyrights: {
        display: 'flex',
        flexDirection: 'column',
    },
    RGPDCGU: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    socialMedia: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: 150,
    },
    copyrightsText: {
        color: 'white',
        fontSize: 20,
    },
    allRightsReserved: {
        color: 'white',
        fontSize: 14,
    },
    text: {
        color: 'white',
    },
    cornerRightText: {
        color: 'white',
        width: 150,
    },
}));

export default Footer;
