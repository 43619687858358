import React from 'react';
import ProductsGroup from './ProductsGroup/ProductsGroup';
import Grid from 'data/components/Grid';
import { makeStyles } from '@mui/styles';
import DashboardProductsGroup from './ProductsGroup/DashboardProductsGroup';
import EcoTipsWidget from './ProductsGroup/EcoTipsWidget';
import ConsoProdWidget from './ProductsGroup/ConsoProdWidget';
import {useSelector} from "react-redux";
import {selectGeneratorsIds} from "../../../store/generators/generatorSlice";

export default function ProductsList({ groups, dashboard, filter }) {
    const classes = useStyles();
    const generators = useSelector(selectGeneratorsIds);

    return (
        <Grid container spacing={6} className={classes.container} item xs={12}>
            {dashboard && generators && <ConsoProdWidget />}
            {dashboard && <EcoTipsWidget />}
            {Object.entries(groups).reduce((acc, [group, productIds]) => {
                if (productIds.length) {
                    dashboard
                        ? acc.push(
                              <DashboardProductsGroup
                                  key={group}
                                  group={group}
                                  productIds={productIds}
                              />,
                          )
                        : acc.push(
                              <ProductsGroup
                                  key={group}
                                  group={group}
                                  productIds={productIds}
                                  filter={filter}
                              />,
                          );
                }
                return acc;
            }, [])}
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        padding: 24,
        marginBottom: 25,
    },
}));
