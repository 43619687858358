import React from 'react';
import InputWrapper from '../InputWrapper';
import { TextField } from '@mui/material';
import t from 'utils/Translator';

export default function InputMinMax({ formik }) {
    return (
        <>
            <InputWrapper>
                <TextField
                    fullWidth
                    name="load_time"
                    label={t.get('inputs.loadTime')}
                    value={formik.values.load_time}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </InputWrapper>
            <InputWrapper>
                <TextField
                    fullWidth
                    name="max_autonomy"
                    label={t.get('inputs.maxAutonomy')}
                    value={formik.values.max_autonomy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </InputWrapper>
        </>
    );
}
