/** Import Internals */
import EventEmitter from 'utils/EventEmitter';
import { MIDDLEWARE_CONFIGURATION } from 'config';
const io = require('socket.io-client');

/** Error Codes */
const ERROR_CODE_TIMEOUT = 'timeout';
const ERROR_CODE_INVALID_CREDENTIALS = 'AMY_AUTH';

class MiddlewareSocket {
    constructor() {
        this.socket = null;
    }

    connectToServer = (config, token) => {
        this.connectionTimeout = setTimeout(() => {
            EventEmitter.emit('middlewareConnectionTimeout', {
                errorCode: ERROR_CODE_TIMEOUT,
            });

            this.destroy();
        }, MIDDLEWARE_CONFIGURATION.timeout);

        const uri = `${config.protocol}://${config.host}:${config.port}`;
        const opts = {
            timeout: MIDDLEWARE_CONFIGURATION.timeout,
            ...config.options,
            query: `type=client&authid=${token}`,
        };
        this.socket = io(uri, opts);
        this.bindSocketEvents();
    };

    bindSocketEvents = () => {
        this.socket.on('connect_error', e => {
            console.log('socket connect_error', e);
        });

        this.socket.on('connect', () => {
            console.log('socket connected');
            clearTimeout(this.connectionTimeout);
            this.connectionTimeout = null;

            EventEmitter.emit('middlewareConnected');
        });

        this.socket.on('disconnect', () => {
            console.log('socket disconnected');
            EventEmitter.emit('middlewareDisconnected');
        });

        this.socket.on('error', errorCode => {
            console.log('middlewareDisconnected', errorCode);
            if (
                typeof errorCode === 'string' &&
                errorCode.startsWith(ERROR_CODE_INVALID_CREDENTIALS)
            ) {
                clearTimeout(this.connectionTimeout);
                this.connectionTimeout = null;
                EventEmitter.emit('middlewareConnectionError', { errorCode });
                this.destroy();
            } else {
                EventEmitter.emit('middlewareDisconnected');
            }
        });
    };

    emit = (event, payload) =>
        new Promise((resolve, reject) => {
            console.log('📣 ==>', event, payload);
            if (Array.isArray(event)) {
                const timeout = setTimeout(() => {
                    console.log('🔴 <==FEEDBACK_TIMEOUT', feedbackName);
                    reject({ errorCode: ERROR_CODE_TIMEOUT });
                }, MIDDLEWARE_CONFIGURATION.timeout);

                const [eventName, feedbackName] = event;

                /** Bind feedback listener before emit **/
                this.socket?.once(feedbackName, result => {
                    console.log('👂 <==FEEDBACK', feedbackName, result);
                    clearTimeout(timeout);
                    if (['bad_command', 'fail'].includes(result.res)) {
                        return reject({ errorCode: result.error_msg });
                    }
                    resolve(result);
                });

                this.socket?.emit(eventName, payload);
            } else {
                this.socket?.emit(event, payload);

                return resolve();
            }
        });

    on = (eventName, handler) => {
        this.socket?.on(eventName, params => {
            return handler(params);
        });

        return () => this.socket?.off(eventName, handler);
    };

    destroy = () => {
        if (this.socket) {
            this.socket.disconnect();
        }
    };
}

export default new MiddlewareSocket();
