import * as React from 'react';

const PinIcon = props => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 24 24"
        data-name="Livello 1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fill={props.color}
            d="M11.9 1a8.6 8.6 0 0 0-8.6 8.6c0 4.35 7.2 12.05 8.42 13.33a.24.24 0 0 0 .35 0c1.22-1.27 8.42-9 8.42-13.33A8.6 8.6 0 0 0 11.9 1Zm0 11.67A3.07 3.07 0 1 1 15 9.6a3.07 3.07 0 0 1-3.1 3.07Z"
            data-name="map pin"
        />
    </svg>
);

export default PinIcon;
