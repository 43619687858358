import Text from 'data/components/Text';
import ProductModal from '../../../ProductModal';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { isInteger } from 'formik';

export default function InelioProductLabel({ product }) {
    const classes = useStyles();

    const getDuration = () => {
        const DURATION_LIMIT = 600;
        const now = new Date();
        const startTime = new Date(product.stageStateStart * 1000);
        const duration = Math.abs(now - startTime);
        const minutes = Math.floor(duration / 1000 / 60);

        if (minutes < DURATION_LIMIT) {
            return minutes;
        } else {
            return null;
        }
    };

    // const getTimeLeft = () => {
    //     const CHARGE_TIME = 240;
    //     const duration = getDuration();
    //     const moduleState = product.stageState;
    //     if (moduleState === 'CHARGE' && duration) {
    //         return CHARGE_TIME - duration;
    //     } else {
    //         return null;
    //     }
    // };

    return (
        <>
            <Text noWrap className={classes.container}>
                {product.label} -
                {product.stageState === 'CHARGE'
                    ? ' Charge '
                    : ` ${product.stageState} ${
                          getDuration() !== null ? `${getDuration()} min` : ''
                      }`}
            </Text>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    container: {},
}));
