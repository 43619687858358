import Grid from 'data/components/Grid';
import { ReactComponent as Sunset } from './svgs/wi-sunset.svg';
import Text from 'data/components/Text';
import React from 'react';
import { ReactComponent as Sunrise } from './svgs/wi-sunrise.svg';
import moment from 'moment';
import Tooltip from 'data/components/Tooltip';
import { makeStyles } from '@mui/styles';

export default function Sun({
    astronomy: { sunrise, sunset },
    color = 'inherit',
    variant = 'body1',
}) {
    const classes = useStyle();
    const formatTime = time => {
        return moment(time, ['h:mm A']).format('HH:mm');
    };

    return (
        <Grid item container alignItems="center" xs>
            <Tooltip title={'Lever du soleil'}>
                <div className={classes.content}>
                    <Sunrise
                        fill={color === 'inherit' ? 'black' : 'white'}
                        height={32}
                    />
                    <Text color={color} variant={variant}>
                        {formatTime(sunrise)}
                    </Text>
                </div>
            </Tooltip>
            <Tooltip title={'Coucher du soleil'}>
                <div className={classes.content}>
                    <Sunset
                        fill={color === 'inherit' ? 'black' : 'white'}
                        height={32}
                    />
                    <Text color={color} variant={variant}>
                        {formatTime(sunset)}
                    </Text>
                </div>
            </Tooltip>
        </Grid>
    );
}

const useStyle = makeStyles({
    content: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        marginRight: 12,
    },
});
