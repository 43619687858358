import React from 'react';
import ReactDOM from 'react-dom';
import configureAppStore from './store';
import { Provider } from 'react-redux';
import moment from 'moment';
import Routes from './navigation/components/Routes.js';
import SnackbarProvider from './data/components/SnackbarProvider';
import { warningsFilter } from './utils';
import CustomThemeProvider from './theme/CustomThemeContext';

const Index = () => {
    moment.locale('fr');
    const store = configureAppStore();
    warningsFilter();

    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <SnackbarProvider>
                    <Routes />
                </SnackbarProvider>
            </CustomThemeProvider>
        </Provider>
    );
};

ReactDOM.render(<Index />, document.getElementById('root'));
