import Grid from 'data/components/Grid';
import Text from 'data/components/Text';
import React from 'react';
import { useSelector } from 'react-redux';
import { weathersSelectors } from 'store/weather/weatherSlice';
import WeatherIcon from 'data/components/WeatherIcon';
import { getWeatherDescription, withTooltip } from 'utils';

export default function CurrentTemperature() {
    const currentWeather = useSelector(weathersSelectors.selectCurrentWeather);
    return (
        <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
                <Text variant="h3">{currentWeather.tempC}°C</Text>
            </Grid>
            <Grid item>
                {withTooltip(
                    <div>
                        <WeatherIcon
                            code={currentWeather.weatherCode}
                            height={80}
                        />
                    </div>,
                    getWeatherDescription(currentWeather),
                )}
            </Grid>
        </Grid>
    );
}
