import React from 'react';
import { TextField } from '@mui/material';
import InputWrapper from '../InputWrapper';
import t from 'utils/Translator';

export default function InputModel({ formik }) {
    return (
        <InputWrapper>
            <TextField
                fullWidth
                name="model"
                label={t.get('inputs.model')}
                value={formik.values.model}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </InputWrapper>
    );
}
