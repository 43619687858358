import { makeStyles } from '@mui/styles';
import Grid from 'data/components/Grid';
import Paper from 'data/components/Paper';
import Text from 'data/components/Text';
import React, { useContext, useEffect, useState } from 'react';
import AccordionGenerators from 'data/components/AccordionGenerators';
import Button from '../../../../data/components/Button';
import CloseIcon from '../../../../assets/svgs/Close';
import Modal from '../../../../data/components/Modals/Modal';
import useModal from '../../../../utils/hooks/useModal';
import GeneratorModalForm from '../../../../data/components/Modals/GeneratorModalForm';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CustomThemeContext } from '../../../../theme/CustomThemeContext';
import GeneratorCard from './GeneratorCard';
import Box from '@mui/material/Box';

const renderNewGenerator = () => {
    return <Box>lala</Box>;
};

const GeneratorCards = props => {
    const classes = useStyle();
    const { theme, setTheme } = useContext(CustomThemeContext);
    const [generators, setGenerators] = useState(props.generators);
    const [edition, setEdition] = useState(false);

    return (
        <Grid container item className={classes.container}>
            <Grid item md={12}>
                <Card
                    elevation={5}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                    }}
                >
                    <CardContent className={classes.card}>
                        <AccordionGenerators
                            defaultExpanded={false}
                            header={
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.titleContainer}
                                    >
                                        <Text noWrap className={classes.title}>
                                            Panneaux
                                        </Text>
                                    </Grid>
                                </Grid>
                            }
                            content={
                                <Grid container className={classes.content}>
                                    {generators.map((generator, index) =>
                                        generator.added === true ? (
                                            <GeneratorCard
                                                generator={generator}
                                                index={index}
                                                resetGenerators={() =>
                                                    setGenerators(
                                                        props.generators,
                                                    )
                                                }
                                                setEdition={setEdition}
                                            />
                                        ) : (
                                            <GeneratorCard
                                                generator={generator}
                                                index={index}
                                                resetGenerators={() =>
                                                    setGenerators(
                                                        props.generators,
                                                    )
                                                }
                                                setEdition={setEdition}
                                            />
                                        ),
                                    )}
                                    {!edition && (
                                        <Paper
                                            elevation={5}
                                            className={classes.addGenerator}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setGenerators([
                                                        ...props.generators,
                                                        {
                                                            added: true,
                                                            orientation: '',
                                                            power: 0,
                                                            roof_pitch: 0,
                                                            module_type: '',
                                                        },
                                                    ]);
                                                    setEdition(true);
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    transform: 'rotate(45deg)',
                                                }}
                                            >
                                                <CloseIcon
                                                    width={25}
                                                    color={
                                                        theme === 'dark'
                                                            ? 'white'
                                                            : 'black'
                                                    }
                                                />
                                            </Button>
                                        </Paper>
                                    )}
                                </Grid>
                            }
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const useStyle = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    card: {
        backgroundColor: theme.palette.background.default,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 20,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    addGenerator: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        padding: 12,
        margin: 12,
    },
}));

export default GeneratorCards;
