import {
    createEntityAdapter,
    createSlice,
    createSelector,
} from '@reduxjs/toolkit';
import { getCurrentWeatherWindow } from 'utils';

const adapter = createEntityAdapter();
const selectors = adapter.getSelectors(({ weathers }) => weathers);
const slice = createSlice({
    name: 'weathers',
    initialState: adapter.getInitialState(),
    reducers: {
        setAll: adapter.setAll,
    },
});

const selectCurrentWeather = createSelector(
    state => selectors.selectById(state, 'day0'),
    weather => getCurrentWeatherWindow(weather),
);

export const weathersActions = slice.actions;
export const weathersReducer = slice.reducer;
export const weathersSelectors = { ...selectors, selectCurrentWeather };
