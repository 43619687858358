import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const InfosIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 300">
            <g fill={props.color}>
                <path d="M150,0C67.29,0,0,67.29,0,150S67.29,300,150,300s150-67.29,150-150S232.71,0,150,0Zm95.28,245.28A134.75,134.75,0,1,1,54.72,54.72,134.75,134.75,0,1,1,245.28,245.28Z" />
                <path d="M168.71,191.06a22.91,22.91,0,0,0,2.82-4.72,2.7,2.7,0,0,0,.31-1.38,3.78,3.78,0,0,0-1.27.73c-2.21,2.2-4.38,4.54-6.49,6.8s-4.1,4.41-6.23,6.56a23,23,0,0,1-6.2,4.38,5.29,5.29,0,0,1-7.93-5.56,36.16,36.16,0,0,1,2-8.32q3.45-9.53,7-19c3.86-10.47,7.85-21.29,11.61-32A40.9,40.9,0,0,0,167,125.35c0-3.6-1-6.4-3-8.34-2.23-2.13-5.77-3.19-9.94-3-.58,0-1.15,0-1.73.06a26,26,0,0,0-3.18.23c-15,2.59-26.75,11-39.25,28.14-1,1.38-.73,1.61-.35,1.92a2,2,0,0,0,.27.19,8.11,8.11,0,0,0,1.38-1.29c1.26-1.35,2.49-2.81,3.68-4.21,1.7-2,3.46-4.08,5.39-6a68.25,68.25,0,0,1,9.34-7.62,6.14,6.14,0,0,1,3.35-.89,5.53,5.53,0,0,1,3.9,1.36c1.58,1.62,2,4.38,1.64,6.39a18.22,18.22,0,0,1-1.49,4.49c-.22.5-.43,1-.63,1.51q-2.9,7.57-5.83,15.15c-5,12.85-10.12,26.15-14.8,39.33-1.9,5.37-2.43,11.49-2.72,17.3-.28,5.52,4.53,7.31,6.61,7.82,5.12,1.27,10.53.64,17-2C148.88,210.94,159.4,202.81,168.71,191.06Z" />
                <path d="M182.88,84.56A18.92,18.92,0,0,1,156.93,102c-6.6-2.68-10.07-9.85-8.66-17.84a19.07,19.07,0,0,1,23.82-14.93C178.93,71.19,182.85,76.76,182.88,84.56Z" />
            </g>
        </svg>
    );
};

InfosIcon.propTypes = propTypes;
InfosIcon.defaultProps = defaultProps;

export default InfosIcon;
