import * as React from "react"
import PropTypes from "prop-types";

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

const SchemeTower = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.01 114.78" {...props}>
        <defs>
            <radialGradient
                id="a"
                cx={-69.18}
                cy={-187.51}
                r={29.8}
                gradientTransform="matrix(1.41 0 0 .79 139.52 238.76)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#1d1d1d" />
                <stop offset={0.09} stopColor="#242424" />
                <stop offset={0.24} stopColor="#383838" />
                <stop offset={0.4} stopColor="#595959" />
                <stop offset={0.59} stopColor="#878787" />
                <stop offset={0.8} stopColor="silver" />
                <stop offset={1} stopColor="#fff" />
            </radialGradient>
        </defs>
        <g
            data-name="Calque 2"
            style={{
                isolation: "isolate",
            }}
        >
            <g data-name="Calque 1">
                <path
                    d="M84 91.36c0 12.93-18.81 23.42-42 23.42S0 104.29 0 91.36s18.81-23.42 42-23.42 42 10.48 42 23.42Z"
                    fill="url(#a)"
                    style={{
                        mixBlendMode: "multiply",
                    }}
                />
                <path
                    d="M74.71 89.5v-5.67c-.49-10-15-.56-32.85-.56-17 0-31.14-8-32.83 1.31v4.92c0 10.24 14.71 18.56 32.85 18.56s32.83-8.32 32.83-18.56Z"
                    fill="#dbdbdb"
                />
                <path
                    d="M74.71 83.83c0-10.25-14.7-18.56-32.85-18.56S9 73.58 9 83.83s14.72 18.57 32.86 18.57 32.85-8.32 32.85-18.57Z"
                    fill="#1c998b"
                />
                <path
                    d="M41.86 100.58c-16.82 0-31-7.66-31-16.75s14.14-16.75 31-16.75 31 7.67 31 16.75-14.18 16.75-31 16.75Z"
                    fill="#87aeaa"
                />
                <path
                    fill="#abb8b7"
                    d="m69.8 10.02-1.24 1.6-24.19 1.29 1.66-1.31 23.77-1.58z"
                />
                <path
                    d="m57 19.94-15.93-7.57L67.31 1.82l10 2.8v1.83Zm-11.32-7.53 11.93 5.3L73.22 6.2l-5.71-2.7Z"
                    fill="#abb8b7"
                />
                <path
                    d="m57 18.11-15.93-7.57L67.31 0l10 4.62ZM44.72 11l12.11 6.28L73.89 4.69l-6.38-3Z"
                    fill="#f8f7f7"
                />
                <path
                    fill="#f8f7f7"
                    d="m55.87 17.71 1.98-.15 1.88-13.35-1.98.15-1.88 13.35z"
                />
                <path
                    fill="#f8f7f7"
                    d="m69.8 8.65-1.24 1.61-24.19 1.27 1.66-1.29L69.8 8.65zM58.79 4.71l15.31-1c.49.4 1 .82 1.43 1.23l-.15.2L58.6 6c.06-.42.13-.86.19-1.29Z"
                />
                <path
                    d="M30.45 53.22 41 57.39v12.45l1.56-.78V.57l-12.7 6.36L13.17 83.5l1.48-.43L17.72 69ZM18.21 66.75l3.69-16.92 7.54 3Zm16-46.27 6.7 2.65-8.16 10.15-6.67-2.64v-.09Zm-7.63 7.81 2.18-10 4.45 1.77Zm4.93 6.52-8.7 10.78 2.86-13.1ZM35.45 19 41 12.14v9Zm5.5 5.8v11.73l-7.2-2.85Zm0 13.79L30.67 51.3 22.3 48v-.14L32.52 35.2 41 38.55Zm0-35.9v7.84l-6.51 8.07-5.27-2.09 1.94-8.91ZM31.68 51.7 41 40.2v15.17Z"
                    fill="#788080"
                />
                <path
                    d="m31 54.08 10.5 4.17v12.44l1.56-.78V1.41L30.45 7.77 13.92 83.6l1.32.32 3.07-14.07ZM18.8 67.6l3.69-16.91 7.54 3Zm16-46.28L41.51 24l-8.18 10.13-6.66-2.64v-.1Zm-7.63 7.82 2.18-10 4.44 1.77Zm4.93 6.51-8.7 10.78 2.86-13.09ZM36 19.81 41.54 13v9Zm5.5 5.8v11.78l-7.2-2.86Zm0 13.79L31.26 52.15l-8.37-3.32v-.14l10.22-12.63 8.43 3.34Zm0-35.9v7.83L35 19.41l-5.27-2.1 1.93-8.87Zm-9.24 49.05 9.28-11.49v15.17Z"
                    fill="#abb8b7"
                />
                <path
                    d="m67.29 69 3.06 14.06 1.46.28L55.15 6.93 42.49.57v68.49l1.57.78V57.39l10.5-4.17ZM55.57 52.82l7.54-3 3.69 16.93Zm3.33-22.27v.09l-6.67 2.64-8.15-10.15 6.7-2.65Zm-7.11-10.46 4.45-1.77 2.17 10Zm7.53 12.4 2.86 13.1-8.69-10.78ZM44.06 21.13v-9L49.55 19Zm7.19 12.55-7.19 2.85V24.76Zm-7.19 4.87 8.42-3.35 10.19 12.64v.14l-8.33 3.32-10.28-12.75ZM53.9 7.6l1.93 8.87-5.27 2.09-6.5-8.07V2.65Zm-9.84 47.77V40.2l9.27 11.5Z"
                    fill="#788080"
                />
                <path
                    d="m66.76 69.85 3.07 14.07 1.09-.24-16.3-75.91L42 1.41v68.5l1.57.78V58.25L54 54.08ZM55.05 53.67l7.53-3 3.69 16.93Zm3.33-22.28v.1l-6.67 2.64L43.56 24l6.7-2.67Zm-7.11-10.46 4.44-1.77 2.18 10Zm7.53 12.41 2.86 13.09L53 35.65ZM43.54 22v-9L49 19.81Zm7.19 12.54-7.19 2.86V25.61Zm-7.19 4.86L52 36.06l10.15 12.63v.14l-8.36 3.32L43.54 39.4Zm9.84-31 1.93 8.87L50 19.41l-6.5-8.08V3.5Zm-9.84 47.83V41.06l9.27 11.49Z"
                    fill="#abb8b7"
                />
                <path
                    d="m53.65 65.27 12.86 4.53 3.06 14 .93.87-16.79-78.1-12.1 6.76v86.53l1.57-.9V83.14ZM43.18 80.6v-19l9.24 3.26Zm6.68-52.11 8.21 2.89-6.73 11.49L43.18 40v-.1Zm-6.68 8.85V26.13L48.63 28Zm7.15 7.26-7.15 12.2V42.07Zm.54-17.85L55.39 19l2.21 10.13Zm7.62 6.45 2.9 13.21-8.81-3.11Zm3.39 15.47-8.45 14.44-10.25-3.62v-.15L51.57 45l10.31 3.63ZM53.06 8.39l1.94 8.8-5.35 9.13-6.46-2.27v-10Zm1.6 55.15 7.63-13 3.73 17Z"
                    fill="#dbdbdb"
                />
                <path
                    d="m54.58 66.13 12.86 4.54 3.06 14 1.57-.9L55.24 6.89l-12.7 7.31v86.53l1.57-.9V84ZM44.11 81.46v-19l9.23 3.26Zm6.68-52.11L59 32.24l-6.73 11.49-8.16-2.87v-.12Zm-6.68 8.85V27l5.45 1.91Zm7.15 7.26-7.15 12.2V42.93Zm.54-17.84 4.52-7.72L58.53 30Zm7.62 6.45 2.9 13.2-8.81-3.11Zm3.39 15.46L54.36 64l-10.25-3.64v-.16l8.38-14.31 10.32 3.64ZM54 9.25l1.93 8.81-5.35 9.12-6.46-2.27v-10Zm1.6 55.16 7.63-13 3.72 17Z"
                    fill="#abb8b7"
                />
                <path
                    d="M41.76 83.24v15.81l1.57.9V13.43L30.74 6.91 14.59 84.64l.78-.77 3.06-14 12.86-4.53Zm-9.24-18.32 9.24-3.25v19ZM41.76 40v.1L33.6 43l-6.73-11.5 8.21-2.89Zm-5.45-11.86 5.45-1.92v11.22Zm5.45 14v14.75l-7.15-12.21Zm-14.42-13 2.21-10.1 4.52 7.72Zm5 14.17-8.79 3.19 2.9-13.21Zm-9.31 5.37 10.32-3.65 8.38 14.32v.16l-10.24 3.6-8.43-14.35Zm18.7-34.6v10l-6.43 2.33-5.3-9.13 1.92-8.79Zm-22.8 53.55 3.73-17 7.62 13Z"
                    fill="#abb8b7"
                />
                <path
                    d="M41 84v15.83l1.57.9V14.2L29.85 6.89 13 83.74l1.57.9 3.06-14 12.86-4.54Zm-9.26-18.3L41 62.44v19Zm9.23-25v.12l-8.15 2.87-6.73-11.45 8.21-2.89Zm-5.44-11.8L41 27v11.2Zm5.44 14v14.76l-7.14-12.2ZM26.56 30l2.21-10.1 4.52 7.72Zm5 14.17-8.8 3.11 2.89-13.2Zm-9.3 5.37 10.31-3.64L41 60.2v.16L30.73 64l-8.45-14.47ZM41 14.94v10l-6.45 2.27-5.35-9.12 1.9-8.84ZM18.14 68.41l3.73-17 7.63 13Z"
                    fill="#dbdbdb"
                />
                <path
                    fill="#f8f7f7"
                    d="m29.85 6.89 12.65 7.3 12.74-7.3L42.49.57 29.85 6.89z"
                />
                <path
                    d="m11.17 36.87-6.11-2.94v-1.58l22.49-14.2 15.11 6.2v2.41Zm-3.48-3.55L11.15 35l26.48-10.13-9.55-4.42Z"
                    fill="#abb8b7"
                />
                <path
                    d="M20 33.2c-.48.22-1 .44-1.43.68l-12 .64v-1.89L22 31.44v.36a10.82 10.82 0 0 1-2 1.4Z"
                    fill="#abb8b7"
                />
                <path
                    fill="#abb8b7"
                    d="M21.18 33.14 23.2 33l4.98-12.7-2.02.15-4.98 12.69z"
                />
                <path
                    fill="#abb8b7"
                    d="m40.73 24.48-1.71 2.54-24.19 1.29 1.08-1.94 24.82-1.89z"
                />
                <path
                    d="m11.17 35.29-6.11-2.94 22.49-15 15 6.93Zm-4.48-2.93 4.35 2L39.6 23.79l-11.52-5.32Z"
                    fill="#f8f7f7"
                />
                <path
                    fill="#f8f7f7"
                    d="m21.18 31.2 2.02-.15 4.98-12.7-2.02.15-4.98 12.7z"
                />
                <path
                    fill="#f8f7f7"
                    d="m40.26 23.55-1.24 1.6-24.19 1.28 1.66-1.3 23.77-1.58zM20 32l-13.44.68v-.57a2.26 2.26 0 0 0 0-.52l.18-.14 15.11-1C21.94 30.87 20 32 20 32Z"
                />
                <path
                    fill="#abb8b7"
                    d="m59.54 5.6 14.27-.95.08.04-2.13 1.56-12.41.67.19-1.32z"
                />
            </g>
        </g>
    </svg>
)

SchemeTower.propTypes = propTypes;
SchemeTower.defaultProps = defaultProps;

export default SchemeTower;
