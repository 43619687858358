import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

function Home(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width={props.width}
            height={props.width}
            {...props}
        >
            <path
                fill={props.color}
                d="M32 3L1 28l.492.654a4 4 0 005.738.692L32 9l24.77 20.346a4 4 0 005.738-.692L63 28l-9-7.258V8h-9v5.484L32 3zm0 10L8 32v24h48V35L32 13zm-6 21h12v18H26V34z"
            />
        </svg>
    );
}

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
