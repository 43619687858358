import Paper from '../../Paper';
import Grid from '../../Grid';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Text from '../../Text';
import Box from '@mui/material/Box';
import { useSelector, shallowEqual } from 'react-redux';
import { productsSelectors } from '../../../../store/products/selectors';

export default function ConsoProdWidget() {
    const classes = useStyles();

    const clamps = useSelector(productsSelectors.selectClamps, shallowEqual);

    let consoInstantData = 0;
    let prodInstantData = 0;

    let consoClamps = {};
    let prodClamps = {};

    Object.values(clamps).map(clamp => {
        let module = clamp.product_identifier;
        let code = clamp.code;
        if (clamp.conso_prod_type === 'prod') {
            prodClamps[`${module}_${code}`] = clamp.instant;
        } else if (clamp.conso_prod_type === 'conso') {
            consoClamps[`${module}_${code}`] = clamp.instant;
        }
    });

    consoInstantData =
        Object.keys(consoClamps).length &&
        Object.values(consoClamps).reduce((a, b) => a + b);
    prodInstantData =
        Object.keys(prodClamps).length &&
        Object.values(prodClamps).reduce((a, b) => a + b);

    return (
        <Grid container style={{ justifyContent: 'center' }} item xs={6}>
            <Paper elevation={5} className={classes.container}>
                <Box className={classes.box}>
                    <Box>
                        <Text className={classes.consoData}>
                            {!consoInstantData ? '- ' : consoInstantData} W
                        </Text>
                        <Text className={classes.text}>Consommation</Text>
                    </Box>
                    <Box>
                        <Text className={classes.prodData}>
                            {!prodInstantData ? '- ' : prodInstantData} W
                        </Text>
                        <Text className={classes.text}>Production</Text>
                    </Box>
                </Box>
            </Paper>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: 700,
    },
    box: {
        display: 'flex',
        padding: 8,
        width: '80%',
        height: '80%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: theme.palette.primary.main,
        width: 380,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 22,
    },
    consoData: {
        fontSize: 32,
        width: 380,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#2196F3',
    },
    prodData: {
        fontSize: 28,
        width: 380,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#4CAF50',
    },
}));
