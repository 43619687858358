import ListItem from 'data/components/ListItem';
import { makeStyles } from '@mui/styles';
import Grid from 'data/components/Grid';
import React from 'react';
import InelioProductLabel from '../InelioProductLabel';
import { setInelioState } from '../../../../../../MiddlewareSocket/emitters/widgets';
import { isDisconnected } from '../../../../../../utils';
import MUISwitch from '@mui/material/Switch';

export default function InelioSwitch({ product }) {
    const classes = useStyles();

    return (
        <ListItem>
            <Grid container alignItems="center">
                <Grid item xs={7}>
                    <InelioProductLabel product={product} />
                </Grid>
                <Grid item xs={5} className={classes.container}>
                    <Grid
                        container
                        justifyContent="flex-end"
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <MUISwitch
                                checked={product.stageState !== 'STDBY'}
                                onChange={() =>
                                    setInelioState(product, product.stageState)
                                }
                                disabled={isDisconnected(product)}
                                color="primary"
                                edge="end"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}

const useStyles = makeStyles(theme => ({
    container: {},
}));
