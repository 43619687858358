import {
    isClamp_measures,
    isFavorite, isInelio,
    isLighting,
    isLightModule_lighting,
    isMeasure,
    isNotLinkedClamp,
    isPilotWire_temperature,
    isProbe_temperature,
    isRelay_temperature,
    isRollingShutter_shutters,
    isSecurity,
    isShutter,
    isSlaveClamp,
    isSwitch,
    isTemperature,
    productIsInCategory,
} from './predicates';
import { getModuleKey, reduceModuleKeys, reduceProducts } from 'utils';

const GROUPS = {
    shutters: [],
    switches: [],
    lighting: [],
    temperatures: [],
    securities: [],
    measures: [],
    inelio: [],
};

export const filterProductsByGroups = products => {
    return [
        {
            label: '',
            groups: products.reduce(
                (acc, product) => {
                    acc = sortProductByGroup(product, acc);
                    return acc;
                },
                { ...GROUPS },
            ),
        },
    ];
};

export const filterProductsByFavorites = products => {
    return [
        {
            label: '',
            groups: products.reduce(
                (acc, product) => {
                    if (isFavorite(product)) {
                        acc = sortProductByGroup(product, acc);
                    }
                    return acc;
                },
                { ...GROUPS },
            ),
        },
    ];
};

export const filterProductsByCategories = ({ products, categories }) => {
    return categories.map(({ id, label }) => {
        return {
            label,
            groups: products.reduce(
                (acc, product) => {
                    if (productIsInCategory(product, id)) {
                        acc = sortProductByGroup(product, acc);
                    }
                    return acc;
                },
                { ...GROUPS },
            ),
        };
    });
};

const sortProductByGroup = (product, acc) => {
    const obj = { ...acc };
    if (isShutter(product))
        obj.shutters = [getModuleKey(product), ...obj.shutters];
    if (isSwitch(product))
        obj.switches = [getModuleKey(product), ...obj.switches];
    if (isLighting(product))
        obj.lighting = [getModuleKey(product), ...obj.lighting];
    if (isTemperature(product))
        obj.temperatures = [getModuleKey(product), ...obj.temperatures];
    if (isSecurity(product))
        obj.securities = [getModuleKey(product), ...obj.securities];
    if (isMeasure(product))
        obj.measures = [getModuleKey(product), ...obj.measures];
    if (isInelio(product))
        obj.inelio = [getModuleKey(product), ...obj.inelio];
    return obj;
};

// Types
export const filterProductsByTypes = products => [
    {
        label: '',
        groups: products.reduce((acc, product) => {
            const type = product.type || product.product_type;
            if (!acc.hasOwnProperty(type)) acc[type] = [];
            acc[type] = [getModuleKey(product), ...acc[type]];
            return acc;
        }, {}),
    },
];

// Favorites
export const filterFavorites = products => {
    return products.filter(isFavorite);
};
export const filterFavoritesIds = products =>
    reduceModuleKeys(products, [isFavorite]);

// Groups
export const filterShutters = products => products.filter(isShutter);
export const filterTemperatures = products => products.filter(isTemperature);
export const filterSwitches = products => products.filter(isSwitch);
export const filterSecurities = products => products.filter(isSecurity);
export const filterLighting = products => products.filter(isLighting);
export const filterMeasures = products => products.filter(isMeasure);

export const filterShuttersIds = products =>
    reduceModuleKeys(products, [isShutter]);
export const filterTemperaturesIds = products =>
    reduceModuleKeys(products, [isTemperature]);
export const filterSwitchesIds = products =>
    reduceModuleKeys(products, [isSwitch]);
export const filterSecuritiesIds = products =>
    reduceModuleKeys(products, [isSecurity]);
export const filterLightingIds = products =>
    reduceModuleKeys(products, [isLighting]);
export const filterMeasuresIds = products =>
    reduceModuleKeys(products, [isMeasure]);

// Favorites Groups
export const filterFavoritesShuttersIds = products =>
    reduceModuleKeys(products, [isShutter, isFavorite]);
export const filterFavoritesTemperaturesIds = products =>
    reduceModuleKeys(products, [isTemperature, isFavorite]);
export const filterFavoritesSwitchesIds = products =>
    reduceModuleKeys(products, [isSwitch, isFavorite]);
export const filterFavoritesSecuritiesIds = products =>
    reduceModuleKeys(products, [isSecurity, isFavorite]);
export const filterFavoritesLightingIds = products =>
    reduceModuleKeys(products, [isLighting, isFavorite]);
export const filterFavoritesMeasuresIds = products =>
    reduceModuleKeys(products, [isMeasure, isFavorite]);

// Clamps
export const filterClamps = products =>
    reduceProducts(products, [isClamp_measures]);
export const filterUnlinkedClamps = products =>
    reduceProducts(products, [isClamp_measures, isNotLinkedClamp]);
export const filterSlaveClamps = products =>
    reduceProducts(products, [isClamp_measures, isSlaveClamp]);

// Module
export const filterModules = ({ products, product }) => {
    if (isRollingShutter_shutters(product)) {
        return reduceProducts(products, [isRollingShutter_shutters]);
    } else if (isLightModule_lighting(product)) {
        return reduceProducts(products, [isLightModule_lighting]);
    }
};

// Probe
export const filterProbes = products =>
    reduceProducts(products, [isProbe_temperature]);

export const filterRelaysAndWires = products => ({
    ...reduceProducts(products, [isRelay_temperature]),
    ...reduceProducts(products, [isPilotWire_temperature]),
});
