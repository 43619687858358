import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  active: PropTypes.bool,
};

const defaultProps = {
  width: 50,
  height: 50,
  primaryColor: "#d62918",
  secondaryColor: "#9293a1",
  active: false,
};

const AngelottiLogo = (props) => {
  return (
    <svg height={props.height} width={props.width} viewBox="0 0 300 134.55">
      <path
        d="M231.73.28c-.82.41 4.83 5.88 9.18 14.67a117.93 117.93 0 0 1 12.65 52.33c0 19.75-4.55 38.48-13.62 54.17-4.37 7.55-8.85 12.29-8.35 13s10.29-4.46 15-6.82C280.06 111.02 300 90.05 300 67.28c0-22.6-19.61-43.41-52.54-60 0 0-14.91-7.45-15.73-7"
        fill={props.primaryColor}
      />
      <path
        d="M188.65 19c-23.3-13-48.75-2.48-71.63 4.86-11 3.52-28.83 13.74-38.21 1.41a34.77 34.77 0 0 1-6.22-12.79C71.39 8.21 69.38.56 68.36.03s-13 5.14-20 8.7C25.93 20.15 2.27 38.38.15 63.83c-2.54 30.61 27.27 51 52.53 63.39 5.5 2.69 15.48 8 15.48 6.95s-6.28-6.29-9.64-12.73c-6.77-13-1.09-30.46 9.81-41.35 17.7-17.69 45.16-22.36 68.24-25.5 14.75-2 20.43-2.9 43.82-3.89 19.84-.84 25.91-21.82 8.25-31.7"
        fill={props.secondaryColor}
      />
      <path
        d="M199.75 84.98c-7.58-3.67-65-.33-80.52 12.08-8.59 6.86-9.73 14.47-7 20.3 2.72 6.86 10.85 12.49 21.59 15.24a42 42 0 0 0 9.45 1.59c1.78.15 3.6.2 5.46.24 40.78.9 61-43.47 51.06-49.44"
        fill={props.primaryColor}
      />
    </svg>
  );
};

AngelottiLogo.propTypes = propTypes;
AngelottiLogo.defaultProps = defaultProps;

export default AngelottiLogo;
