import React, { useEffect, useState } from 'react';
import InputWrapper from '../InputWrapper';
import { remotesApi } from 'data/remotes/api';
import Select from 'data/components/Select';
import t from 'utils/Translator';

let remotes = {};

export default function InputRemote({ formik }) {
    const [loading, setLoading] = useState(false);
    const [remotesOptions, setRemotesOptions] = useState([]);

    const getRemotesList = async () => {
        try {
            setLoading(true);
            const response = await remotesApi.getRemotes();
            const array = Object.keys(response).map(remote => ({
                label: remote,
                value: remote,
            }));
            remotes = response;
            setRemotesOptions(array);
        } catch (e) {
            console.log('🌟 ==>e', e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        return getRemotesList();
    }, []);

    const handleChange = ({ target: { value } }) => {
        formik.setFieldValue('remote_ref', value);
        formik.setFieldValue('remote', remotes[value]);
    };

    return (
        <InputWrapper>
            <Select
                disabled={loading}
                fullWidth
                id="remote_ref"
                name="remote_ref"
                label={t.get('inputs.remote')}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.remote_ref}
                options={remotesOptions}
            />
        </InputWrapper>
    );
}
