import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const ModificationIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 300">
            <g fill={props.color}>
                <path d="M275.22,90c.41-.36.81-.73,1.2-1.12l16.33-16.33a24.73,24.73,0,0,0,0-35L262.43,7.25a24.73,24.73,0,0,0-35,0L211.11,23.58c-.39.39-.76.79-1.12,1.21Z" />
                <polygon points="43.74 256.26 75.99 288.51 37.99 294.26 0 300 5.74 262.01 11.49 224.01 43.74 256.26" />
                <polygon points="219.86 52.15 47.24 224.77 37.91 215.44 210.53 42.82 201.19 33.49 19.24 215.44 84.56 280.76 266.51 98.81 219.86 52.15" />
            </g>
        </svg>
    );
};

ModificationIcon.propTypes = propTypes;
ModificationIcon.defaultProps = defaultProps;

export default ModificationIcon;
