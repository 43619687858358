import React, { useContext, useEffect, useState } from 'react';
import Grid from 'data/components/Grid';
import BackgroundImageLogin from './components/BackgroundImageLogin';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { login, init } from 'store/auth/thunks';
import Paper from 'data/components/Paper';
import FormControl from 'data/components/FormControl';
import logo from 'assets/logo_Sweetom.png';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Link,
    TextField,
} from '@mui/material';
import t from 'utils/Translator';
import Text from 'data/components/Text';
import Button from 'data/components/Button';
import LoginGoogle from './components/LoginGoogle';
import LoginFacebook from './components/LoginFacebook';
import { SnackbarContext } from 'data/components/SnackbarProvider';
import Loader from './components/Loader';
import { makeStyles } from '@mui/styles';
import { setTokenToHttpHeader, setTokenToStorages } from '../../../utils';
import PasswordRecoveryModal from '../../../data/components/Modals/PasswordRecoveryModals/PasswordRecoveryModal';
import useModal from '../../../utils/hooks/useModal';
import Modal from '../../../data/components/Modals/Modal';
import RecoverAndAuthModal from '../../../data/components/Modals/PasswordRecoveryModals/RecoverAndAuthModal';

const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
};

const validationSchema = Yup.object({
    email: Yup.string().required('Enter a valid email'),
    password: Yup.string().required('Enter a valid password'),
    rememberMe: Yup.boolean(),
});

export default function Login() {
    const { loading } = useSelector(state => state.auth);
    const classes = useStyles({ loading });
    const dispatch = useDispatch();
    const history = useHistory();
    const [recoverEmail, setRecoverEmail] = useState('');
    const [
        passwordRecoveryModalIsOpen,
        togglePasswordRecoveryModal,
    ] = useModal();
    const [recoverAndAuthModalIsOpen, toggleRecoverAndAuthModal] = useModal();
    const { setIsOpen } = useContext(SnackbarContext);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            const { payload } = await dispatch(login(values));
            return toDashboard(payload);
        },
    });
    let location = `${window.location}`;
    let lastFive = location.substr(location.length - 5);
    let token = location.substring(location.indexOf('=') + 1);

    useEffect(() => {
        if (token && lastFive !== 'login') {
            setTokenToHttpHeader(token);
            setTokenToStorages('false', token);
            history.push('/dashboard');
        }
    }, []);

    const toDashboard = async payload => {
        if (payload) history.push('/dashboard');
        else setIsOpen(true);
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.container}
        >
            <BackgroundImageLogin />
            <Paper elevation={5} className={classes.paper}>
                <Loader loading={loading} />
                <form onSubmit={formik.handleSubmit}>
                    <FormControl className={classes.form}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.imageContainer}
                        >
                            <img
                                src={logo}
                                alt="logo"
                                height={130}
                                width="auto"
                            />
                        </Grid>
                        <TextField
                            fullWidth
                            variant="standard"
                            id="email"
                            name="email"
                            label={t.get('login.email')}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            className={classes.input}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            id="password"
                            name="password"
                            autoComplete="password"
                            label={t.get('login.password')}
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                            helperText={
                                formik.touched.password &&
                                formik.errors.password
                            }
                            className={classes.input}
                        />
                        <Box className={classes.actionsContainer}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.rememberMe}
                                        onChange={formik.handleChange}
                                        name="rememberMe"
                                        color="primary"
                                    />
                                }
                                label={
                                    <Text variant="body2">
                                        {t.get('login.rememberMe')}
                                    </Text>
                                }
                                className={classes.remember}
                            />
                            <Link
                                component="button"
                                color={'primary'}
                                variant={'body2'}
                                underline={'hover'}
                                className={classes.forgotPassword}
                                onClick={togglePasswordRecoveryModal}
                                type={'button'}
                            >
                                {t.get('login.forgotPassword')}
                            </Link>
                        </Box>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={classes.loginButton}
                            type="submit"
                        >
                            {t.get('login.login').toUpperCase()}
                        </Button>
                        <Text align="center" variant="body2">
                            {t.get('login.alternative')}
                        </Text>
                        <Grid
                            container
                            spacing={2}
                            className={classes.socialsContainer}
                        >
                            <Grid item xs={6}>
                                <LoginFacebook
                                    rememberMe={formik.values.rememberMe}
                                    toDashboard={toDashboard}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LoginGoogle
                                    rememberMe={formik.values.rememberMe}
                                    toDashboard={toDashboard}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </form>
            </Paper>
            <Modal
                open={passwordRecoveryModalIsOpen}
                closeFunction={togglePasswordRecoveryModal}
                modalForm
            >
                <PasswordRecoveryModal
                    close={togglePasswordRecoveryModal}
                    togglerecoverandauthmodal={toggleRecoverAndAuthModal}
                    setRecoverEmail={setRecoverEmail}
                />
            </Modal>
            <Modal
                open={recoverAndAuthModalIsOpen}
                closeFunction={toggleRecoverAndAuthModal}
                modalForm
            >
                <RecoverAndAuthModal
                    close={toggleRecoverAndAuthModal}
                    recoverEmail={recoverEmail}
                />
            </Modal>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    a: {
        margin: '15px',
    },
    container: {
        height: '99vh',
    },
    paper: {
        position: 'relative',
    },
    title: {},
    imageContainer: {
        marginBottom: 20,
    },
    form: {
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        width: 420,
        padding: '35px 50px 50px 50px',
    },
    input: {
        margin: '22px 0 0 0',
    },
    actionsContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    remember: {
        marginTop: 10,
        color: theme.palette.dark.main,
        width: '55%',
    },
    forgotPassword: {
        marginTop: 10,
        width: '45%',
    },
    loginButton: {
        margin: '30px 0',
    },
    socialsContainer: {
        marginTop: 5,
    },
    socialsButtons: {
        height: 25,
    },
}));
