import React, { useEffect, useState } from 'react';
import InputWrapper from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/InputWrapper';
import { getModuleKey } from 'utils';
import SubmitButton from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/SubmitButton';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { InputLabel, MenuItem } from '@mui/material';
import MUISelect from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import FormDivider from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/FormDivider';

export default function InputLinkProduct({
    products,
    product,
    isMaster,
    label,
    slaves,
    optionIsDisabled,
    submitFunc,
}) {
    const dispatch = useDispatch();
    const [slaveKeys, setSlaveKeys] = useState([]);
    const masterKey = isMaster ? getModuleKey(product) : null;
    const [focused, setFocused] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (isMaster) {
            setSlaveKeys(slaves);
        }
    }, []);

    const handleChange = ({ target: { value } }) => {
        setSlaveKeys(value);
    };

    const renderValue = selected => {
        if (selected.length === 0) return 'Aucune';
        return selected.reduce((acc, moduleKey) => {
            acc += `${products[moduleKey].label}, `;
            return acc;
        }, '');
    };

    const isDisabled = moduleKey => {
        return optionIsDisabled(products, moduleKey, masterKey);
    };

    const handleSubmit = async () => {
        await dispatch(
            submitFunc({
                master: product,
                slaveKeys,
            }),
        );
    };

    return (
        <>
            <FormDivider />
            <InputWrapper>
                <InputLabel id={`${label}-label`} shrink focused={focused}>
                    {label}
                </InputLabel>
                <MUISelect
                    displayEmpty
                    onChange={handleChange}
                    fullWidth
                    value={slaveKeys}
                    labelId={`${label}-label`}
                    id={label}
                    multiple
                    onOpen={() => setFocused(true)}
                    onClose={() => setFocused(false)}
                    renderValue={renderValue}
                    MenuProps={{
                        getContentAnchorEl: () => null,
                        disableScrollLock: true,
                        style: {
                            zIndex: 1301,
                        },
                    }}
                >
                    {Object.keys(products).map(moduleKey => (
                        <MenuItem
                            disabled={isDisabled(moduleKey)}
                            key={moduleKey}
                            value={moduleKey}
                            className={classes.item}
                        >
                            <Checkbox
                                disabled={isDisabled(moduleKey)}
                                color="primary"
                                checked={slaveKeys.indexOf(moduleKey) > -1}
                            />
                            <ListItemText primary={products[moduleKey].label} />
                        </MenuItem>
                    ))}
                </MUISelect>
            </InputWrapper>
            <SubmitButton
                disabled={_.isEqual(slaves, slaveKeys)}
                handleSubmit={handleSubmit}
            />
        </>
    );
}
const useStyles = makeStyles(theme => ({
    item: { color: theme.palette.primary.main },
}));
