import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const CollectedInfosIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 300">
            <g fill={props.color}>
                <path d="M300,26.49A26.52,26.52,0,0,0,273.51,0h-209A26.51,26.51,0,0,0,38,26.49v21H26.49A26.52,26.52,0,0,0,0,74V264a36,36,0,0,0,36,36H264a36,36,0,0,0,36-36ZM264,285H36a21,21,0,0,1-21-21V74A11.53,11.53,0,0,1,26.49,62.47H38V254.53a7.47,7.47,0,0,0,14.93,0v-228A11.54,11.54,0,0,1,64.46,15h209A11.53,11.53,0,0,1,285,26.49V262l0,2A21,21,0,0,1,264,285Z" />
                <path d="M183.26,121.55h76a4.75,4.75,0,1,0,0-9.49h-76a4.75,4.75,0,1,0,0,9.49Z" />
                <path d="M183.26,93h76a4.75,4.75,0,0,0,0-9.5h-76a4.75,4.75,0,0,0,0,9.5Z" />
                <path d="M183.26,64.52h76a4.75,4.75,0,1,0,0-9.49h-76a4.75,4.75,0,1,0,0,9.49Z" />
                <path d="M154.75,254.56h-76a4.75,4.75,0,0,0,0,9.49h76a4.75,4.75,0,0,0,0-9.49Z" />
                <path d="M154.75,226h-76a4.75,4.75,0,0,0,0,9.5h76a4.75,4.75,0,0,0,0-9.5Z" />
                <path d="M154.75,197.53h-76a4.75,4.75,0,0,0,0,9.5h76a4.75,4.75,0,0,0,0-9.5Z" />
                <path d="M259.27,254.56h-76a4.75,4.75,0,1,0,0,9.49h76a4.75,4.75,0,1,0,0-9.49Z" />
                <path d="M259.27,226h-76a4.75,4.75,0,0,0,0,9.5h76a4.75,4.75,0,0,0,0-9.5Z" />
                <path d="M259.27,197.53h-76a4.75,4.75,0,0,0,0,9.5h76a4.75,4.75,0,0,0,0-9.5Z" />
                <path d="M259.27,140.54H78.73a4.75,4.75,0,0,0,0,9.49H259.27a4.75,4.75,0,1,0,0-9.49Z" />
                <path d="M259.27,169.05H78.73a4.75,4.75,0,0,0,0,9.49H259.27a4.75,4.75,0,1,0,0-9.49Z" />
                <path d="M83.48,121.49H150a9.49,9.49,0,0,0,9.49-9.5V45.54A9.49,9.49,0,0,0,150,36H83.48A9.49,9.49,0,0,0,74,45.54V112A9.49,9.49,0,0,0,83.48,121.49ZM93,55h47.5v47.5H93Z" />
            </g>
        </svg>
    );
};

CollectedInfosIcon.propTypes = propTypes;
CollectedInfosIcon.defaultProps = defaultProps;

export default CollectedInfosIcon;
