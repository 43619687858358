import { Redirect, Route } from 'react-router-dom';
import Layout from '../screens/Layout';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { authSlice } from '../../store/auth/authSlice';
import { getTokenFromStorages } from '../../utils';

const PrivateRoute = ({ component, ...rest }) => {
    const tokenFromStore = useSelector(state => state.auth.token);
    const tokenFromStorage = getTokenFromStorages();

    const dispatch = useDispatch();
    if (tokenFromStorage) {
        dispatch(authSlice.actions.setToken(tokenFromStorage));
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                tokenFromStorage || tokenFromStore ? (
                    <Layout component={component} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
