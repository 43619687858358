import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const AddressIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 300">
            <g fill={props.color}>
                <path d="M295.93,112.84c-5.4-4.1-10.83-8.31-16.09-12.39-4.89-3.8-9.94-7.72-14.95-11.53a7.73,7.73,0,0,1-3.27-6.83c.06-20.15.06-40.65.05-60.46V9.16c0-6.42-2.78-9.16-9.27-9.16H47.3a9,9,0,0,0-9,9V21c0,20.06,0,40.8.06,61.2A7.84,7.84,0,0,1,35,89C29,93.76,22.82,98.64,16.88,103.35q-6.16,4.91-12.33,9.77A11.29,11.29,0,0,0,0,122.65C.05,179,.05,235.36,0,290.11c0,3.4.8,5.93,2.38,7.51S6.48,300,9.85,300h0c34.95-.06,70.48-.05,104.85,0H290.65c6.34,0,9.29-2.93,9.29-9.22V249.49c0-42.06,0-85.55.06-128.33A9.34,9.34,0,0,0,295.93,112.84ZM261.85,105l18.94,14.41-18.94,19ZM52.15,31.48V20.21c0-.69.05-1.34.09-2,0-.32,0-.65.07-1l.14-2.39H246.91L247,17.2c0,.36,0,.7.06,1.05,0,.76.09,1.56.09,2.36V51c0,32.28,0,65.66.06,98.49,0,4.32-2.33,6.48-5,9-.74.67-1.57,1.44-2.45,2.32-4.9,4.92-9.76,9.56-14.46,14.06-7.16,6.85-14.56,13.94-22.39,22.22-1.37,1.45-2.4,2.64-3.3,3.7a51.3,51.3,0,0,1-3.8,4.1l-1.58,1.52-26.64-20.74-12.3-9.59c-3.8-3-7.31-3-11-.13-8.17,6.33-16.46,12.8-24.46,19.06l-10.51,8.21-1.57,1.19-2.63,2-4.54-4.44q-3.27-3.19-6.52-6.39L87.39,189C76.72,178.5,65.68,167.61,54.9,156.81a9.41,9.41,0,0,1-2.68-6.2C52.13,110.9,52.14,70.52,52.15,31.48ZM38.11,105v33L19.36,119.21ZM14.79,137.13,94.07,215,14.79,277.49ZM28.25,285.24,150,189.64l121.72,95.6Zm256.87-7.81-79.2-62.38,79.2-77.83Z" />
                <path d="M217.33,130.33H82.67a5,5,0,1,1,0-10H217.33a5,5,0,0,1,0,10Z" />
                <path d="M217.33,107.67H82.67a5,5,0,0,1,0-10H217.33a5,5,0,0,1,0,10Z" />
                <path d="M217.33,84.67H82.67a5,5,0,0,1,0-10H217.33a5,5,0,0,1,0,10Z" />
                <path d="M217.33,61.33H82.67a5,5,0,0,1,0-10H217.33a5,5,0,0,1,0,10Z" />
            </g>
        </svg>
    );
};

AddressIcon.propTypes = propTypes;
AddressIcon.defaultProps = defaultProps;

export default AddressIcon;
