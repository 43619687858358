import { useSelector } from 'react-redux';
import { weathersSelectors } from 'store/weather/weatherSlice';
import { makeStyles } from '@mui/styles';
import Grid from 'data/components/Grid';
import Text from 'data/components/Text';
import WeatherIcon from 'data/components/WeatherIcon';
import React from 'react';
import { getWeatherDescription } from 'utils';
import t from 'utils/Translator';
import { useHistory } from 'react-router-dom';
import Tooltip from 'data/components/Tooltip';
import Sun from 'navigation/screens/Weathers/components/Sun';

export default function WeatherHeader(props) {
    const classes = useStyles();
    const history = useHistory();
    const currentWeather = useSelector(weathersSelectors.selectCurrentWeather);
    const currentDay = useSelector(state =>
        weathersSelectors.selectById(state, 'day0'),
    );

    const goToWeathers = e => {
        e.stopPropagation();
        history.push('/weather');
    };

    if (props.isExpanded || !currentWeather) {
        return null;
    }

    return (
        <Grid container alignItems="center" className={classes.container}>
            <Grid item md={6} xs={12}>
                <div className={classes.content} onClick={goToWeathers}>
                    <Tooltip title={getWeatherDescription(currentWeather)}>
                        <div>
                            <WeatherIcon
                                code={currentWeather.weatherCode}
                                height={52}
                                fill="white"
                            />
                        </div>
                    </Tooltip>
                    <Tooltip title={t.get('weathers.temperature')}>
                        <div>
                            <Text
                                color="secondary"
                                variant="h6"
                                onClick={goToWeathers}
                            >{`${currentWeather.tempC}°C`}</Text>
                        </div>
                    </Tooltip>
                    <Tooltip title={t.get('weathers.cloudCover')}>
                        <div
                            className={classes.cloudCover}
                            onClick={goToWeathers}
                        >
                            <WeatherIcon code="116" height={28} fill="white" />
                            <Text
                                color="secondary"
                                variant="h6"
                            >{`${currentWeather.cloudcover}%`}</Text>
                        </div>
                    </Tooltip>
                    <Sun
                        variant="h6"
                        color="secondary"
                        astronomy={currentDay?.astronomy[0]}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {},
    content: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
    },
    cloudCover: {
        display: 'flex',
        margin: '0 12px',
        alignItems: 'center',
    },
}));
