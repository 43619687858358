import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { startProductInclusion } from '../../../../../MiddlewareSocket/emitters/widgets';
import FormControl from '../../../FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import t from '../../../../../utils/Translator';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const initialValues = {
    SSID: '',
    password: '',
    encryption: 'WPA2',
    encryption_type: 'TKIP',
};

const validationSchema = Yup.object({
    SSID: Yup.string().required('Entrez un SSID valide'),
    password: Yup.string().required('Entrez un mot de passe valide'),
    encryption: Yup.string(),
    encryption_type: Yup.string(),
});

const InclusionWizardSecondStep = props => {
    const classes = useStyles();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            await startProductInclusion(
                props.focusedProduct.bssid,
                values.SSID,
                values.password,
                values.encryption,
                values.encryption_type,
            );
            props.setLoading(true);
            props.handleNext();
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className={classes.form}>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    freeSolo
                    id="freeSolo"
                    className={classes.input}
                    onClick={value => {
                        console.log(value, 'le freesolo');
                        formik.handleChange();
                    }}
                    options={props.WifiList.map(option => option.ssid)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            id="SSID"
                            name="SSID"
                            label={'SSID'}
                            value={formik.values.SSID}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.SSID &&
                                Boolean(formik.errors.SSID)
                            }
                            helperText={
                                formik.touched.SSID && formik.errors.SSID
                            }
                        />
                    )}
                />

                <TextField
                    className={classes.input}
                    id="password"
                    name="password"
                    autoComplete="password"
                    label={t.get('login.password')}
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                    }
                    helperText={
                        formik.touched.password && formik.errors.password
                    }
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <FormControl>
                    <InputLabel id="encryption" style={{ width: 200 }}>
                        Cryptage
                    </InputLabel>
                    <Select
                        labelId="encryption"
                        id="encryption"
                        value={formik.values.encryption}
                        label="Cryptage"
                        onChange={formik.handleChange}
                    >
                        <MenuItem value={'WPA2'}>WPA2</MenuItem>
                        <MenuItem value={'WPA'}>WPA</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="encryption_type" style={{ width: 200 }}>
                        Type de cryptage
                    </InputLabel>
                    <Select
                        labelId="encryption_type"
                        id="encryption_type"
                        value={formik.values.encryption_type}
                        label="Type de cryptage"
                        onChange={formik.handleChange}
                    >
                        <MenuItem value={'TKIP'}>TKIP</MenuItem>
                        <MenuItem value={'AES'}>AES</MenuItem>
                    </Select>
                </FormControl>
            </FormControl>

            <Button type={'submit'}>Appairer</Button>
        </form>
    );
};

const useStyles = makeStyles(theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 14,
        gap: 20,
    },
    form: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    input: {
        width: 200,
        display: 'inline-block',
    },
}));

export default InclusionWizardSecondStep;
