import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

function PanelIcon(props) {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 138.98">
            <path
                fill={props.color}
                d="M300,102.31l-.49,0H300c0-.24,0-.48-.07-.77l0-.16a5.6,5.6,0,0,0-.27-.92L254.39,3.18C253.61,1.25,251.76,0,247.73,0H50.31a5,5,0,0,0-4.2,3.2L.36,100.43a.5.5,0,0,0,0,.13,4.85,4.85,0,0,0-.22.78l0,.13a4.94,4.94,0,0,0-.07.87c0,.06,0,.11,0,.24a5.69,5.69,0,0,0,.08.68c0,.08,0,.16.05.23a4.76,4.76,0,0,0,.27.83,5.31,5.31,0,0,0,.41.77l.13.18.42-.25-.34.36.13.18,12.67,10.6A5.09,5.09,0,0,0,17.76,118h50l-.22.38A5,5,0,0,0,66.8,121v12.9a5.11,5.11,0,0,0,5.1,5.1H85.26a5.1,5.1,0,0,0,4.67-3.07l7.6-17.51c.06-.13.11-.26-1.8-.39H200.6c2,.13,2,.26,2.09.39l7.61,17.51A5.1,5.1,0,0,0,215,139h13.36a5.1,5.1,0,0,0,5.09-5.1V121a5,5,0,0,0-.71-2.59l-.22-.38h51.6a5.09,5.09,0,0,0,4.26-2.31l10.83-10.6a4.9,4.9,0,0,0,.39-.73l.07-.2a5.39,5.39,0,0,0,.23-.71l0-.16a4.93,4.93,0,0,0,.1-.85ZM220.65,118l2.59,4.37v6.41h-4.93L213.63,118ZM158.6,23.13l-2.94-2.56V8.77L159,6.31h37.16l3.55,2.46,2.73,11.68-2.9,2.68Zm96.48-2.68-3.64,2.68h-36l-3.41-2.64L209.32,8.77l2.79-2.46h33l4.41,2.46ZM222.44,51.7l-4.78-3.31-3.6-14,1.34-3.61h36.5L259,34.37l7.36,14L264,51.7Zm43.47,9.48,5.56,3.62,9.88,22.44s-4.58,5.05-4.6,5.11H232.29l-7.19-5.07-4.94-22.61,2.58-3.49ZM155.66,34.37l2.9-3.61h41.79l4.12,3.61,3.6,14-3.9,3.31H159.41l-3.76-3.46Zm0,30.38,3.57-3.57h46.05l5.31,3.58,4.9,22.48-5.35,5.11H161.49l-5.83-5.16Zm-74.4-3.57,2.22,3.53L78.92,87.13,71.5,92.24l-.14.11H22.87l-4.31-5.11L28.33,64.8l5.3-3.62ZM40.51,34.47l6.73-3.71H87l2.11,3.61-3.33,14L81.56,51.7H35l-1.69-3.31ZM91,20.45l-4,2.68H47.61l-3.08-2.68L50,8.77l4.35-2.46H91.2l2.36,2.46ZM77,128.79v-6.41L79.58,118h7l-4.68,10.78Zm65.47-67.61,3.72,3.72V87.05l-6,5.3H94.42l-5.93-5.14L93,64.8l3.75-3.62Zm3.72-12.94-3.9,3.46H97.88l-2.52-3.31,3.34-14,3.68-3.61h40.75l3.05,3.45Zm-43-25.11-2.61-2.68L103.09,9l4-2.67h35.23l3.82,2.83V20.43l-3.09,2.7Z"
            />
        </svg>
    );
}

PanelIcon.propTypes = propTypes;
PanelIcon.defaultProps = defaultProps;

export default PanelIcon;
