import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

const DownArrow = props => {
    return (
        <svg
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill={props.color}
                d="M14.77 23.795L5.185 14.21a2.265 2.265 0 010-3.195l.8-.801a2.266 2.266 0 013.194 0l7.315 7.315 7.316-7.315a2.266 2.266 0 013.194 0l.8.801a2.265 2.265 0 010 3.195l-9.587 9.585a2.242 2.242 0 01-1.723.647 2.247 2.247 0 01-1.724-.647z"
            />
        </svg>
    );
};

DownArrow.propTypes = propTypes;
DownArrow.defaultProps = defaultProps;

export default DownArrow;
