import { selectors } from './productsSlice';
import { createSelector } from '@reduxjs/toolkit';
import {
    filterClamps,
    filterModules,
    filterProbes,
    filterProductsByCategories,
    filterProductsByFavorites,
    filterProductsByGroups,
    filterProductsByTypes,
    filterRelaysAndWires,
    filterUnlinkedClamps,
} from './utils/filters';
import { categoriesSelectors } from '../categories/categoriesSlice';

const { selectAll } = selectors;
export const productsSelectors = {
    ...selectors,

    // Groups selectors
    selectProductsByGroups: createSelector(selectAll, filterProductsByGroups),
    selectProductsByFavorites: createSelector(
        selectAll,
        filterProductsByFavorites,
    ),
    selectProductsByTypes: createSelector(selectAll, filterProductsByTypes),
    selectProductsByCategories: createSelector(
        state => ({
            products: selectAll(state),
            categories: categoriesSelectors.selectAll(state),
        }),
        filterProductsByCategories,
    ),
    selectUnlinkedClamps: createSelector(selectAll, filterUnlinkedClamps),
    selectClamps: createSelector(selectAll, filterClamps),
    selectProbes: createSelector(selectAll, filterProbes),
    selectRelaysAndWires: createSelector(selectAll, filterRelaysAndWires),
    selectModules: createSelector((state, product) => {
        return {
            products: selectAll(state),
            product,
        };
    }, filterModules),
};
