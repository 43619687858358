import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@mui/styles';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

function SwitchesIcon(props) {
    const StyledSvgIcon = withStyles(theme => ({
        root: {
            height: props.height,
            width: props.width,
        },
    }))(SvgIcon);

    return (
        <StyledSvgIcon
            viewBox="0 0 269.08 300"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.color}
                d="M149.44,85.1v20.23H122.87V85a67.84,67.84,0,0,0,0,133.18V200.76h26.57v17.33a67.87,67.87,0,0,0,0-133ZM101.84,164A14.14,14.14,0,1,1,116,149.84,14.14,14.14,0,0,1,101.84,164Zm70.69,0a14.14,14.14,0,1,1,14.14-14.13A14.14,14.14,0,0,1,172.53,164Z"
            />
            <path
                fill={props.color}
                d="M192.34,0H76.74C34.43,0,0,34.05,0,75.9V222.43C0,265.2,34.43,300,76.74,300h115.6a76.82,76.82,0,0,0,76.74-76.73V76.73A76.82,76.82,0,0,0,192.34,0ZM137.13,269.75a7.14,7.14,0,1,1,7.28-7.14A7.21,7.21,0,0,1,137.13,269.75Zm-.95-41.15a77.08,77.08,0,1,1,77.07-77.07A77.15,77.15,0,0,1,136.18,228.6Z"
            />
        </StyledSvgIcon>
    );
}

SwitchesIcon.propTypes = propTypes;
SwitchesIcon.defaultProps = defaultProps;

export default SwitchesIcon;
