import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';
import { getModuleKey } from 'utils';
import React from 'react';
import ListItem from 'data/components/ListItem';
import ListItemText from 'data/components/ListItemText';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

export default function PlanningEvent({ planningEvent }) {
    const classes = useStyles();

    const product = useSelector(state =>
        productsSelectors.selectById(state, getModuleKey(planningEvent)),
    );

    return (
        <ListItem>
            <ListItemText
                primary={product.label}
                secondary={
                    <Typography className={classes.secondary}>
                        {days[planningEvent.num_of_day]}
                        {` `}
                        {planningEvent.event_time} -{` `}
                        {(planningEvent?.type === 'position' &&
                            `${planningEvent?.type_value}% `) ||
                            (planningEvent?.type === 'thermostatic' &&
                                `${planningEvent?.type_value}°C`) ||
                            (planningEvent?.type === 'config_clim' &&
                                `${planningEvent?.type_value.slice(-2)}°C`) ||
                            `${planningEvent?.type_value}`}
                    </Typography>
                }
            />
        </ListItem>
    );
}

const days = {
    0: 'lundi',
    1: 'mardi',
    2: 'mercredi',
    3: 'jeudi',
    4: 'vendredi',
    5: 'samedi',
    6: 'dimanche',
};

const useStyles = makeStyles(theme => ({
    secondary: {
        color: theme.palette.text.primary,
    },
}));
