import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { getModuleKey } from 'utils';
import {
    handleFulfilledModulesAdd,
    handleFulfilledClampsAdd,
    handleFulfilledModulesRemove,
    handleFulfilledClampsRemove,
    handleFulfilledProductAPI,
    handleFulfilledProductEvent,
    handleFulfilledProbesAdd,
    handleFulfilledProbesRemove,
    handlePending,
    handleRejected,
    formatPayload,
} from './utils';
import {
    updateProductAPI,
    updateProductEvent,
    modulesLinkAdd,
    modulesLinkRemove,
    clampsLinkAdd,
    clampsLinkRemove,
    probesLinkAdd,
    probesLinkRemove,
} from 'store/products/thunks';

const adapter = createEntityAdapter({
    selectId: product => getModuleKey(product),
});
export const selectors = adapter.getSelectors(({ products }) => products);
const slice = createSlice({
    name: 'products',
    initialState: adapter.getInitialState({ loading: false, error: null }),
    reducers: {
        setAll: (state, { payload }) => {
            adapter.setAll(state, formatPayload(payload));
        },
        updateOne: adapter.updateOne,
        updateMany: adapter.updateMany,
    },
    extraReducers: builder => {
        // Update API
        builder.addCase(updateProductAPI.pending, handlePending);
        builder.addCase(updateProductAPI.fulfilled, handleFulfilledProductAPI);
        builder.addCase(updateProductAPI.rejected, handleRejected);
        // Update Event
        builder.addCase(updateProductEvent.pending, handlePending);
        builder.addCase(
            updateProductEvent.fulfilled,
            handleFulfilledProductEvent,
        );
        builder.addCase(updateProductEvent.rejected, handleRejected);
        // Clamps add
        builder.addCase(clampsLinkAdd.pending, handlePending);
        builder.addCase(clampsLinkAdd.fulfilled, handleFulfilledClampsAdd);
        builder.addCase(clampsLinkAdd.rejected, handleRejected);
        // Clamps remove
        builder.addCase(clampsLinkRemove.pending, handlePending);
        builder.addCase(
            clampsLinkRemove.fulfilled,
            handleFulfilledClampsRemove,
        );
        builder.addCase(clampsLinkRemove.rejected, handleRejected);
        // Module add
        builder.addCase(modulesLinkAdd.pending, handlePending);
        builder.addCase(modulesLinkAdd.fulfilled, handleFulfilledModulesAdd);
        builder.addCase(modulesLinkAdd.rejected, handleRejected);
        // Module remove
        builder.addCase(modulesLinkRemove.pending, handlePending);
        builder.addCase(
            modulesLinkRemove.fulfilled,
            handleFulfilledModulesRemove,
        );
        builder.addCase(modulesLinkRemove.rejected, handleRejected);
        // Probes add
        builder.addCase(probesLinkAdd.pending, handlePending);
        builder.addCase(probesLinkAdd.fulfilled, handleFulfilledProbesAdd);
        builder.addCase(probesLinkAdd.rejected, handleRejected);
        // Probes add
        builder.addCase(probesLinkRemove.pending, handlePending);
        builder.addCase(
            probesLinkRemove.fulfilled,
            handleFulfilledProbesRemove,
        );
        builder.addCase(probesLinkRemove.rejected, handleRejected);
    },
});

export const productsActions = slice.actions;
export const productsReducer = slice.reducer;
