import React, { useState } from 'react';
import PlusIcon from '../../../assets/svgs/Plus';
import MinusIcon from '../../../assets/svgs/Minus';
import Button from '@mui/material/Button';
import {Box} from "@mui/material";

const TemperatureControls = props => {
    const classes = useStyles();

    let initialTemp = props.module.consign_temp;

    const [consignTemp, setConsignTemp] = useState(props.module.consign_temp);

    return (
        <Box className={classes.container}>
            <Button
                onClick={() => setConsignTemp(initialTemp--)}
                style={{ height: 35, width: 35 }}
            >
                <MinusIcon color={'black'} />
            </Button>
            {consignTemp}°C
            <Button
                onClick={() => setConsignTemp(initialTemp++)}
                style={{ height: 40, width: 40 }}
            >
                <PlusIcon color={'black'} />
            </Button>
        </Box>
    );
};

const useStyles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: 'red',
    },
});

export default TemperatureControls;
