import * as React from 'react';

const EuroIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{
            enableBackground: 'new 0 0 512 512',
        }}
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        {...props}
    >
        <path
            fill={props.color}
            d="M256.012 0c-141.156 0-256 114.844-256 256s114.844 256 256 256c141.148 0 255.976-114.844 255.976-256S397.16 0 256.012 0zm95.312 391.5c-23.724 13.772-50.784 20.776-78.256 20.776C205.9 412.276 145.248 368 124.532 304h-20.06c-11.028 0-19.996-8.976-19.996-20 0-11.028 8.968-20 19.996-20h12.688c-.164-4-.244-5.444-.244-8s.08-4 .244-8h-12.688c-11.028 0-19.996-8.976-19.996-20 0-11.028 8.968-20 19.996-20h20.06c20.716-64 81.368-107.876 148.532-107.876 27.472 0 54.536 7.352 78.256 21.108 9.528 5.54 12.784 17.832 7.252 27.372-3.568 6.136-10.2 9.976-17.316 9.976-3.496 0-6.956-.928-10.004-2.688-17.644-10.232-37.756-15.78-58.192-15.78-45.916 0-86.768 27.888-105.668 67.888h93.22c11.02 0 19.996 8.972 19.996 20 0 11.024-8.976 20-19.996 20H157.188c-.188 4-.28 5.304-.28 8s.092 4 .28 8h103.424c11.02 0 19.996 8.972 19.996 20 0 11.024-8.976 20-19.996 20h-93.22c18.904 40 59.752 68.292 105.672 68.292 20.432 0 40.548-5.26 58.184-15.496 3.048-1.772 6.516-2.64 10.02-2.64 7.108 0 13.744 3.848 17.308 9.996 5.532 9.536 2.272 21.82-7.252 27.348z"
        />
    </svg>
);

export default EuroIcon;
