import React from 'react';
import { makeStyles } from '@mui/styles';
import MUIModal from '@mui/material/Modal';
import Button from '../Button';
import CloseIcon from '../../../assets/svgs/Close';
import Paper from '../Paper';

export default function Modal({
    children,
    closeFunction,
    modalForm,
    planningModalForm,
    modalWizard,
    statsModal,
    ...props
}) {
    const classes = useStyles();
    return (
        <MUIModal
            {...props}
            onClose={(event, reason) => {
                if (
                    (reason === 'backdropClick' && modalForm) ||
                    modalWizard ||
                    planningModalForm ||
                    statsModal
                ) {
                    closeFunction();
                }
            }}
        >
            <div>
                <Paper className={classes.container}>
                        {children}
                        {!modalWizard &&
                            !planningModalForm &&
                            !modalForm &&
                            !statsModal && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={closeFunction}
                                >
                                    Accepter
                                </Button>
                            )}
                        {planningModalForm && (
                            <Button
                                variant="contained"
                                color="primary"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={() => {
                                    props.submit();
                                    closeFunction();
                                }}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: -80,
                                }}
                            >
                                Valider
                            </Button>
                        )}
                </Paper>
            </div>
        </MUIModal>
    );
}

const useStyles = makeStyles((theme, modalForm) => ({
    container: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: modalForm ? '66vh' : 490,
        position: 'absolute',
        zIndex: 99999,
        backgroundColor: theme.palette.background.default,
        // border: '2px solid #333',
        // borderRadius: 30,
        padding: theme.spacing(2),
        overflowY: 'auto',
    },
}));
