import React from 'react';
import Button from 'data/components/Button';
import { useGoogleLogin } from 'react-google-login';
import { config } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import {
    linkProvider,
    loginWithProvider,
    unlinkProvider,
} from 'store/auth/thunks';
import LoginIcon from './LoginIcon';
import { selectCurrentUser } from 'store/auth/authSlice';

export default function LoginGoogle({ toDashboard, rememberMe, link }) {
    const { email_google } = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const handleOnClick = async () => {
        if (link && email_google) {
            await dispatch(unlinkProvider('google'));
        } else {
            signIn();
        }
    };

    const handleSuccess = async ({ code }) => {
        if (link) {
            await dispatch(linkProvider({ code, provider: 'google' }));
        } else {
            const { payload } = await dispatch(
                loginWithProvider({ code, provider: 'google', rememberMe }),
            );
            return toDashboard(payload);
        }
    };

    const { signIn } = useGoogleLogin({
        clientId: config.GOOGLE_CLIENT_ID,
        responseType: 'code',
        accessType: 'offline',
        autoLoad: false,
        cookiePolicy: 'single_host_origin',
        onSuccess: handleSuccess,
    });

    return (
        <Button
            color="dark"
            variant="outlined"
            fullWidth
            onClick={handleOnClick}
            startIcon={<LoginIcon provider="google" />}
        >
            {link ? email_google || 'Lier avec Google' : 'Google'}
        </Button>
    );
}
