import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@mui/styles';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

function LightingIcon(props) {
    const StyledSvgIcon = withStyles(theme => ({
        root: {
            height: props.height,
            width: props.width,
        },
    }))(SvgIcon);

    return (
        <StyledSvgIcon
            viewBox="0 0 211.43 300"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.color}
                d="M58.71 289.17c4.08 8.32 13 10.83 21.67 10.83h50.66c8.65 0 17.58-2.5 21.67-10.83a29.82 29.82 0 0 0 2.69-11.82H56.02a29.82 29.82 0 0 0 2.69 11.82zM57.14 244.83c0 6-.35 12-.69 18.07h98.52c-.34-6-.66-12-.69-18.07zM105.71 0a105.8 105.8 0 0 0-101 137.14c6.41 17.51 18.31 33.38 30.76 47.08 11.41 12.55 18.41 29.23 20.74 46.16h98.92c2.33-16.93 9.32-33.61 20.74-46.16 12.46-13.7 24.35-29.57 30.76-47.08A105.8 105.8 0 0 0 105.71 0zm61.08 73.08a7.22 7.22 0 0 1-10-1.94 70.25 70.25 0 0 0-58.27-31 7.23 7.23 0 0 1 0-14.45 84.67 84.67 0 0 1 70.19 37.36 7.22 7.22 0 0 1-1.92 10.03z"
            />
        </StyledSvgIcon>
    );
}

LightingIcon.propTypes = propTypes;
LightingIcon.defaultProps = defaultProps;

export default LightingIcon;
