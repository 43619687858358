import EventEmitter from 'utils/EventEmitter';
import MiddlewareSocket from 'MiddlewareSocket/index';
import { productsActions } from 'store/products/productsSlice';
import { getModuleChanges, getModuleKey } from 'utils';
import { boxActions } from '../../store/box/boxSlice';

export const binds = dispatch => {
    EventEmitter.once('middlewareConnected', () => {
        MiddlewareSocket.on('heartbeat', payload => {
            dispatch(boxActions.setBoxMacAddress(payload));
            dispatch(boxActions.setBoxStatus({ status: 'connected' }));
        });

        MiddlewareSocket.on('boxStatus', payload => {
            dispatch(boxActions.setBoxStatus(payload));
        });

        MiddlewareSocket.on('fheProductRelayState', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { value: payload.state },
                }),
            );
        });

        MiddlewareSocket.on('fheProductPower', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { power: payload.power },
                }),
            );
        });

        MiddlewareSocket.on('fheProductMeasures', payload => {
            const changes = getModuleChanges(payload);
            dispatch(productsActions.updateMany(changes));
        });

        MiddlewareSocket.on('fheProductExternalTemp', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: {
                        last_data: {
                            instant: {
                                temp_out: payload.temperature,
                            },
                        },
                    },
                }),
            );
        });

        MiddlewareSocket.on('fheProductThermostaticStateChanged', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { state: payload.state },
                }),
            );
        });

        MiddlewareSocket.on('fheProductAlarmTriggered', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { alarm_state: payload.alarmState },
                }),
            );
        });

        MiddlewareSocket.on('fheProductPositionChanged', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { value: payload.position },
                }),
            );
        });

        MiddlewareSocket.on('productModuleThresholdChanged', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { value: payload.position },
                }),
            );
        });

        /*        MiddlewareSocket.on('fheProductMode', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { fhe_product_mode: payload.fhe_product_mode },
                }),
            );
        });*/

        // SDM18HCL
        MiddlewareSocket.on('fheProductInternalTemp', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: {
                        last_data: {
                            instant: {
                                temp_in: payload.temperature,
                            },
                        },
                    },
                }),
            );
        });
        MiddlewareSocket.on('fheProductHumidity', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { humidity: payload.humidity },
                }),
            );
        });
        MiddlewareSocket.on('fheProductClimConfigChanged', payload => {
            const { config } = payload;

            if (config.startsWith('OFF')) {
                dispatch(
                    productsActions.updateOne({
                        id: getModuleKey(payload),
                        changes: { clim_state: 'OFF' },
                    }),
                );
            } else {
                dispatch(
                    productsActions.updateOne({
                        id: getModuleKey(payload),
                        changes: {
                            clim_state: 'ON',
                            clim_mode: config[0] === 'F' ? 'COLD' : 'HOT',
                            ventilation: config[2],
                            consign_temp: config[4] + config[5],
                        },
                    }),
                );
            }
        });
        MiddlewareSocket.on('inelioStateUpdate', payload => {
            console.log(payload, 'letat de linelio');
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: {
                        stageState: payload.state,
                        stageStateTimestamp: payload.timestamp,
                    },
                }),
            );
        });
        MiddlewareSocket.on('inelioSlaveMeasures', payload => {
            dispatch(
                productsActions.updateOne({
                    id: getModuleKey(payload),
                    changes: { measures: payload.measures },
                }),
            );
        });
        // MiddlewareSocket.on('inelioStageMeasures', payload => {
        //     console.log(payload, 'les mesures de linelio');
        //     dispatch(
        //         productsActions.updateOne({
        //             id: getModuleKey(payload),
        //             changes: { measures: payload.measures },
        //         }),
        //     );
        // });
        MiddlewareSocket.on('productInclusionStep', payload => {
            console.log(payload, 'Étape dinclusion');
        });
        MiddlewareSocket.on('productInclusion', payload => {
            console.log(payload, 'Inclusion Terminé');
        });
        MiddlewareSocket.on('fheProductStatus', payload => {
            // dispatch(productsActions.setAll(payload))
            console.log(payload, 'Status Produit');
        });
    });
};
