import React from 'react';
import {
    isLighting,
    isSecurity,
    isShutter,
    isSwitch,
    isTemperature,
    isInelio,
} from 'store/products/utils/predicates';

import Shutter from './Shutter';
import Switch from './Switch';
import Lighting from './Lighting';
import Temperature from './Temperature';
import Security from './Security';
import Measure from './Measure';
import Inelio from './Inelio';

import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';

export default function Product({ id }) {
    const product = useSelector(state =>
        productsSelectors.selectById(state, id),
    );

    const getProductComponent = product => {
        if (isShutter(product)) return Shutter;
        if (isSwitch(product)) return Switch;
        if (isLighting(product)) return Lighting;
        if (isInelio(product)) return Inelio;
        if (isTemperature(product)) return Temperature;
        if (isSecurity(product)) return Security;
        return Measure;
    };
    const Component = getProductComponent(product);

    return <Component product={product} />;
}
