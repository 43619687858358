import {Box, Button} from '@mui/material';
import moment from 'moment';
import React from 'react';
import Text from '../Text';
import { equals, reject } from 'ramda';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
};

const defaultProps = {
    multiple: false,
};

const DayButton = props => {
    let containerStyles = props.classes.dayButton;
    let textStyles = props.classes.buttonText;

    if (props.selected) {
        containerStyles = props.classes.button__selected;
        textStyles = props.classes.buttonText__selected;
    }

    return (
        <Button
            className={containerStyles}
            onClick={() => props.onChange(props.dayNumber)}
            key={props.dayNumber}
            variant={props.selected ? 'contained' : 'outlined'}
            color={'primary'}
        >
            <Text className={textStyles}>
                {moment()
                    .startOf('isoWeek')
                    .add(props.dayNumber, 'days')
                    .format('dd')}
            </Text>
        </Button>
    );
};

const DAY_NUMBERS = [0, 1, 2, 3, 4, 5, 6];

const ByDaySelector = props => {
    const classes = useStyles();

    const onChange = dayPressed => {
        if (props.multiple) {
            onChangeMultiple(dayPressed);
        } else {
            onChangeOne(dayPressed);
        }
    };

    const onChangeOne = dayPressed => {
        if (props.value.includes(dayPressed)) {
            return;
        }

        props.onChange([dayPressed]);
    };

    const onChangeMultiple = dayPressed => {
        const daySelected = props.value.includes(dayPressed);

        // Si la periode ne contient qu'un seul jour, on empèche de le désélectionner
        if (daySelected && props.value.length === 1) {
            return;
        }

        if (daySelected) {
            props.onChange(reject(equals(dayPressed), props.value));
        } else {
            props.onChange([...props.value, dayPressed]);
        }
    };

    return (
        <Box className={classes.container}>
            {DAY_NUMBERS.map(dayNumber => (
                <DayButton
                    selected={props.value.includes(dayNumber)}
                    key={dayNumber}
                    dayNumber={dayNumber}
                    classes={classes}
                    onChange={onChange}
                />
            ))}
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 4,
        width: 500,
    },
    button__selected: {
        display: 'flex',
        borderWidth: 1,
        borderRadius: 400,
        width: '14%',
        maxWidth: 60,
        justifyContent: 'center',
        marginHorizontal: 2,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
    },
    buttonText__selected: {
        display: 'flex',
        borderWidth: 1,
        borderRadius: 400,
        width: '14%',
        maxWidth: 60,
        justifyContent: 'center',
        marginHorizontal: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },
    dayButton: {
        display: 'flex',
        borderWidth: 1,
        borderRadius: 400,
        width: '14%',
        maxWidth: 60,
        justifyContent: 'center',
        marginHorizontal: 2,
        borderColor: theme.palette.primary.main,
        aspectRatio: 1,
    },
    buttonText: {
        display: 'flex',
        borderWidth: 1,
        borderRadius: 400,
        width: '14%',
        maxWidth: 60,
        justifyContent: 'center',
        marginHorizontal: 2,
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
}));

ByDaySelector.propTypes = propTypes;
ByDaySelector.defaultProps = defaultProps;
export default ByDaySelector;
