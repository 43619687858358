import React, { useEffect, useState } from 'react';
import Tabs from '../../../Tabs';
import PlanningTab from './PlanningTab';
import MeasuresTab from './MeasuresTab';
import { makeStyles } from '@mui/styles';
import SettingsTab from './SettingsTab';
import t from 'utils/Translator';
import Text from '../../../Text';

export default function ProductModalTabs({ product, setMaxWidth }) {
    const classes = useStyles();
    const [tabs, setTabs] = useState({
        measures: MeasuresTab,
        planning: PlanningTab,
        settings: SettingsTab,
    });
    const tabsKeys = Object.keys(tabs);

    const [value, setValue] = useState(tabsKeys[0]);
    const TabComponent = tabs[value];

    useEffect(() => {
        if (
            (product.type === 'SDM18HCN' &&
                product.line_type === 'pilotwire') ||
            (product.type === 'SDM18HCL' &&
                product.operating_mode === 'split') ||
            product.type === 'SDM19TMT' ||
            product.type === 'SDM20LHT' ||
            product.type === 'SDM18MVR'
        ) {
            setTabs({
                planning: PlanningTab,
                settings: SettingsTab,
            });
            setValue(tabsKeys[1]);
        } else if (
            (product.type === 'SDM18HCN' && product.line_type === 'probe') ||
            (product.type === 'SDM18SP2' && product.line_type === 'probe') ||
            (product.type === 'SDM19DEE' && product.line_type === 'probe') ||
            (product.type === 'SDM19STM' && product.line_type === 'probe') ||
            (product.type === 'SDM17DEC' && product.line_type === 'probe')
        ) {
            setTabs({
                settings: SettingsTab,
            });
            setValue(tabsKeys[2]);
        }
    }, []);

    return (
        <div className={classes.container}>
            <Tabs
                variant="fullWidth"
                tabs={tabsKeys.map(value => ({
                    label: (
                        <Text className={classes.label}>
                            {t.get(`tabs.${value}`)}
                        </Text>
                    ),
                    value,
                }))}
                value={value}
                onChange={(e, value) => setValue(value)}
                className={classes.tabs}
            />
            <div className={classes.component}>
                <TabComponent product={product} setMaxWidth={setMaxWidth} />
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        minWidth: 600,
        minHeight: 600,
        zIndex: 1000,
        backgroundColor: theme.palette.background.default,
    },
    tabs: {
        backgroundColor: theme.palette.background.default,
    },
    label: {
        color: theme.palette.text.primary,
    },
    component: {
        padding: 20,
    },
}));
