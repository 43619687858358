import MUITabs from '@mui/material/Tabs';
import React from 'react';
import MUITab from '@mui/material/Tab';

export default function Tabs({ tabs, ...props }) {
    return (
        <MUITabs indicatorColor="primary" textColor="primary" {...props}>
            {tabs.map(tab => (
                <MUITab key={tab.label} label={tab.label} value={tab.value} />
            ))}
        </MUITabs>
    );
}
