import Http from 'utils/Http';

const api = {
    getMessages: () =>
        Http.get('messages/get-all').then(resp => {
            return resp.messages;
        }),
    getLastMessage: () =>
        Http.get('messages/get-last').then(resp => {
            return resp.message;
        }),
};

export default api;
