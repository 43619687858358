import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@mui/styles';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

function MeasuresIcon(props) {
    const StyledSvgIcon = withStyles(theme => ({
        root: {
            height: props.height,
            width: props.width,
        },
    }))(SvgIcon);

    return (
        <StyledSvgIcon
            viewBox="0 0 195.91 300"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.color}
                d="M156.88 154l-7.19-6a5.55 5.55 0 0 0-8.5 1.81q-.55 1.09-1.19 2.2a50.84 50.84 0 0 1-10.59 13 45.51 45.51 0 0 1-18.08 9.37 5.53 5.53 0 0 0-2.44 9.28l2.2 2.17a5.54 5.54 0 0 1 1.29 2l35.58 93.72a5.54 5.54 0 0 0 3.13 3.18l37.27 14.86a5.54 5.54 0 0 0 7.4-6.6l-37-136.17a5.54 5.54 0 0 0-1.88-2.82z"
            />
            <path
                fill={props.color}
                d="M119.16.35a5.57 5.57 0 0 0-7.2 5.31v33.88a5.54 5.54 0 0 0 2.77 4.8l5.78 3.34a5.54 5.54 0 0 1 2.77 4.8v22.68a5.54 5.54 0 0 1-2.77 4.8l-19.62 11.33a5.54 5.54 0 0 1-5.54 0L75.71 80a5.54 5.54 0 0 1-2.77-4.8V52.48a5.54 5.54 0 0 1 2.77-4.8l6.13-3.54a5.54 5.54 0 0 0 2.77-4.8V5.56A5.55 5.55 0 0 0 77.52.22 73.54 73.54 0 0 0 55.86 131a5.52 5.52 0 0 1 .32 8.75L39.04 154a5.54 5.54 0 0 0-1.8 2.81L.21 293a5.54 5.54 0 0 0 7.4 6.6l37.27-14.86a5.54 5.54 0 0 0 3.13-3.18l35.52-93.68a5.54 5.54 0 0 1 1.29-2l22-21.73a5.38 5.38 0 0 1 1.95-1.24 46.21 46.21 0 0 0 6.44-2.89 50.85 50.85 0 0 0 9.12-6.52c4.82-4.55 7.62-11.18 9.21-16.51a5.54 5.54 0 0 1 2.49-3.16A73.55 73.55 0 0 0 119.16.35zM98.35 138.13a12.63 12.63 0 1 1 12.61-12.63 12.63 12.63 0 0 1-12.61 12.63z"
            />
        </StyledSvgIcon>
    );
}

MeasuresIcon.propTypes = propTypes;
MeasuresIcon.defaultProps = defaultProps;

export default MeasuresIcon;
