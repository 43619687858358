import MUISlider from '@mui/material/Slider';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { setShutterPosition } from 'MiddlewareSocket/emitters/widgets';
import { useAsync } from 'utils/hooks/useAsync';
import { isDisconnected, round10 } from 'utils';
import React from 'react';

export default function Slider({ product, ...props }) {
    const { execute, error, isLoading } = useAsync(setShutterPosition);
    const classes = useStyles();
    const [value, setValue] = useState(0);

    useEffect(() => {
        const newValue = round10(product.value);
        setValue(newValue);
    }, [product.value]);

    useEffect(() => {
        if (error) {
            setValue(round10(product.value));
        }
    }, [error]);

    const handleChangeCommitted = async () => {
        await execute(product, value);
    };

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.container}>
            <div className={classes.value}>{value}%</div>
            <MUISlider
                {...props}
                value={value}
                onChangeCommitted={handleChangeCommitted}
                onChange={handleChange}
                step={10}
                min={0}
                max={100}
                valueLabelDisplay="off"
                className={classes.slider}
                disabled={isLoading || isDisconnected(product)}
            />
        </div>
    );
}
const useStyles = makeStyles(theme => ({
    container: { display: 'flex', alignItems: 'center' },
    value: { marginRight: 10 },
    slider: {
        width: 90,
        marginLeft: 14,
    },
}));
