import MUISelect from '@mui/material/Select';
import { InputLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

export default function Select({ options, label, ...props }) {
    const [focused, setFocused] = useState(false);
    const classes = useStyles();
    return (
        <>
            {label && (
                <InputLabel shrink focused={focused} id={label}>
                    {label}
                </InputLabel>
            )}
            <MUISelect
                fullWidth
                onOpen={() => setFocused(true)}
                onClose={() => setFocused(false)}
                labelId={label}
                className={classes.item}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                {...props}
                variant={'standard'}
            >
                {options.map(option => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        className={classes.item}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MUISelect>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    item: { color: theme.palette.primary.main },
}));
