import React from 'react';

function CGVText(props) {
    return (
        <>
            {props.listing ? (
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <p> - {props.text}</p>
                </div>
            ) : (
                <p>{props.text}</p>
            )}
        </>
    );
}

export default CGVText;
