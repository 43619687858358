import * as React from "react"
import PropTypes from "prop-types";

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

const SchemePanel = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.7 83.82" {...props}>
        <defs>
            <radialGradient
                id="a"
                cx={491.24}
                cy={-153.45}
                r={26.67}
                gradientTransform="matrix(-.9 0 0 .5 487.71 147.64)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#1d1d1d" />
                <stop offset={0.1} stopColor="#2a2a2a" />
                <stop offset={0.28} stopColor="#4d4d4d" />
                <stop offset={0.54} stopColor="#868686" />
                <stop offset={0.84} stopColor="#d3d3d3" />
                <stop offset={1} stopColor="#fff" />
            </radialGradient>
            <radialGradient
                id="b"
                cx={76.29}
                cy={-2380.33}
                r={26.66}
                gradientTransform="matrix(-.32 0 0 .18 68.46 489.85)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#1d1d1d" />
                <stop offset={0.1} stopColor="#4b4b4b" />
                <stop offset={0.22} stopColor="#7a7a7a" />
                <stop offset={0.34} stopColor="#a3a3a3" />
                <stop offset={0.46} stopColor="#c4c4c4" />
                <stop offset={0.59} stopColor="#dedede" />
                <stop offset={0.71} stopColor="#f1f1f1" />
                <stop offset={0.85} stopColor="#fbfbfb" />
                <stop offset={1} stopColor="#fff" />
            </radialGradient>
        </defs>
        <g
            data-name="Calque 2"
            style={{
                isolation: "isolate",
            }}
        >
            <g data-name="Calque 1">
                <path
                    d="M20.19 70.4c0 7.41 10.78 13.42 24.08 13.42s24.07-6 24.07-13.42S57.56 57 44.27 57s-24.08 6-24.08 13.4Z"
                    fill="url(#a)"
                    style={{
                        mixBlendMode: "multiply",
                    }}
                />
                <path
                    d="M26.88 69.11v-3c.26-5.29 7.94-.3 17.37-.3 9 0 16.45-4.26 17.34.7v2.6c0 5.41-7.78 9.8-17.36 9.8s-17.35-4.39-17.35-9.8Z"
                    fill="#dbdbdb"
                />
                <path
                    d="M26.88 66.11c0-5.42 7.78-9.82 17.37-9.82s17.36 4.4 17.36 9.82-7.78 9.82-17.36 9.82-17.37-4.39-17.37-9.82Z"
                    fill="#1c998b"
                />
                <path
                    d="M44.25 75c8.89 0 16.4-4.06 16.4-8.86s-7.51-8.85-16.4-8.85-16.41 4.06-16.41 8.85S35.35 75 44.25 75Z"
                    fill="#87aeaa"
                />
                <path
                    d="M35.52 65.2c0 2.63 3.81 4.76 8.53 4.76s8.53-2.13 8.53-4.76-3.82-4.75-8.53-4.75-8.53 2.12-8.53 4.75Z"
                    fill="url(#b)"
                    style={{
                        mixBlendMode: "multiply",
                    }}
                />
                <path
                    d="M43.81 67.47 28.55 63.6s1.41-3.07 7-4.93l7.51 3 5.6 4.27"
                    fill="#0e7166"
                    opacity={0.32}
                    style={{
                        mixBlendMode: "multiply",
                    }}
                />
                <path
                    fill="#dbdbdb"
                    d="m43.81 67.47 4.82-1.57-3.06-40.67-1.58.93-.18 41.31z"
                />
                <path
                    fill="#abb8b7"
                    d="m43.81 67.47-4.28-1.09 3.05-41.02 1.41.8-.18 41.31z"
                />
                <path
                    fill="#262626"
                    opacity={0.5}
                    d="m43.99 26.16-1.41-.8-2.14 28.48 3.47 2.04 3.94-.23-2.28-30.42-1.58.93z"
                />
                <path
                    d="M.27 48.32c.31.55 1.37 2.51 1.58 3a2.43 2.43 0 0 0 2.36 1.31h79.27a2.46 2.46 0 0 0 2.35-1.27c.23-.48 1.24-2.44 1.55-2.94S0 47.81.27 48.32Z"
                    fill="#1c998b"
                />
                <path
                    d="M87.56 45.71c.54 2.49-.57 4.56-2.51 4.56H2.64c-1.93 0-3.05-2.07-2.5-4.56l9.51-43A3.49 3.49 0 0 1 12.88 0h61.94a3.49 3.49 0 0 1 3.23 2.68Z"
                    fill="#eeeded"
                />
                <path
                    d="M3.52 46.77a1.24 1.24 0 0 1 0-.31l9.53-42.95h61.57l9.51 43c0 .11 0 .22.05.31Z"
                    fill="#576573"
                />
                <path
                    d="M64.53 33.51h16.73l-.16-.74H64.44l-1.16-10h15.6l-.16-.77H63.19l-1.08-9.33h14.53l-.16-.74H62l-1-8.42h-1.57l.88 8.43h-15.7V3.51h-1.48l-.05 8.43H27.37l.89-8.43h-1.62l-1 8.43H11.19l-.16.74h14.54L24.5 22H9l-.17.74H24.4l-1.16 10H6.59l-.16.74h16.73l-1.54 13.29h2.1l1.39-13.26H43l-.07 13.26h1.87l-.06-13.26h17.84L64 46.77h2.09ZM43 32.77H25.19l1-10H43ZM43 22H26.32l1-9.33h15.76Zm1.59-9.33h15.8l1 9.33H44.66Zm.09 20.09-.05-10h16.82l1 10Z"
                    fill="#eeeded"
                />
                <path
                    d="M27.71 46.77H13.45L56.71 3.51H71Zm53.18-14.92L78.27 20 51.5 46.77H66Zm-48-28.34H13.05L7.42 29Z"
                    fill="#fff"
                    opacity={0.3}
                />
            </g>
        </g>
    </svg>
)

SchemePanel.propTypes = propTypes;
SchemePanel.defaultProps = defaultProps;

export default SchemePanel;
