import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

const UpArrow = props => {
    return (
        <svg
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill={props.color}
                d="M18.221 7.206l9.585 9.585a2.265 2.265 0 010 3.195l-.8.801a2.266 2.266 0 01-3.194 0l-7.315-7.315-7.315 7.315a2.266 2.266 0 01-3.194 0l-.8-.801a2.265 2.265 0 010-3.195l9.587-9.585a2.24 2.24 0 011.723-.647 2.247 2.247 0 011.723.647z"
            />
        </svg>
    );
};

UpArrow.propTypes = propTypes;
UpArrow.defaultProps = defaultProps;

export default UpArrow;
