import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fafafa',
    height: '100%',
    width: '100%',
};

const LightsOff = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height}
            width={props.width}
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M31 2l-2-2-4.174 4.174C23.246 1.934 20.453-.028 16.001 0c-5.355-.035-8.315 2.806-9.645 5.552C4.993 8.293 5.005 10.916 4.999 11c.036 4.424 2.714 7.911 3.82 9.182L1 28l2 2 7.46-7.46c.132.326.244.649.319.951.111.442.167.834.194 1.108.027.273.023.4.027.4V30h2l2 2h2l2-2h2v-5.039c0-.04.002-.143.025-.361.074-.83.442-2.677 1.681-3.894.051-.084 4.249-4.165 4.292-9.707-.003-.071.001-1.951-.814-4.183L31 2zM10.235 18.766l-.091-.106C9.078 17.389 6.982 14.339 7 11c0-.002-.002-.531.142-1.397.214-1.303.768-3.249 2.067-4.799C10.517 3.26 12.499 2.016 16.001 2c4.071.031 6.142 1.695 7.378 3.622L10.235 18.766zm12.911-1.914a16.2 16.2 0 01-1.293 1.808c-.352.424-.562.632-.562.634-1.559 1.594-2.047 3.548-2.206 4.708h-6.171a9.026 9.026 0 00-.947-2.967L24.589 8.411c.116.429.208.838.265 1.192.147.866.139 1.397.145 1.397.004 2.228-.908 4.321-1.853 5.852z"
                fill={props.color}
            />
        </svg>
    );
};

LightsOff.propTypes = propTypes;
LightsOff.defaultProps = defaultProps;

export default LightsOff;
