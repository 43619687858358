import React from 'react';
import { withStyles } from '@mui/styles';
import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import MUIAccordionDetails from '@mui/material/AccordionDetails';

export default function AccordionUser(props) {
    return (
        <AccordionWithStyles defaultExpanded={props.defaultExpanded} square>
            <Summary>{props.header}</Summary>
            <Details>{props.content}</Details>
        </AccordionWithStyles>
    );
}

const AccordionWithStyles = withStyles(theme => ({
    root: {
        borderRadius: 3,
        width: '100%',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 0,
        },
        backgroundColor: theme.palette.background.default,
    },
    expanded: {},
}))(MUIAccordion);

const Summary = withStyles(theme => ({
    root: {
        padding: theme.spacing(0, 2),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        minHeight: 50,
        '&$expanded': {
            minHeight: 56,
        },
        backgroundColor: theme.palette.background.default,
    },
    content: {
        '&$expanded': {},
    },
    expanded: {},
}))(MUIAccordionSummary);

const Details = withStyles(theme => ({
    root: {
        padding: theme.spacing(2, 2),
    },
}))(MUIAccordionDetails);
