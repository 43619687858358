import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

const adapter = createEntityAdapter();
const selectors = adapter.getSelectors(({ messages }) => messages);
const slice = createSlice({
    name: 'messages',
    initialState: adapter.getInitialState(),
    reducers: {
        setAll: adapter.setAll,
    },
});

const selectCurrentMessages = createSelector(
    state => selectors.selectById(state, 'date_debut'),
    message => message,
);

export const messagesActions = slice.actions;
export const messagesReducer = slice.reducer;
export const messagesSelectors = { ...selectors, selectCurrentMessages };
