import Text from '../../../Text';
import React, { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const InclusionWizardLastStep = props => {
    useEffect(() => {
        setTimeout(() => {
            props.setLoading(false);
        }, 60000);
    }, []);

    return (
        <>
            {props.loading ? (
                <>
                    <Text>Appairage en cours ... </Text>
                    <LinearProgress size={120} />
                </>
            ) : (
                <Text sx={{ mt: 2, mb: 1 }}>
                    Votre module est maintenant connecté
                </Text>
            )}
        </>
    );
};

export default InclusionWizardLastStep;
