import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

const YoutubeIcon = props => (
    <svg
        height={props.height}
        width={props.width}
        {...props}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill={props.color}
            d="M224.113 303.96L307.387 256l-83.274-47.96zm0 0"
        />
        <path
            fill={props.color}
            d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm159.96 256.262s0 51.918-6.585 76.953c-3.691 13.703-14.496 24.508-28.2 28.195C356.142 368 256 368 256 368s-99.879 0-125.176-6.852c-13.703-3.687-24.508-14.496-28.199-28.199-6.59-24.77-6.59-76.949-6.59-76.949s0-51.914 6.59-76.95c3.688-13.702 14.758-24.773 28.2-28.46C155.858 144 256 144 256 144s100.14 0 125.176 6.852c13.703 3.687 24.508 14.496 28.199 28.199 6.852 25.035 6.586 77.21 6.586 77.21zm0 0"
        />
    </svg>
);

YoutubeIcon.propTypes = propTypes;
YoutubeIcon.defaultProps = defaultProps;

export default YoutubeIcon;
