import * as React from 'react';

const AngleIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{
            enableBackground: 'new 0 0 512 512',
        }}
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        {...props}
    >
        <path
            fill={props.color}
            d="M495.304 425.738H255.417c-3.576-52.031-23.828-100.842-58.185-140.23L401.371 81.37c6.52-6.52 6.52-17.091 0-23.611-6.519-6.52-17.091-6.52-23.611 0L4.89 430.629a16.69 16.69 0 0 0-3.619 18.194 16.696 16.696 0 0 0 15.425 10.307H495.305c9.22 0 16.696-7.475 16.696-16.696s-7.476-16.696-16.697-16.696zm-438.302 0 116.562-116.561c28.136 32.988 44.927 73.446 48.38 116.561H57.002z"
        />
    </svg>
);

export default AngleIcon;
