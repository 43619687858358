import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Paper from "data/components/Paper";
import List from "data/components/List";
import Grid from "data/components/Grid";
import Product from "./components/Product";
import t from "../../../../utils/Translator";
import ShuttersIcon from "../../../../assets/svgs/ShuttersIcon";
import SwitchesIcon from "../../../../assets/svgs/SwitchesIcon";
import LightingIcon from "../../../../assets/svgs/LightingIcon";
import TemperaturesIcon from "../../../../assets/svgs/TemperaturesIcon";
import SecuritiesIcon from "../../../../assets/svgs/SecuritiesIcon";
import MeasuresIcon from "../../../../assets/svgs/MeasuresIcon";
import Text from "../../Text";
import { Collapse } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "../../Tooltip";
import InelioIcon from "../../../../assets/svgs/InelioIcon";
import { useTheme } from "@mui/material/styles";

const translations = require("../../../../lang/fr.json");

export default function ProductsGroup({ productIds, group }) {
  const [collapsed, setCollapsed] = useState(true);
  const classes = useStyles(collapsed);
  const theme = useTheme();

  const groupEcoTips = Object.keys(translations.ecoTips.groups[group]);

  const randomEcoTip =
    groupEcoTips[Math.floor(Math.random() * groupEcoTips.length)];

  if (!productIds.length) return null;

  const groups = {
    shutters: {
      title: t.get("products.groups.shutters"),
      icon: (
        <ShuttersIcon
          color={theme.palette.primary.main}
          height={60}
          width={80}
        />
      ),
    },
    switches: {
      title: t.get("products.groups.switches"),
      icon: (
        <SwitchesIcon
          color={theme.palette.primary.main}
          height={60}
          width={80}
        />
      ),
    },
    lighting: {
      title: t.get("products.groups.lighting"),
      icon: (
        <LightingIcon
          color={theme.palette.primary.main}
          height={60}
          width={80}
        />
      ),
    },
    temperatures: {
      title: t.get("products.groups.temperatures"),
      icon: (
        <TemperaturesIcon
          color={theme.palette.primary.main}
          height={60}
          width={80}
        />
      ),
    },
    securities: {
      title: t.get("products.groups.securities"),
      icon: (
        <SecuritiesIcon
          color={theme.palette.primary.main}
          height={60}
          width={80}
        />
      ),
    },
    measures: {
      title: t.get("products.groups.measures"),
      icon: (
        <MeasuresIcon
          color={theme.palette.primary.main}
          height={60}
          width={80}
        />
      ),
    },
    inelio: {
      title: t.get("products.groups.inelio"),
      icon: <InelioIcon color={"#00988E"} height={120} width={120} />,
    },
  };

  return (
    <Grid container style={{ justifyContent: "center" }} item xs={6}>
      <Collapse
        orientation={"horizontal"}
        in={collapsed}
        collapsedSize={280}
        sx={
          !collapsed && {
            boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.20)",
          }
        }
      >
        <Paper elevation={5} className={classes.container}>
          <Tooltip title={groups[group]?.title}>
            <Button
              onClick={() => setCollapsed(!collapsed)}
              sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: 280,
                height: 200,
              }}
              component={"div"}
            >
              {groups[group]?.icon || null}
              <Text
                className={classes.text}
                sx={{
                  padding: 2,
                  fontStyle: "italic",
                  fontSize: "small",
                }}
              >
                {t.get(`ecoTips.groups.${group}.${randomEcoTip}`)}
              </Text>
            </Button>
          </Tooltip>

          <List className={classes.list}>
            {productIds.map((id) => (
              <div key={id}>
                <Product id={id} />
              </div>
            ))}
          </List>
        </Paper>
      </Collapse>
    </Grid>
  );
}

const useStyles = makeStyles((theme, collapsed) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: 700,
    alignItems: "center",
  },
  list: {
    padding: 8,
    width: 420,
    alignSelf: "center",
  },
  text: {
    color: theme.palette.text.primary,
    width: 280,
    textAlign: "center",
  },
}));
