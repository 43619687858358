import React, { useEffect, useState } from 'react';
import Portal from '@mui/material/Portal';
import { makeStyles } from '@mui/styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Button from 'data/components/Button';
import Grid from '../Grid';

export default function SidePanel({
    sidePanelIsOpen,
    setSidePanelIsOpen,
    sidePanelContainerRef,
    children,
}) {
    const [initialized, setInitialized] = useState(false);
    const classes = useStyles({ sidePanelIsOpen });
    let screenWidth = window.screen.width;

    const reportWindowSize = () => {
        screenWidth = window.screen.width;
        screenWidth < 1400 && setSidePanelIsOpen(false);
    };

    window.addEventListener('resize', reportWindowSize);

    useEffect(() => {
        setInitialized(true);
        screenWidth < 1400 && setSidePanelIsOpen(false);
    }, []);

    if (!initialized) return null;
    return (
        <Portal container={sidePanelContainerRef.current}>
            <Grid container className={classes.container}>
                <Grid container item className={classes.content} xs={12}>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => setSidePanelIsOpen(!sidePanelIsOpen)}
                        >
                            {sidePanelIsOpen ? (
                                <ChevronRight />
                            ) : (
                                <ChevronLeft />
                            )}
                        </Button>
                    </Grid>
                    <Grid container className={classes.childrenContainer} item xs={12}>
                        <Grid item className={classes.children} xs={12}>
                            {sidePanelIsOpen ? children : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Portal>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        width: 300,
        zIndex: 100,
        position: 'absolute',
        top: 0,
        backgroundColor: theme.palette.background.default,
        transition: 'right 0.3s',
        right: ({ sidePanelIsOpen }) => (sidePanelIsOpen ? 0 : -235),
    },
    content: { backgroundColor: theme.palette.background.default },
    children: {
        width: 300,
        backgroundColor: theme.palette.background.default,
        overflowX: 'hidden',
    },
    childrenContainer: {
        backgroundColor: theme.palette.background.default,
    }
}));
