import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const adapter = createEntityAdapter();
const selectors = adapter.getSelectors(({ box }) => box);

export const boxSlice = createSlice({
    name: 'box',
    initialState: {
        boxStatus: 'connected',
        macAddress: '',
        ipAddress: 0,
    },
    reducers: {
        setBoxStatus: (state, action) => {
            state.boxStatus = action.payload.status;
        },
        setBoxMacAddress: (state, action) => {
            state.macAddress = action.payload;
        },
    },
    extraReducers: {},
});

export const boxActions = boxSlice.actions;

export const boxSelectors = { ...selectors };

export const selectBoxStatus = state => state.box.boxStatus;
export const selectBoxMacAddress = state => state.box.macAddress;

export const boxReducer = boxSlice.reducer;
