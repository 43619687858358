import React from 'react';
import { useSelector } from 'react-redux';
import { weathersSelectors } from 'store/weather/weatherSlice';
import { makeStyles } from '@mui/styles';
import Grid from 'data/components/Grid';
import Weather from './components/Weather';

const Weathers = () => {
    const classes = useStyles();
    const weathers = useSelector(weathersSelectors.selectAll);

    if (!weathers) return null;
    return (
        <Grid
            container
            spacing={6}
            className={classes.container}
            justifyContent="center"
        >
            {weathers.map((weather, index) => (
                <Weather index={index} weather={weather} key={index} />
            ))}
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(4),
    },
}));

export default Weathers;
