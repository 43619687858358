import React from 'react';
import InputLinkProbe from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkProbe';
import InputLinkWire from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkWire';
import InputLinkClamp from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkClamp';
import {
    hasInputLinkClamp,
    hasInputLinkModule,
    hasInputLinkProbe,
    hasInputLinkRelay,
    hasInputLinkWire,
} from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/utils/inputsPredicates';
import InputLinkModule from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkModule';
import InputLinkRelay from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkRelay';

const inputs = [
    { predicate: hasInputLinkProbe, Input: InputLinkProbe },
    { predicate: hasInputLinkWire, Input: InputLinkWire },
    { predicate: hasInputLinkRelay, Input: InputLinkRelay },
    { predicate: hasInputLinkClamp, Input: InputLinkClamp },
    { predicate: hasInputLinkModule, Input: InputLinkModule },
];

export default function LinkInputs({ product, formik }) {
    return (
        <>
            {inputs.map(
                ({ predicate, Input }, i) =>
                    predicate(product) && (
                        <Input key={i} formik={formik} product={product} />
                    ),
            )}
        </>
    );
}
