import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

const SchemeLeftArrow = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65.79 33.23"
        {...props}
    >
        <g data-name="Calque 2">
            <g data-name="Calque 1" fill={props.color}>
                <path d="M10.18 8.82a37.87 37.87 0 0 1 8.5-5.62A36.43 36.43 0 0 1 28.29.32a37 37 0 0 1 9.86 0 36.53 36.53 0 0 1 9.28 2.82 35.51 35.51 0 0 1 7.93 5.32c.58.54 1.12 1.14 1.64 1.69l.82.85.76.9a29.92 29.92 0 0 1 2.7 3.82 31.17 31.17 0 0 1 4.45 17.51A30.12 30.12 0 0 0 59.47 17a29.26 29.26 0 0 0-2.91-3.18l-.79-.73-.84-.67c-.56-.44-1.1-.9-1.68-1.31a31.63 31.63 0 0 0-7.5-3.78 31.51 31.51 0 0 0-8.12-1.4 30.29 30.29 0 0 0-8 1 28.8 28.8 0 0 0-7.23 3.2 29.67 29.67 0 0 0-5.88 5.05Z" />
                <path d="m0 25.33 21.04-5.64-15.4-15.4L0 25.33z" />
            </g>
        </g>
    </svg>
);

SchemeLeftArrow.propTypes = propTypes;
SchemeLeftArrow.defaultProps = defaultProps;

export default SchemeLeftArrow;
