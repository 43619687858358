import Menu from 'data/components/Menu';
import t from 'utils/Translator';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth/authSlice';
import MiddlewareSocket from '../../../../MiddlewareSocket';
import { removeTokenFromStorages } from '../../../../utils';

export default function NavMenu() {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <Menu
            label="Icon"
            items={[
                {
                    label: t.get('tabs.account'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/account');
                    },
                },
                {
                    label: t.get('tabs.support'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/support');
                    },
                },
                {
                    label: t.get('tabs.logout'),
                    onClick: () => {
                        MiddlewareSocket.destroy();
                        removeTokenFromStorages('token');
                        dispatch(logout());
                        history.push('/login');
                    },
                },
            ]}
        />
    );
}
