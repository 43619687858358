import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from 'utils/Http';
import {
    setProductConversionRate,
    setProductDeltaT,
    setProductLabel,
    setProductOperatingMode,
    setProductPhase,
    setProductRemote,
    updateDS18B20Group,
} from 'MiddlewareSocket/emitters/details';
import {
    getModuleCurrentSlaveIds,
    getForm,
    getMasterIdFromKey,
    getSlaveIdsFromKeys,
    getClampCurrentSlaveIds,
    getProbeCurrentSlaveIds,
} from 'store/products/utils';
import {
    hasInputConversionRate,
    hasInputDelta,
} from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/utils/inputsPredicates';

export const updateProductAPI = createAsyncThunk(
    'products/updateProductAPI',
    async payload => {
        const form = getForm(payload);

        const { success, product_module } = await Http.put(
            'product-modules/update',
            form,
        );

        if (!success) throw new Error('API error');
        return { product_module };
    },
);

export const updateProductEvent = createAsyncThunk(
    'products/updateProductEvent',
    async payload => {
        const { product } = payload;

        const form = getForm(payload);
        const changes = {};

        try {
            if (form.product_module.label) {
                await setProductLabel(product, form.product_module);
                changes.label = form.product_module.label;
            }
            if (form.product_module.remote_ref) {
                await setProductRemote(product, form.product_module);
                changes.remote_ref = form.product_module.remote_ref;
            }
            if (form.product_module.operating_mode) {
                await setProductOperatingMode(product, form.product_module);
                changes.operating_mode = form.product_module.operating_mode;
            }
            if (form.product_module.threshold) {
                if (hasInputDelta(product)) {
                    await setProductDeltaT(product, form.product_module);
                } else if (hasInputConversionRate(product)) {
                    await setProductConversionRate(
                        product,
                        form.product_module,
                    );
                }
                changes.threshold = form.product_module.threshold;
            }
            if (form.product_module.fhe_product_mode) {
                await setProductPhase(product, form.product_module);
                changes.fhe_product_mode = form.product_module.fhe_product_mode;
            }
        } catch (e) {
            throw new Error('Middleware error', e);
        }

        return { product, changes };
    },
);

export const clampsLinkAdd = createAsyncThunk(
    'products/clampsLinkAdd',
    async (payload, { getState }) => {
        const {
            products: { entities },
        } = getState();
        const { master, slaveKeys } = payload;
        const slaves_ids = getSlaveIdsFromKeys(entities, slaveKeys);
        const currentSlaveIds = getClampCurrentSlaveIds(entities, master);
        let hasRemoved = false;
        let hasAdded = false;

        if (master.is_master_clamp && currentSlaveIds.length > 0) {
            const {
                success,
            } = await Http.post(
                `product-modules/${master.id}/groups/clamp/remove`,
                { slaves_ids: currentSlaveIds },
            );
            hasRemoved = success;
        }

        if (slaveKeys.length > 0) {
            const { success } = await Http.post(
                `product-modules/${master.id}/groups/clamp/add`,
                {
                    slaves_ids,
                },
            );
            hasAdded = success;
        }

        if (
            (slaveKeys.length > 0 && !hasAdded) ||
            (master.is_master_clamp && !hasRemoved)
        )
            throw new Error('API error');
        return { master, slaveKeys };
    },
);

export const clampsLinkRemove = createAsyncThunk(
    'products/clampsLinkRemove',
    async (payload, { getState }) => {
        const {
            products: { entities },
        } = getState();
        const { product } = payload;
        const masterId = getMasterIdFromKey(entities, product.clamp_master);
        const { success } = await Http.post(
            `product-modules/${masterId}/groups/clamp/remove`,
            {
                slaves_ids: [product.id],
            },
        );

        if (!success) throw new Error('API error');
        return { product };
    },
);

export const modulesLinkAdd = createAsyncThunk(
    'products/modulesLinkAdd',
    async (payload, { getState }) => {
        const {
            products: { entities },
        } = getState();
        const { master, slaveKeys } = payload;
        const slaves_ids = getSlaveIdsFromKeys(entities, slaveKeys);
        const currentSlaveIds = getModuleCurrentSlaveIds(entities, master);
        let hasRemoved = false;
        let hasAdded = false;

        if (master.is_master_from_master_slave && currentSlaveIds.length > 0) {
            const {
                success,
            } = await Http.post(
                `product-modules/${master.id}/groups/master_slave/remove`,
                { slaves_ids: currentSlaveIds },
            );
            hasRemoved = success;
        }

        if (slaveKeys.length > 0) {
            const { success } = await Http.post(
                `product-modules/${master.id}/groups/master_slave/add`,
                {
                    slaves_ids,
                },
            );
            hasAdded = success;
        }

        if (
            (slaveKeys.length > 0 && !hasAdded) ||
            (master.is_master_from_master_slave && !hasRemoved)
        )
            throw new Error('API error');
        return { master, slaveKeys };
    },
);

export const modulesLinkRemove = createAsyncThunk(
    'products/modulesLinkRemove',
    async (payload, { getState }) => {
        const {
            products: { entities },
        } = getState();
        const { product } = payload;
        const masterId = getMasterIdFromKey(
            entities,
            product.master_from_master_slave,
        );
        const { success } = await Http.post(
            `product-modules/${masterId}/groups/master_slave/remove`,
            {
                slaves_ids: [product.id],
            },
        );

        if (!success) throw new Error('API error');
        return { product };
    },
);

export const probesLinkAdd = createAsyncThunk(
    'products/probesLinkAdd',
    async (payload, { getState }) => {
        const {
            products: { entities },
        } = getState();
        const { master, slaveKeys } = payload;
        const slaves_ids = getSlaveIdsFromKeys(entities, slaveKeys);
        const currentSlaveIds = getProbeCurrentSlaveIds(entities, master);
        let hasRemoved = false;
        let hasAdded = false;

        if (master.is_master_thermostatic && currentSlaveIds.length > 0) {
            const {
                success,
            } = await Http.post(
                `product-modules/${master.id}/groups/thermostatic/remove`,
                { slaves_ids: currentSlaveIds },
            );
            hasRemoved = success;
        }

        if (slaveKeys.length > 0) {
            const { success } = await Http.post(
                `product-modules/${master.id}/groups/thermostatic/add`,
                {
                    slaves_ids,
                },
            );
            hasAdded = success;
            await updateDS18B20Group(master);
        }

        if (
            (slaveKeys.length > 0 && !hasAdded) ||
            (master.is_master_clamp && !hasRemoved)
        )
            throw new Error('API error');
        return { master, slaveKeys };
    },
);

export const probesLinkRemove = createAsyncThunk(
    'products/probesLinkRemove',
    async (payload, { getState }) => {
        const {
            products: { entities },
        } = getState();
        const { product } = payload;
        const master = entities[product.thermostatic_master];
        const { success } = await Http.post(
            `product-modules/${master.id}/groups/thermostatic/remove`,
            {
                slaves_ids: [product.id],
            },
        );

        if (!success) throw new Error('API error');
        await updateDS18B20Group(master);
        return { product };
    },
);
