import React from 'react';
import InputRemote from './inputs/InputRemote';
import InputOperatingMode from './inputs/InputOperatingMode';
import InputDelta from './inputs/InputDelta';
import InputPhase from './inputs/InputPhase';
import InputReverse from './inputs/InputReverse';
import {
    hasInputCalibrationThreshold,
    hasInputDelta,
    hasInputOperatingMode,
    hasInputPhase,
    hasInputRemote,
    hasInputReverse,
} from '../utils/inputsPredicates';
import SubmitButton from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/SubmitButton';
import FormDivider from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/FormDivider';
import { updateProductEvent } from 'store/products/thunks';
import { useDispatch } from 'react-redux';
import InputCalibrationThreshold from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputCalibrationThreshold';
import { useFormik } from 'formik';
import _ from 'lodash';

const inputs = [
    { predicate: hasInputRemote, Input: InputRemote },
    { predicate: hasInputOperatingMode, Input: InputOperatingMode },
    { predicate: hasInputDelta, Input: InputDelta },
    { predicate: hasInputReverse, Input: InputReverse },
    { predicate: hasInputPhase, Input: InputPhase },
    {
        predicate: hasInputCalibrationThreshold,
        Input: InputCalibrationThreshold,
    },
];

export default function SecondaryInputs({ product }) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            remote_ref: product.remote_ref || '',
            operating_mode: product.operating_mode || '',
            threshold: product.threshold || '',
            reverse_polarity: product.reverse_polarity || false,
            fhe_product_mode: product.fhe_product_mode || '',
            remote: {},
        },
        // validationSchema: validationSchema,
        onSubmit: () => {},
    });
    const handleSubmit = async () => {
        await dispatch(updateProductEvent({ product, formik }));
    };

    return (
        <>
            {inputs.some(({ predicate }) => predicate(product)) && (
                <FormDivider />
            )}
            {inputs.map(
                ({ predicate, Input }, i) =>
                    predicate(product) && <Input key={i} formik={formik} />,
            )}
            {inputs.some(({ predicate }) => predicate(product)) && (
                <>
                    <SubmitButton
                        disabled={_.isEqual(
                            formik.values,
                            formik.initialValues,
                        )}
                        handleSubmit={handleSubmit}
                    />
                </>
            )}
        </>
    );
}
