// export const API_URL = 'http://api.192.168.1.108.sslip.io/fheconnect/v1.1/';

export const MIDDLEWARE_CONFIGURATION = {
    options: {},
    timeout: 15000,
};

const configs = {
    fheconnect: {
        env: 'fheconnect',
        color: '#00988E',
        API_URL: 'https://apiv2.solidom.net/fheconnect/v1.1/',
        // API_URL: 'http://api.192.168.1.108.sslip.io/fheconnect/v1.1/',
        API_RESOURCES_URL: 'https://ressources.solidom.net/fheconnect/v1.0/',
        GOOGLE_CLIENT_ID:
            '400696028447-56hvs8qetqjn8jef7a3s38cvisonc3pq.apps.googleusercontent.com',
        FACEBOOK_CLIENT_ID: '550403632112172',
    },
};

export const config = configs.fheconnect;
