import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

const MinusIcon = props => {
    return (
        <svg
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 330 330"
            {...props}
        >
            <path
                fill={props.color}
                d="M281.633 48.328C250.469 17.163 209.034 0 164.961 0 120.888 0 79.453 17.163 48.289 48.328c-64.333 64.334-64.333 169.011 0 233.345C79.453 312.837 120.888 330 164.962 330c44.073 0 85.507-17.163 116.671-48.328 31.165-31.164 48.328-72.599 48.328-116.672s-17.163-85.508-48.328-116.672zM260.42 260.46C234.922 285.957 201.021 300 164.962 300c-36.06 0-69.961-14.043-95.46-39.54-52.636-52.637-52.636-138.282 0-190.919C95 44.042 128.901 30 164.961 30s69.961 14.042 95.459 39.54c25.498 25.499 39.541 59.4 39.541 95.46s-14.043 69.961-39.541 95.46z"
            />
            <path
                fill={props.color}
                d="M254.961 150H74.962c-8.284 0-15 6.716-15 15s6.716 15 15 15h179.999c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
            />
        </svg>
    );
};

MinusIcon.propTypes = propTypes;
MinusIcon.defaultProps = defaultProps;

export default MinusIcon;
