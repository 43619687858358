import React from 'react';
import t from 'utils/Translator';
import Text from '../Text';
import CollectedInfosIcon from '../../../assets/svgs/CollectedInfosIcon';
import { makeStyles } from '@mui/styles';
import LogoFHE from '../../../assets/svgs/LogoFHE';
import FinaliteIcon from '../../../assets/svgs/FinaliteIcon';
import ConservationIcon from '../../../assets/svgs/ConservationIcon';
import TransmissionIcon from '../../../assets/svgs/TransmissionIcon';
import AccessIcon from '../../../assets/svgs/AccessIcon';
import ModificationIcon from '../../../assets/svgs/ModificationIcon';
import OppositionIcon from '../../../assets/svgs/OppositionIcon';
import MailIcon from '../../../assets/svgs/MailIcon';
import AddressIcon from '../../../assets/svgs/AddressIcon';
import InfosIcon from '../../../assets/svgs/InfosIcon';
import AngelottiSmallLogo from "../../../assets/svgs/AngelottiSmallLogo";

const ModalRGPD = props => {
    const classes = useStyles();

    return (
        <div style={classes.container}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AngelottiSmallLogo width={40} height={40} />
                <h1 style={{ marginLeft: 14 }}>{t.get('rgpd-modal.title')}</h1>
            </div>
            <div>
                <div
                    style={{
                        backgroundColor: '#d62e1d',
                        padding: 14,
                        borderRadius: 45,
                    }}
                >
                    <div
                        style={{
                            margin: 14,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <CollectedInfosIcon
                            color={'#fff'}
                            width={25}
                            height={25}
                        />
                        <div style={{ marginLeft: 14, color: '#fff' }}>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph1.1')}{' '}
                            </Text>
                            <Text
                                style={{
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph1.2')}
                            </Text>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: 14,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <FinaliteIcon color={'#fff'} width={30} height={30} />
                        <div style={{ marginLeft: 14, color: '#fff' }}>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph2.1')}
                            </Text>
                            <Text
                                style={{
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph2.2')}
                            </Text>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: 14,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <ConservationIcon
                            color={'#fff'}
                            width={30}
                            height={30}
                        />
                        <div style={{ marginLeft: 14, color: '#fff' }}>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph3.1')}{' '}
                            </Text>
                            <Text
                                style={{
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph3.2')}
                            </Text>
                            <Text
                                style={{
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph3.3')}
                            </Text>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: 14,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <TransmissionIcon
                            color={'#fff'}
                            width={40}
                            height={40}
                        />
                        <div style={{ marginLeft: 14, color: '#fff' }}>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph4.1')}
                            </Text>
                            <Text
                                style={{
                                    display: 'inline',
                                }}
                            >
                                {t.get('rgpd-modal.body.paragraph4.2')}
                            </Text>
                        </div>
                    </div>
                </div>

                <div style={{ padding: 14 }}>
                    <Text
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {t.get('rgpd-modal.body.title1').toUpperCase()}
                    </Text>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <AccessIcon
                                color={'black'}
                                height={30}
                                width={30}
                            />
                            <Text style={{ display: 'inline', marginLeft: 14 }}>
                                {t.get('rgpd-modal.body.paragraph5')}
                            </Text>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <ModificationIcon
                                color={'black'}
                                height={30}
                                width={30}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    marginLeft: 14,
                                }}
                            >
                                <Text style={{ display: 'inline' }}>
                                    {t.get('rgpd-modal.body.paragraph6.1')}
                                </Text>
                                <Text style={{ display: 'inline' }}>
                                    {t.get('rgpd-modal.body.paragraph6.2')}
                                </Text>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <OppositionIcon
                                color={'black'}
                                height={30}
                                width={30}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    marginLeft: 14,
                                }}
                            >
                                <Text style={{ display: 'inline' }}>
                                    {t.get('rgpd-modal.body.paragraph7.1')}
                                </Text>
                                <Text style={{ display: 'inline' }}>
                                    {t.get('rgpd-modal.body.paragraph7.2')}
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: '#ccc',
                        padding: 14,
                        borderRadius: 45,
                    }}
                >
                    <div>
                        <Text
                            style={{
                                fontWeight: 'bold',
                                marginLeft: 14,
                                marginBottom: 14,
                            }}
                        >
                            {t.get('rgpd-modal.body.title2').toUpperCase()}{' '}
                        </Text>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                width: '30%',
                            }}
                        >
                            <MailIcon color={'black'} width={30} height={30} />

                            <Text>{t.get('rgpd-modal.body.paragraph11')}</Text>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                width: '30%',
                            }}
                        >
                            <AddressIcon
                                color={'black'}
                                width={35}
                                height={35}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {t.get('rgpd-modal.body.paragraph12.1')}
                                </Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {t.get('rgpd-modal.body.paragraph12.2')}
                                </Text>
                            </div>
                        </div>
                    </div>

                    <Text style={{ marginLeft: 14, marginTop: 14 }}>
                        {t.get('rgpd-modal.body.paragraph10')}
                    </Text>
                </div>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        margin: 14,
                    }}
                >
                    <InfosIcon color={'black'} width={50} height={50} />
                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            width: '85%',
                        }}
                    >
                        <div style={{ marginBottom: 14 }}>
                            <Text style={{ display: 'inline' }}>
                                {t.get('rgpd-modal.body.paragraph13.1')}{' '}
                            </Text>
                            <Text style={{ display: 'inline' }}>
                                <a
                                    href={t.get(
                                        'rgpd-modal.body.paragraph13.link',
                                    )}
                                    style={{
                                        color: '#d62e1d',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {t.get('rgpd-modal.body.paragraph13.2')}
                                </a>
                            </Text>
                        </div>
                        <div>
                            <Text style={{ display: 'inline' }}>
                                {t.get('rgpd-modal.body.paragraph14.1')}{' '}
                            </Text>
                            <Text style={{ display: 'inline' }}>
                                <a
                                    href={t.get(
                                        'rgpd-modal.body.paragraph14.link',
                                    )}
                                    style={{
                                        color: '#d62e1d',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {t.get('rgpd-modal.body.paragraph14.2')}
                                </a>
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = () =>
    makeStyles(theme => ({
        container: {},
    }));

export default ModalRGPD;
