import { useContext, useEffect } from 'react';
import useSetState from './useSetState';
import { SnackbarContext } from '../../data/components/SnackbarProvider';

export const useAsync = (asyncFunction, immediate = false) => {
    const { setIsOpen } = useContext(SnackbarContext);
    const [state, setState] = useSetState({
        isLoading: false,
        value: false,
        error: false,
    });
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = (...args) => {
        setState({
            isLoading: true,
            value: false,
            error: false,
        });
        return asyncFunction(...args)
            .then(response => {
                setState({ value: response, isLoading: false });
            })
            .catch(error => {
                setIsOpen(true);
                setState({ error: true, isLoading: false });
            });
    };
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);
    return { execute, ...state };
};
