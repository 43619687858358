import React, { useState } from 'react';
import ProductIcon from '../ProductIcon';
import {
    getItemsPos,
    ScrollMenu,
    VisibilityContext,
} from 'react-horizontal-scrolling-menu';
import LeftArrow from '../../../assets/svgs/LeftArrow';
import RightArrow from '../../../assets/svgs/RightArrow';
import './hideScrollbar.css';

function SliderLeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(
        VisibilityContext,
    );

    return (
        <div
            onClick={() => scrollPrev()}
            style={{ marginRight: 8, alignSelf: 'center' }}
        >
            {!isFirstItemVisible && (
                <LeftArrow color={'black'} height={40} width={40} />
            )}
        </div>
    );
}

function SliderRightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(
        VisibilityContext,
    );

    return (
        <div
            onClick={() => scrollNext()}
            style={{ marginLeft: 8, alignSelf: 'center' }}
        >
            {!isLastItemVisible && (
                <RightArrow color={'black'} height={40} width={40} />
            )}
        </div>
    );
}

function Card({ onClick, selected, title, itemId, product }) {
    const visibility = React.useContext(VisibilityContext);

    return (
        <div
            onClick={() => onClick(visibility)}
            style={{
                width: '160px',
                opacity: selected ? 1 : 0.5,
                cursor: 'pointer',
            }}
            tabIndex={0}
        >
            <div className="card">
                <ProductIcon product={product} />
            </div>
            <div
                style={{
                    height: '200px',
                }}
            />
        </div>
    );
}

const ModulesSlider = props => {
    const classes = useStyles();
    const [selected, setSelected] = useState(props.selectedItem || 0);

    const isItemSelected = id => selected?.id === id;

    const handleClick = product => () => {
        selected.id === product.id ? setSelected({}) : setSelected(product);

        props.setSelectedItem(product);
    };

    const centerOnInit = ({ getItemById, scrollToItem }) => {
        const { center: centerItemKey } = getItemsPos([selected.id]);
        scrollToItem(getItemById(centerItemKey), 'auto', 'center');
    };

    return (
        <div className={classes.container}>
            <ScrollMenu
                LeftArrow={SliderLeftArrow}
                RightArrow={SliderRightArrow}
                onInit={selected.id !== 0 && centerOnInit}
            >
                {props.products.map(product => (
                    <Card
                        itemId={product.id}
                        title={product.label}
                        key={product.id}
                        onClick={handleClick(product)}
                        selected={isItemSelected(product.id)}
                        product={product}
                    />
                ))}
            </ScrollMenu>
        </div>
    );
};

const useStyles = theme => ({
    container: {
        width: '100%',
        height: '100%',
    },
});

export default ModulesSlider;
