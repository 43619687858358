import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import EmailIcon from "../../../assets/svgs/Email";
import ClockIcon from "../../../assets/svgs/Clock";
import PhoneIcon from "../../../assets/svgs/Phone";
import LinkedinIcon from "../../../assets/svgs/Linkedin";
import YoutubeIcon from "../../../assets/svgs/Youtube";
import TwitterIcon from "../../../assets/svgs/Twitter";
import FacebookIcon from "../../../assets/svgs/Facebook";
import backgroundImage from "../../../assets/callCenter.jpg";
import Text from "../../../data/components/Text";
import { useTheme } from "@mui/material/styles";

export default function Support() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <div className={classes.infosCards}>
        <Card className={classes.infosCard}>
          <div className={classes.title}>Contactez nous</div>
          <div className={classes.infos}>
            <div className={classes.text}>
              <PhoneIcon
                width={20}
                height={20}
                color={theme.palette.primary.main}
              />
              <Text style={{ marginLeft: 12 }}>
                09 82 99 20 00 (coût d'un appel local)
              </Text>
            </div>
            <div className={classes.text}>
              <ClockIcon
                width={20}
                height={20}
                color={theme.palette.primary.main}
              />
              <Text style={{ marginLeft: 12 }}>
                Lundi au Vendredi de 9H - 12H / 14H - 18H
              </Text>
            </div>
            <div className={classes.text}>
              <EmailIcon
                width={20}
                height={20}
                color={theme.palette.primary.main}
              />
              <Text style={{ marginLeft: 12 }}>support@fullhomeenergy.fr</Text>
            </div>
          </div>
        </Card>
        <Card className={classes.infosCard}>
          <div className={classes.title}>Suivez nous sur :</div>
          <div className={classes.icons}>
            <a
              href="https://www.facebook.com/FHE.France/"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon
                width={50}
                height={50}
                color={theme.palette.primary.main}
              />
            </a>
            <a
              href="https://twitter.com/FHE_France"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon
                width={50}
                height={50}
                color={theme.palette.primary.main}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCevsyFrMSyHlnhmV3nk3zsw"
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeIcon
                width={50}
                height={50}
                color={theme.palette.primary.main}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/fhe-group/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon
                width={50}
                height={50}
                color={theme.palette.primary.main}
              />
            </a>
          </div>
        </Card>
      </div>
      <div className={classes.banner} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 10,
    margin: 24,
    justifyContent: "center",
  },
  infosCards: {
    display: "flex",
    width: "80%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    alignSelf: "center",
    margin: 24,
  },
  infosCard: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    height: 200,
    boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.20)",
  },
  title: {
    display: "flex",
    color: theme.palette.text.primary,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
  },
  banner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 800,
    height: 300,
    marginTop: 20,
    alignSelf: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.20)",
  },
  icons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
  },
  infos: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    margin: 10,
    height: "100%",
  },
  text: {
    fontSize: 16,
    color: theme.palette.text.primary,
    display: "flex",
  },
}));
