import { makeStyles } from '@mui/styles';
import React from 'react';
import LeafIcon from 'assets/svgs/Leaf';
import Text from 'data/components/Text';
import Grid from 'data/components/Grid';
import { useHistory } from 'react-router-dom';
import { withTooltip } from 'utils';
import SchemePanel from '../../../../assets/svgs/SchemePanel';
import SchemeDataLabel from '../../../../assets/svgs/SchemeDataLabel';
import Tooltip from '../../../../data/components/Tooltip';
import SchemeRightArrow from '../../../../assets/svgs/SchemeRightArrow';
import SchemeHouse from '../../../../assets/svgs/SchemeHouse';
import SchemeLeftArrow from '../../../../assets/svgs/SchemeLeftArrow';
import SchemeTower from '../../../../assets/svgs/SchemeTower';
import Box from '@mui/material/Box';
import SvgScheme from '../../../../data/components/Scheme/svgScheme';

const ConsumptionContent = props => {
    const classes = useStyles();
    const history = useHistory();
    const co2 = Math.round(props.homeNetworkStats.prodPV * 0.18 * 100) / 100;

    return (
        <Grid
            container
            className={classes.container}
            onClick={() => history.push('/stats')}
            spacing={2}
        >
            <Grid
                item
                xs={6}
                md={6}
                container
                direction="column"
                alignItems="center"
            >
                {withTooltip(
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 24,
                        }}
                    >
                        <LeafIcon
                            className={classes.icon}
                            color={'#4CAF50'}
                            height={90}
                            width={90}
                        />
                        <Text
                            color="secondary"
                            variant="body2"
                            className={classes.text}
                        >
                            CO₂: {co2} kg
                        </Text>
                    </div>,
                    'CO₂ économisé',
                )}
            </Grid>
            <div className={classes.schemeContainer}>
                <SvgScheme
                    homeNetworkStats={props.homeNetworkStats}
                    width={150}
                    height={100}
                    arrowWidth={50}
                />
            </div>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        color: theme.palette.common.white,
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    co2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 200,
        width: 200,
    },
    icon: {
        alignSelf: 'center',
        marginBottom: 24,
    },
    text: {
        fontSize: 26,
        fontWeight: 800,
    },
    schemeContainer: {
        display: 'flex',
        width: '50%',
        justifyContent: 'center',
    },
    scheme: {
        display: 'flex',
        alignSelf: 'center',
    },
    schemeLayer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        height: 250,
        justifyContent: 'space-evenly',
        marginLeft: 4,
        marginRight: 4,
    },
    arrowLayer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
}));

export default ConsumptionContent;
