import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fafafa',
    height: '100%',
    width: '100%',
};

const OpenedRollingShutter = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={props.height}
        width={props.width}
        viewBox="0 0 300 211.23"
        {...props}
    >
        <g fill={props.color}>
            <path d="M10.47 65v108.78a1.94 1.94 0 001.95 1.94h17.86a1.94 1.94 0 001.95-1.94V65h8.89a2 2 0 001.58-3L23 34.35a2 2 0 00-3.17 0L.36 62A1.94 1.94 0 002 65z" />
            <rect x={48.78} width={239.63} height={29.62} rx={2.35} />
            <rect x={57.52} y={38.36} width={222.16} height={11.32} rx={2.35} />
            <rect x={57.52} y={58.33} width={222.16} height={11.32} rx={2.35} />
            <rect x={57.52} y={78.3} width={222.16} height={11.32} rx={2.35} />
            <rect x={57.52} y={98.27} width={222.16} height={11.32} rx={2.35} />
            <rect
                x={57.52}
                y={118.24}
                width={222.16}
                height={11.32}
                rx={2.35}
            />
            <rect
                x={57.52}
                y={138.21}
                width={222.16}
                height={11.32}
                rx={2.35}
            />
            <path d="M286.06 195.45h-15.21V158.2H66.32v37.25H51.13a2.35 2.35 0 00-2.35 2.35v11.08a2.35 2.35 0 002.35 2.35h234.93a2.35 2.35 0 002.35-2.35V197.8a2.35 2.35 0 00-2.35-2.35zM291.43 6.49v17.13a8.57 8.57 0 100-17.13zM37 15.05a8.57 8.57 0 008.57 8.57V6.49A8.57 8.57 0 0037 15.05zM296.21 40.72c0-1.3-1.79-2.35-4-2.35s-4 1.05-4 2.35v62.12c0 1.3 1.79 2.35 4 2.35s4-1 4-2.35zM292.2 108.73c-2.22 0-4 1.06-4 2.35v4.48c0 1.3 1.79 2.35 4 2.35s4-1 4-2.35v-4.48c.01-1.29-1.78-2.35-4-2.35z" />
        </g>
    </svg>
);

OpenedRollingShutter.propTypes = propTypes;
OpenedRollingShutter.defaultProps = defaultProps;

export default OpenedRollingShutter;
