import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

const SchemeRightArrow = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65.79 33.23"
        {...props}
    >
        <g data-name="Calque 2">
            <g data-name="Calque 1" fill={props.color}>
                <path d="M55.62 24.4a37.75 37.75 0 0 1-8.5 5.6 36.28 36.28 0 0 1-9.61 2.88 36.17 36.17 0 0 1-9.86 0 36.48 36.48 0 0 1-9.29-2.82 35.7 35.7 0 0 1-7.93-5.31c-.57-.54-1.11-1.12-1.67-1.68l-.82-.84-.76-.9a32.54 32.54 0 0 1-2.7-3.82A31.27 31.27 0 0 1 0 0a30 30 0 0 0 6.3 16.24 28.38 28.38 0 0 0 2.9 3.19l.79.73.84.67c.57.43 1.11.89 1.68 1.31A31.82 31.82 0 0 0 20 25.92a31.07 31.07 0 0 0 8.12 1.39 30.7 30.7 0 0 0 8-1 29.17 29.17 0 0 0 7.23-3.2 29.71 29.71 0 0 0 5.88-5.05Z" />
                <path d="m65.79 7.9-21.04 5.64 15.41 15.4L65.79 7.9z" />
            </g>
        </g>
    </svg>
);

SchemeRightArrow.propTypes = propTypes;
SchemeRightArrow.defaultProps = defaultProps;

export default SchemeRightArrow;
