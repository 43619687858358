import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const TransmissionIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 190.01">
            <g fill={props.color}>
                <path d="M46.06,4.52C32.1,29,.25,86.12,0,87.11s1.41,2.23,11,8.16c6.8,4.1,11.79,6.8,12.5,6.68.87-.12,6.92-10.38,25.45-43.29C62.31,35,73.16,15.13,73.1,14.72,72.93,13.78,50.75,0,49.46,0,49.05,0,47.53,2.05,46.06,4.52Z" />
                <path d="M238.35,7c-8.74,5.4-11.15,7.16-11.09,8.1.06,1.58,47.75,86.34,48.92,87S299.47,89.4,300,87.81,252,.35,250.55.18C250,.12,244.51,3.17,238.35,7Z" />
                <path d="M147,24.11c-9.15.76-18.53,2.29-23.4,3.81-3.64,1.18-4.87,1.88-6.51,3.76C113.69,35.61,100,55.08,96.1,61.42c-3.47,5.63-3.7,6.27-3.76,9.68s0,3.69,1.65,4.45c2.11,1.06,8.91.18,13.78-1.81,7-2.82,15.9-10.27,20.59-17.31l2-3L137.69,51a66.66,66.66,0,0,1,8.44-2.34c2.06,0,26.75,13.31,40.3,21.76,13.14,8.16,37.07,25.23,50.68,36.19l6.92,5.64,2.47-1.35c3.87-2.23,7.92-5.52,13.2-10.8l4.92-5L246,62.12c-10.21-18.18-18.77-33.26-19-33.55s-2.64.17-5.34.82c-6.63,1.7-12.09,1.29-25.23-1.7-15.19-3.52-21.11-4.23-33.2-4.17C157.4,23.58,150.12,23.82,147,24.11Z" />
                <path d="M53.74,62.83,34.56,96.91l3,3.57c1.71,2,4.34,5.29,5.93,7.28a35.78,35.78,0,0,0,3.05,3.69,22.1,22.1,0,0,0,2.81-2c7.86-6,19.3-2.7,24.23,6.92L75,119.19l2.64-3.57c3.4-4.64,6.86-6.52,12.14-6.52a17.22,17.22,0,0,1,17.07,17.13l0,4h2.17c3.35,0,9.15,3.29,11.74,6.75s4.22,8.15,3.87,11.44l-.18,2.23,2.64.41A18.38,18.38,0,0,1,141,160.9a15.63,15.63,0,0,1-.7,16.31c-1.11,1.7-1.82,3.22-1.58,3.46a24,24,0,0,0,4.28,1.7c13.9,4.46,25.34,5.46,30.91,2.7,3-1.53,8.63-6.86,8.27-7.92-.11-.35-6.74-3.75-14.84-7.68s-14.84-7.4-15.07-7.81a3.08,3.08,0,0,1,1-4.22c1.41-.94,1.82-.76,17.43,6.8,16.89,8.22,18.83,8.86,24.81,8a9.42,9.42,0,0,0,7.63-7c1.64-5.51,2.81-4.46-17.72-16.31-10.26-5.92-19.12-11.26-19.65-11.85a3.23,3.23,0,0,1-1.06-1.87c0-1.35,2.35-3.35,3.52-3.05.59.18,9.8,5.34,20.48,11.5,19.18,11.08,19.36,11.2,22.82,11.2,4.4,0,6.92-.88,9.38-3.17,2.29-2.11,3.23-4.86,2.93-8.74l-.17-2.87-22.29-13.43c-12.26-7.4-22.65-14-23.11-14.61a2.28,2.28,0,0,1-.18-2.64c1.35-2.93,2.88-2.23,28.1,13l23.93,14.37,4.4-.18c3.46-.11,4.81-.46,6.34-1.52a10.65,10.65,0,0,0,2.58-14.14c-1.94-2.87-9-8.74-24.47-20.29-25-18.72-43.22-30.28-67.63-42.83L145.9,55l-5.46,1.65-5.51,1.64-4,5C121.15,75.44,107.65,82.66,95.75,82c-4.58-.24-6.34-1.06-8.69-4.22-1-1.3-1.23-2.41-1.17-5.81.06-6.22,2.29-10.62,13-25.81,2.69-3.82,4.86-7.16,4.86-7.33s-3.22-.94-7.15-1.53c-9.39-1.4-13.9-2.81-19.13-6-2.34-1.41-4.34-2.58-4.4-2.58S64.3,44.05,53.74,62.83Z" />
                <path d="M54.33,113.51c-2,1.11-9.21,11-10,13.55-1.47,5.45,3.4,12.26,9.62,13.37,3.81.7,5.81-.7,10.73-7.27,4.05-5.52,4.4-6.16,4.4-8.8,0-3.64-.76-5.69-3.11-8.16C62.72,112.74,57.85,111.63,54.33,113.51Z" />
                <path d="M86.36,115.8c-1.29.58-4.81,4.93-12.26,15.07-5.75,7.81-10.79,14.9-11.2,15.73-2.88,5.51,2.4,14,9.09,14.6,5,.47,5.75-.18,14.54-12.14,14.85-20.18,14.26-19.18,14.26-22.3C100.79,119.2,92.58,112.92,86.36,115.8Z" />
                <path d="M104,137c-1.7.88-17.3,21.41-18.88,24.87-2.64,5.81,2.46,14,9.15,14.61,4.93.47,5.69-.18,13.72-11.21,10.74-14.78,10.39-14.19,10.39-17.65a11,11,0,0,0-5-9.27C110.47,136.27,106.48,135.68,104,137Z" />
                <path d="M121.73,158.26c-2.11,1.3-12.55,15.61-13.37,18.31-1.3,4.22,1.7,9.85,6.56,12.37a8.21,8.21,0,0,0,8,.06c2.29-1.23,12.85-15.31,13.73-18.3s-.18-6.51-2.88-9.5C130.24,157.27,125.25,156,121.73,158.26Z" />
            </g>
        </svg>
    );
};

TransmissionIcon.propTypes = propTypes;
TransmissionIcon.defaultProps = defaultProps;

export default TransmissionIcon;
