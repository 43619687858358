import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function Loader({ loading }) {
    const classes = useStyles();
    return (
        loading && (
            <div className={classes.container}>
                <CircularProgress size={70} />
            </div>
        )
    );
}

const useStyles = makeStyles(theme => ({
        container: {
            zIndex: 100,
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: theme.palette.background.default,
            opacity: 0.7,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }
));
