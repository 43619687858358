import {
    isSmartPlug_switch,
    isRelay_switch,
    isRelayDE_switch,
    isSmartPlug_temperature,
    isRelay_temperature,
    isRelayDE_temperature,
    isPilotWire_temperature,
    isProbe_temperature,
    isHeatAndCool_temperature,
    isThermostat_temperature,
    isLightModule_lighting,
    isSmartPlug_lighting,
    isRelay_lighting,
    isRelayDE_lighting,
    isClamp_measures,
    isFlowMeter_measures,
    isRollingShutter_shutters,
} from 'store/products/utils/predicates';

export const hasInputModuleType = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product);
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product)

export const hasInputLabel = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    isPilotWire_temperature(product) ||
    isProbe_temperature(product) ||
    isHeatAndCool_temperature(product) ||
    isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product) ||
    isClamp_measures(product) ||
    isFlowMeter_measures(product) ||
    isRollingShutter_shutters(product);

export const hasInputBrand = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    isPilotWire_temperature(product) ||
    isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product) ||
    isClamp_measures(product);
// isFlowMeter_measures(product)  ||
// isRollingShutter_shutters(product

export const hasInputModel = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    isPilotWire_temperature(product) ||
    isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product) ||
    isClamp_measures(product);
// isFlowMeter_measures(product)  ||
// isRollingShutter_shutters(product

export const hasInputYear = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    isPilotWire_temperature(product) ||
    isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product) ||
    isClamp_measures(product);
// isFlowMeter_measures(product)  ||
// isRollingShutter_shutters(product

export const hasInputMaxPower = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product) ||
    isClamp_measures(product);
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputSecurityState = product =>
    isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    // isRelay_temperature(product) ||
    // isRelayDE_temperature(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isLightModule_lighting(product) ||
    isSmartPlug_lighting(product);
// isRelay_lighting(product) ||
// isRelayDE_lighting(product) ||
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputExcludeRT2012 = product => false;
// isSmartPlug_switch(product) ||
// isRelay_switch(product) ||
// isRelayDE_switch(product) ||
// isSmartPlug_temperature(product) ||
// isRelay_temperature(product) ||
// isRelayDE_temperature(product) ||
// isPilotWire_temperature(product) ||
// isProbe_temperature(product) ||
// isHeatAndCool_temperature(product) ||
// isThermostat_temperature(product) ||
// isAirzone_temperature(product) ||
// isLightModule_lighting(product) ||
// isSmartPlug_lighting(product) ||
// isRelay_lighting(product) ||
// isRelayDE_lighting(product);
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product)

export const hasInputIdle = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product);
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputCalibrationThreshold = product =>
    // isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    // isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product) ||
    isClamp_measures(product) ||
    // isFlowMeter_measures(product) ||
    isRollingShutter_shutters(product);

export const hasInputPhase = product =>
    // isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product) ||
    isClamp_measures(product);
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputMinMax = product =>
    // isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product);
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputLinkProbe = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    isProbe_temperature(product);
// isHeatAndCool_temperature(product) ||
// isThermostat_temperature(product) ||
// isAirzone_temperature(product) ||
// isSmartPlug_temperature(product) ||
// isRelay_temperature(product) ||
// isRelayDE_temperature(product) ||
// isLightModule_lighting(product) ||
// isSmartPlug_lighting(product) ||
// isRelay_lighting(product) ||
// isRelayDE_lighting(product);
// isClamp_measures(product);
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputLinkModule = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    // isRelay_temperature(product) ||
    // isRelayDE_temperature(product) ||
    isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    // isRelay_lighting(product) ||
    // isRelayDE_lighting(product)
    // isClamp_measures(product);
    // isFlowMeter_measures(product) ||
    isRollingShutter_shutters(product);

export const hasInputRemote = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    isHeatAndCool_temperature(product);
// isThermostat_temperature(product) ||
// isAirzone_temperature(product) ||
// isSmartPlug_temperature(product) ||
// isRelay_temperature(product) ||
// isRelayDE_temperature(product) ||
// isLightModule_lighting(product) ||
// isSmartPlug_lighting(product) ||
// isRelay_lighting(product) ||
// isRelayDE_lighting(product) ||
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputOperatingMode = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    isHeatAndCool_temperature(product);
// isThermostat_temperature(product) ||
// isAirzone_temperature(product) ||
// isSmartPlug_temperature(product) ||
// isRelay_temperature(product) ||
// isRelayDE_temperature(product) ||
// isLightModule_lighting(product) ||
// isSmartPlug_lighting(product) ||
// isRelay_lighting(product) ||
// isRelayDE_lighting(product) ||
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputDelta = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    isHeatAndCool_temperature(product);
// isThermostat_temperature(product) ||
// isAirzone_temperature(product) ||
// isSmartPlug_temperature(product) ||
// isRelay_temperature(product) ||
// isRelayDE_temperature(product) ||
// isLightModule_lighting(product) ||
// isSmartPlug_lighting(product) ||
// isRelay_lighting(product) ||
// isRelayDE_lighting(product) ||
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputLinkWire = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    isPilotWire_temperature(product);
// isProbe_temperature(product) ||
// isHeatAndCool_temperature(product) ||
// isThermostat_temperature(product);
// isAirzone_temperature(product) ||
// isSmartPlug_temperature(product) ||
// isRelay_temperature(product) ||
// isRelayDE_temperature(product) ||
// isLightModule_lighting(product) ||
// isSmartPlug_lighting(product) ||
// isRelay_lighting(product) ||
// isRelayDE_lighting(product) ||
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputLinkRelay = product =>
    // isSmartPlug_switch(product) ||
    isRelay_switch(product) || isRelayDE_switch(product);
// isPilotWire_temperature(product) ||
// isProbe_temperature(product) ||
// isHeatAndCool_temperature(product) ||
// isThermostat_temperature(product);
// isAirzone_temperature(product) ||
// isSmartPlug_temperature(product) ||
// isRelay_temperature(product) ||
// isRelayDE_temperature(product) ||
// isLightModule_lighting(product) ||
// isSmartPlug_lighting(product) ||
// isRelay_lighting(product) ||
// isRelayDE_lighting(product) ||
// isClamp_measures(product) ||
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputLinkClamp = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    // isRelay_temperature(product) ||
    // isRelayDE_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    // isRelay_lighting(product) ||
    // isRelayDE_lighting(product) ||
    isClamp_measures(product);
// isFlowMeter_measures(product) ||
// isRollingShutter_shutters(product;

export const hasInputMeasuresType = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    // isRelay_temperature(product) ||
    // isRelayDE_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    // isRelay_lighting(product) ||
    // isRelayDE_lighting(product) ||
    // isClamp_measures(product) ||
    isFlowMeter_measures(product);
// isRollingShutter_shutters(product;

export const hasInputConversionRate = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    // isRelay_temperature(product) ||
    // isRelayDE_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    // isRelay_lighting(product) ||
    // isRelayDE_lighting(product) ||
    // isClamp_measures(product) ||
    isFlowMeter_measures(product);
// isRollingShutter_shutters(product;

export const hasInputReverse = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    // isRelay_temperature(product) ||
    // isRelayDE_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    // isRelay_lighting(product) ||
    // isRelayDE_lighting(product) ||
    // isClamp_measures(product) ||
    // isFlowMeter_measures(product) ||
    isRollingShutter_shutters(product);

export const hasInputCalibration = product =>
    // isSmartPlug_switch(product) ||
    // isRelay_switch(product) ||
    // isRelayDE_switch(product) ||
    // isPilotWire_temperature(product) ||
    // isProbe_temperature(product) ||
    // isHeatAndCool_temperature(product) ||
    // isThermostat_temperature(product) ||
    // isAirzone_temperature(product) ||
    // isSmartPlug_temperature(product) ||
    // isRelay_temperature(product) ||
    // isRelayDE_temperature(product) ||
    // isLightModule_lighting(product) ||
    // isSmartPlug_lighting(product) ||
    // isRelay_lighting(product) ||
    // isRelayDE_lighting(product) ||
    // isClamp_measures(product) ||
    // isFlowMeter_measures(product) ||
    isRollingShutter_shutters(product);
