import React from 'react';
import CGVText from './CGVText';
import t from 'utils/Translator';

const ModalCGU = props => {
    return (
        <div style={{ margin: 14 }}>
            <h1>{t.get('cgv.intro.title')}</h1>
            <CGVText weight="800" text={t.get('cgv.intro.title01')} gutter />
            <CGVText weight="bold" text={t.get('cgv.intro.01')} />
            <CGVText text={t.get('cgv.intro.02')} />
            <CGVText weight="bold" text={t.get('cgv.intro.03')} />
            <CGVText text={t.get('cgv.intro.04')} gutter />

            <CGVText weight="600" text={t.get('cgv.preamb.title')} gutter />
            <CGVText text={t.get('cgv.preamb.01')} />
            <CGVText text={t.get('cgv.preamb.02')} />
            <CGVText text={t.get('cgv.preamb.03')} gutter />

            <CGVText weight="600" text={t.get('cgv.art01.title')} gutter />
            <CGVText weight="400" text={t.get('cgv.art01.subline')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art01.listing.01')} />
                <CGVText listing text={t.get('cgv.art01.listing.02')} />
                <CGVText listing text={t.get('cgv.art01.listing.03')} />
                <CGVText listing text={t.get('cgv.art01.listing.04')} />
                <CGVText listing text={t.get('cgv.art01.listing.05')} />
                <CGVText listing text={t.get('cgv.art01.listing.06')} />
                <CGVText listing text={t.get('cgv.art01.listing.07')} />
                <CGVText listing text={t.get('cgv.art01.listing.08')} />
                <CGVText listing text={t.get('cgv.art01.listing.09')} />
                <CGVText listing gutter text={t.get('cgv.art01.listing.10')} />
            </div>

            <CGVText weight="600" text={t.get('cgv.art02.title')} gutter />
            <CGVText text={t.get('cgv.art02.01')} />
            <CGVText text={t.get('cgv.art02.02')} />
            <CGVText text={t.get('cgv.art02.03')} />
            <CGVText text={t.get('cgv.art02.04')} />
            <CGVText text={t.get('cgv.art02.05')} />
            <CGVText text={t.get('cgv.art02.06')} />
            <CGVText text={t.get('cgv.art02.07')} gutter />

            <CGVText weight="600" text={t.get('cgv.art03.title')} gutter />
            <CGVText text={t.get('cgv.art03.01')} />
            <CGVText text={t.get('cgv.art03.02')} />
            <CGVText text={t.get('cgv.art03.03')} />
            <CGVText text={t.get('cgv.art03.04')} gutter />

            <CGVText weight="600" text={t.get('cgv.art04.title')} gutter />
            <CGVText text={t.get('cgv.art04.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art05.title')} gutter />
            <CGVText text={t.get('cgv.art05.01')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art05.listing.01')} />
                <CGVText listing text={t.get('cgv.art05.listing.02')} />
                <CGVText listing text={t.get('cgv.art05.listing.03')} />
                <CGVText listing text={t.get('cgv.art05.listing.04')} />
                <CGVText listing text={t.get('cgv.art05.listing.05')} gutter />
            </div>

            <CGVText weight="600" text={t.get('cgv.art06.title')} gutter />
            <CGVText text={t.get('cgv.art06.01')} />
            <CGVText text={t.get('cgv.art06.02')} />
            <CGVText text={t.get('cgv.art06.03')} />
            <CGVText text={t.get('cgv.art06.04')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art06.listing.01')} />
                <CGVText listing text={t.get('cgv.art06.listing.02')} />
                <CGVText listing text={t.get('cgv.art06.listing.03')} />
                <CGVText listing text={t.get('cgv.art06.listing.04')} />
            </div>
            <CGVText text={t.get('cgv.art06.05')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art06.listing01.01')} />
                <CGVText listing text={t.get('cgv.art06.listing01.02')} />
                <CGVText listing text={t.get('cgv.art06.listing01.03')} />
                <CGVText listing text={t.get('cgv.art06.listing01.04')} />
                <CGVText listing text={t.get('cgv.art06.listing01.05')} />
            </div>
            <CGVText text={t.get('cgv.art06.06')} />
            <CGVText text={t.get('cgv.art06.07')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art06.listing02.01')} />
                <CGVText listing text={t.get('cgv.art06.listing02.02')} />
            </div>
            <CGVText text={t.get('cgv.art06.08')} />
            <CGVText text={t.get('cgv.art06.09')} />
            <CGVText text={t.get('cgv.art06.10')} />
            <CGVText text={t.get('cgv.art06.11')} />
            <CGVText text={t.get('cgv.art06.12')} />
            <CGVText text={t.get('cgv.art06.13')} />
            <CGVText text={t.get('cgv.art06.14')} />
            <CGVText text={t.get('cgv.art06.15')} />
            <CGVText text={t.get('cgv.art06.16')} gutter />

            <CGVText weight="600" text={t.get('cgv.art07.title')} gutter />
            <CGVText text={t.get('cgv.art07.01')} />
            <CGVText text={t.get('cgv.art07.02')} gutter />

            <CGVText weight="600" text={t.get('cgv.art08.title')} gutter />
            <CGVText text={t.get('cgv.art08.subtitle01')} gutter />
            <CGVText text={t.get('cgv.art08.01')} />
            <CGVText text={t.get('cgv.art08.02')} gutter />
            <CGVText text={t.get('cgv.art08.subtitle02')} gutter />
            <CGVText text={t.get('cgv.art08.03')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art08.listing.01')} />
                <CGVText listing text={t.get('cgv.art08.listing.02')} />
                <CGVText listing text={t.get('cgv.art08.listing.03')} />
                <CGVText listing text={t.get('cgv.art08.listing.04')} />
                <CGVText listing text={t.get('cgv.art08.listing.05')} />
                <CGVText listing text={t.get('cgv.art08.listing.06')} />
            </div>
            <CGVText text={t.get('cgv.art08.04')} />
            <CGVText text={t.get('cgv.art08.05')} />
            <CGVText text={t.get('cgv.art08.06')} />
            <CGVText text={t.get('cgv.art08.07')} />
            <CGVText text={t.get('cgv.art08.08')} />
            <CGVText text={t.get('cgv.art08.09')} gutter />
            <CGVText text={t.get('cgv.art08.subtitle03')} gutter />
            <CGVText text={t.get('cgv.art08.10')} />
            <CGVText text={t.get('cgv.art08.11')} gutter />
            <CGVText text={t.get('cgv.art08.subtitle04')} gutter />
            <CGVText text={t.get('cgv.art08.12')} />
            <CGVText text={t.get('cgv.art08.13')} />
            <CGVText text={t.get('cgv.art08.14')} />
            <CGVText text={t.get('cgv.art08.15')} />
            <CGVText text={t.get('cgv.art08.16')} />
            <CGVText text={t.get('cgv.art08.17')} />
            <CGVText text={t.get('cgv.art08.18')} />
            <CGVText text={t.get('cgv.art08.19')} />
            <CGVText text={t.get('cgv.art08.20')} />
            <CGVText text={t.get('cgv.art08.21')} />
            <CGVText text={t.get('cgv.art08.22')} />
            <CGVText text={t.get('cgv.art08.23')} />
            <CGVText text={t.get('cgv.art08.24')} gutter />
            <CGVText text={t.get('cgv.art08.subtitle05')} gutter />
            <CGVText text={t.get('cgv.art08.25')} />
            <CGVText text={t.get('cgv.art08.26')} />
            <CGVText text={t.get('cgv.art08.27')} />
            <CGVText text={t.get('cgv.art08.28')} />
            <CGVText text={t.get('cgv.art08.29')} gutter />
            <CGVText text={t.get('cgv.art08.subtitle06')} gutter />
            <CGVText text={t.get('cgv.art08.30')} />
            <CGVText text={t.get('cgv.art08.31')} gutter />
            <CGVText text={t.get('cgv.art08.subtitle07')} gutter />
            <CGVText text={t.get('cgv.art08.32')} />
            <CGVText text={t.get('cgv.art08.33')} />
            <CGVText text={t.get('cgv.art08.34')} gutter />

            <CGVText weight="600" text={t.get('cgv.art09.title')} gutter />
            <CGVText text={t.get('cgv.art09.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art10.title')} gutter />
            <CGVText text={t.get('cgv.art10.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art11.title')} gutter />
            <CGVText text={t.get('cgv.art11.01')} />
            <CGVText text={t.get('cgv.art11.02')} />
            <CGVText text={t.get('cgv.art11.03')} />
            <CGVText text={t.get('cgv.art11.04')} />
            <CGVText text={t.get('cgv.art11.05')} />
            <CGVText text={t.get('cgv.art11.06')} />
            <CGVText text={t.get('cgv.art11.07')} />
            <CGVText text={t.get('cgv.art11.08')} />
            <CGVText text={t.get('cgv.art11.09')} />
            <CGVText text={t.get('cgv.art11.10')} />
            <CGVText text={t.get('cgv.art11.11')} />
            <CGVText text={t.get('cgv.art11.12')} gutter />

            <CGVText weight="600" text={t.get('cgv.art12.title')} gutter />
            <CGVText text={t.get('cgv.art12.01')} />
            <CGVText text={t.get('cgv.art12.02')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art12.listing.01')} />
                <CGVText listing text={t.get('cgv.art12.listing.02')} />
                <CGVText listing text={t.get('cgv.art12.listing.03')} />
                <CGVText listing text={t.get('cgv.art12.listing.04')} />
                <CGVText listing text={t.get('cgv.art12.listing.05')} />
                <CGVText listing text={t.get('cgv.art12.listing.06')} />
                <CGVText listing text={t.get('cgv.art12.listing.07')} />
                <CGVText listing text={t.get('cgv.art12.listing.08')} />
                <CGVText listing text={t.get('cgv.art12.listing.09')} />
                <CGVText listing gutter text={t.get('cgv.art12.listing.10')} />
            </div>
            <CGVText text={t.get('cgv.art12.subtitle01')} gutter />
            <CGVText text={t.get('cgv.art12.03')} />
            <CGVText text={t.get('cgv.art12.04')} gutter />
            <CGVText text={t.get('cgv.art12.subtitle02')} gutter />
            <CGVText text={t.get('cgv.art12.05')} gutter />
            <CGVText text={t.get('cgv.art12.subtitle03')} gutter />
            <CGVText text={t.get('cgv.art12.06')} />
            <CGVText text={t.get('cgv.art12.07')} />
            <CGVText text={t.get('cgv.art12.08')} />
            <CGVText text={t.get('cgv.art12.09')} />
            <CGVText text={t.get('cgv.art12.10')} gutter />

            <CGVText weight="600" text={t.get('cgv.art13.title')} gutter />
            <CGVText text={t.get('cgv.art13.01')} />
            <CGVText text={t.get('cgv.art13.02')} />
            <CGVText text={t.get('cgv.art13.03')} />
            <CGVText text={t.get('cgv.art13.04')} gutter />

            <CGVText weight="600" text={t.get('cgv.art14.title')} gutter />
            <CGVText text={t.get('cgv.art14.01')} />
            <CGVText text={t.get('cgv.art14.02')} />
            <CGVText text={t.get('cgv.art14.03')} />
            <CGVText text={t.get('cgv.art14.04')} />
            <CGVText text={t.get('cgv.art14.05')} />
            <CGVText text={t.get('cgv.art14.06')} />
            <CGVText text={t.get('cgv.art14.07')} gutter />

            <CGVText weight="600" text={t.get('cgv.art15.title')} gutter />
            <CGVText text={t.get('cgv.art15.subtitle01')} gutter />
            <CGVText text={t.get('cgv.art15.01')} />
            <CGVText text={t.get('cgv.art15.02')} />
            <CGVText text={t.get('cgv.art15.03')} />
            <CGVText text={t.get('cgv.art15.04')} gutter />
            <CGVText text={t.get('cgv.art15.subtitle02')} gutter />
            <CGVText text={t.get('cgv.art15.05')} gutter />
            <CGVText text={t.get('cgv.art15.subtitle03')} gutter />
            <CGVText text={t.get('cgv.art15.06')} />
            <CGVText text={t.get('cgv.art15.07')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art15.listing.01')} />
                <CGVText listing text={t.get('cgv.art15.listing.02')} />
                <CGVText listing text={t.get('cgv.art15.listing.03')} />
                <CGVText listing text={t.get('cgv.art15.listing.04')} gutter />
            </div>

            <CGVText weight="600" text={t.get('cgv.art16.title')} gutter />
            <CGVText text={t.get('cgv.art16.01')} />
            <CGVText text={t.get('cgv.art16.02')} />
            <CGVText text={t.get('cgv.art16.03')} />
            <CGVText text={t.get('cgv.art16.04')} />
            <CGVText text={t.get('cgv.art16.05')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art16.listing.01')} />
                <CGVText listing text={t.get('cgv.art16.listing.02')} />
                <div>
                    <CGVText
                        listing
                        text={t.get('cgv.art16.listing.sublist.01')}
                    />
                    <div>
                        <CGVText
                            listing
                            text={t.get(
                                'cgv.art16.listing.sublist.subsublist.01',
                            )}
                        />
                        <CGVText
                            listing
                            text={t.get(
                                'cgv.art16.listing.sublist.subsublist.02',
                            )}
                        />
                    </div>
                    <CGVText
                        listing
                        text={t.get('cgv.art16.listing.sublist.02')}
                    />
                </div>
                <CGVText listing text={t.get('cgv.art16.listing.03')} />
                <CGVText listing text={t.get('cgv.art16.listing.04')} />
                <CGVText listing text={t.get('cgv.art16.listing.05')} />
            </div>
            <CGVText text={t.get('cgv.art16.06')} />
            <CGVText text={t.get('cgv.art16.07')} />
            <CGVText text={t.get('cgv.art16.08')} gutter />

            <CGVText weight="600" text={t.get('cgv.art17.title')} gutter />
            <CGVText text={t.get('cgv.art17.01')} />
            <CGVText text={t.get('cgv.art17.02')} />
            <CGVText text={t.get('cgv.art17.03')} />
            <CGVText text={t.get('cgv.art17.04')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art17.listing.01')} />
                <CGVText listing text={t.get('cgv.art17.listing.02')} />
            </div>
            <CGVText text={t.get('cgv.art17.05')} />
            <CGVText text={t.get('cgv.art17.06')} />
            <CGVText text={t.get('cgv.art17.07')} />
            <CGVText text={t.get('cgv.art17.08')} />
            <CGVText text={t.get('cgv.art17.09')} lineJump />
            <div>
                <CGVText listing text={t.get('cgv.art17.listing01.01')} />
                <CGVText listing text={t.get('cgv.art17.listing01.02')} />
                <CGVText listing text={t.get('cgv.art17.listing01.03')} />
            </div>
            <CGVText text={t.get('cgv.art17.10')} />
            <CGVText text={t.get('cgv.art17.11')} />
            <CGVText text={t.get('cgv.art17.12')} />
            <CGVText text={t.get('cgv.art17.13')} gutter />

            <CGVText weight="600" text={t.get('cgv.art18.title')} gutter />
            <CGVText text={t.get('cgv.art18.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art19.title')} gutter />
            <CGVText text={t.get('cgv.art19.01')} />
            <CGVText text={t.get('cgv.art19.02')} gutter />

            <CGVText weight="600" text={t.get('cgv.art20.title')} gutter />
            <CGVText text={t.get('cgv.art20.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art21.title')} gutter />
            <CGVText text={t.get('cgv.art21.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art22.title')} gutter />
            <CGVText text={t.get('cgv.art22.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art23.title')} gutter />
            <CGVText text={t.get('cgv.art23.01')} gutter />
            <div>
                <CGVText listing text={t.get('cgv.art23.listing.01')} />
                <CGVText listing text={t.get('cgv.art23.listing.02')} gutter />
            </div>

            <CGVText weight="600" text={t.get('cgv.art24.title')} gutter />
            <CGVText text={t.get('cgv.art24.01')} />
            <CGVText text={t.get('cgv.art24.02')} />
            <CGVText text={t.get('cgv.art24.03')} />
            <CGVText text={t.get('cgv.art24.04')} />
            <CGVText text={t.get('cgv.art24.05')} gutter />

            <CGVText weight="600" text={t.get('cgv.art25.title')} gutter />
            <CGVText text={t.get('cgv.art25.01')} gutter />

            <CGVText weight="600" text={t.get('cgv.art26.title')} gutter />
            <CGVText text={t.get('cgv.art26.01')} gutter />

            {/*            <button
                onClick={() => {
                    props.toggleRGPD && props.toggleRGPD();
                    props.hide();
                }}
                color="#fff"
            >
                {t.get('rgpd-modal.body.button.accept')}
            </button>*/}
        </div>
    );
};

export default ModalCGU;
