import {
    isAirzone_temperature,
    isHeatAndCool_temperature,
    isPilotWire_temperature,
    isProbe_temperature,
    isRelay_switch,
    isRelay_temperature,
    isRelayDE_temperature,
    isSmartPlug_switch,
    isThermostat_temperature,
    isSmartPlug_temperature,
} from 'store/products/utils/predicates';

export const isTemperatureWithModes = product =>
    isPilotWire_temperature(product);

export const isTemperatureWithMeasures = product =>
    isProbe_temperature(product) ||
    isHeatAndCool_temperature(product) ||
    isThermostat_temperature(product) ||
    isAirzone_temperature(product);

export const isTemperatureWithSwitch = product =>
    isHeatAndCool_temperature(product) ||
    isThermostat_temperature(product) ||
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product);

export const isTemperatureWithSetPoint = product =>
    isHeatAndCool_temperature(product) ||
    isThermostat_temperature(product) ||
    isAirzone_temperature(product);

export const hasPowerData = product =>
    isSmartPlug_switch(product) || isRelay_switch(product);
