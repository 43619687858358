import React, { useReducer, useRef } from 'react';
import InputLabel from './inputs/InputLabel';
import InputBrand from './inputs/InputBrand';
import InputModel from './inputs/InputModel';
import InputYear from './inputs/InputYear';
import InputMaxPower from './inputs/InputMaxPower';
import InputSecurityState from './inputs/InputSecurityState';
import InputModuleType from './inputs/InputModuleType';
import InputMinMax from './inputs/InputMinMax';
import InputMeasuresType from './inputs/InputMeasuresType';
import InputExcludeRT2012 from './inputs/InputExcludeRT2012';
import InputConversionRate from './inputs/InputConversionRate';
import InputIdle from './inputs/InputIdle';
import {
    hasInputBrand,
    hasInputConversionRate,
    hasInputExcludeRT2012,
    hasInputIdle,
    hasInputLabel,
    hasInputMaxPower,
    hasInputMeasuresType,
    hasInputMinMax,
    hasInputModel,
    hasInputModuleType,
    hasInputSecurityState,
    hasInputYear,
} from '../utils/inputsPredicates';
import SubmitButton from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/SubmitButton';
import _ from 'lodash';
import { updateProductAPI, updateProductEvent } from 'store/products/thunks';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

const inputs = [
    { predicate: hasInputLabel, Input: InputLabel },
    { predicate: hasInputBrand, Input: InputBrand },
    { predicate: hasInputModel, Input: InputModel },
    { predicate: hasInputYear, Input: InputYear },
    { predicate: hasInputMaxPower, Input: InputMaxPower },
    { predicate: hasInputSecurityState, Input: InputSecurityState },
    { predicate: hasInputModuleType, Input: InputModuleType },
    { predicate: hasInputIdle, Input: InputIdle },
    { predicate: hasInputMinMax, Input: InputMinMax },
    { predicate: hasInputMeasuresType, Input: InputMeasuresType },
    { predicate: hasInputExcludeRT2012, Input: InputExcludeRT2012 },
    { predicate: hasInputConversionRate, Input: InputConversionRate },
];

export default function PrimaryInputs({ product }) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const init = useRef(null);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            label: product.label || '',
            brand: product.brand || '',
            model: product.model || '',
            year: product.year || '',
            power: product.power || '',
            security_state: product.security_state || 'OFF',
            module_type: product.module_type || '',
            idle_toggle: product.idle_toggle,
            idle_time: product.idle_time || '',
            idle_power: product.idle_power || '',
            load_time: product.load_time || '',
            max_autonomy: product.max_autonomy || '',
            line_type_diversity: product.line_type_diversity,
            stats_rt2012_excluded: product.stats_rt2012_excluded || false,
            threshold: product.threshold || '',
            type_diversity: product.type_diversity || '',
            remote: {},
        },
        // validationSchema: validationSchema,
        onSubmit: () => {},
    });

    const handleSubmit = async () => {
        const { payload } = await dispatch(
            updateProductAPI({ product, formik }),
        );
        if (payload) {
            await dispatch(
                updateProductEvent({ product: payload.product_module, formik }),
            );
        }
        init.current = formik.values;
        forceUpdate();
    };

    const isDisabled = () => {
        if (init.current) {
            return _.isEqual(formik.values, init.current);
        }

        return _.isEqual(formik.values, formik.initialValues);
    };

    return (
        <>
            {inputs.map(
                ({ predicate, Input }, i) =>
                    predicate(product) && <Input key={i} formik={formik} />,
            )}
            {inputs.some(({ predicate }) => predicate(product)) && (
                <>
                    <SubmitButton
                        disabled={isDisabled()}
                        handleSubmit={handleSubmit}
                    />
                </>
            )}
        </>
    );
}
