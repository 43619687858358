import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { SvgIcon } from '@mui/material';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

const LeafIcon = props => {
    const StyledSvgIcon = withStyles(theme => ({
        root: {
            height: props.height,
            width: props.width,
        },
    }))(SvgIcon);

    return (
        <StyledSvgIcon
            viewBox="0 0 300 297.11"
            height={props.height}
            width={props.width}
            {...props}
        >
            <g fill={props.color}>
                <path
                    fill={props.color}
                    d="M214.37 172.56c-54.17-16.49-102.55-6.95-144.18 34.89 1.87-36.76 29-81.13 79.45-85.15 56.74-4.52 104.9 16.6 148.31 51.1.69.55 1.07 1.5 2.05 2.9-27.09 35.86-57.6 67.79-103.76 77.58-32.08 6.81-64 1.69-94.57-9.63-7.86-2.91-11.6-1.61-15.2 5.66-6.64 13.39-14 26.44-21.21 39.52-4 7.29-11.81 9.77-18.13 5.77-6.71-4.25-8-10.76-4-17 22.6-35.63 48.72-67.9 86.77-88.47 24.83-13.41 52-14 79.12-14.71 1.82-.05 3.65 0 5.47 0zM49.45 0c36.78 32.44 53.78 84.86 39.33 124.53-5.1 14-14 26.66-21.19 39.93l-4.9-.52c-4.23-15.57-9.39-31-12.42-46.76s-3.93-31.88-5.71-47.49c-10.8 16.16-9.36 48.23 4.92 103.08C28.25 168 5.1 140.34 1.12 115.42-2.3 94 2.39 73.89 11.23 54.6 20.53 34.31 33.44 16.44 49.45 0z"
                />
            </g>
        </StyledSvgIcon>
    );
};

LeafIcon.propTypes = propTypes;
LeafIcon.defaultProps = defaultProps;

export default LeafIcon;
