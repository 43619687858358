import { ReactComponent as NA } from 'data/components/WeatherIcon/svgs/wi-na.svg';
import { ReactComponent as Cloud } from 'data/components/WeatherIcon/svgs/wi-cloud.svg';
import { ReactComponent as DayCloudy } from 'data/components/WeatherIcon/svgs/wi-day-cloudy.svg';
import { ReactComponent as DayStormShowers } from 'data/components/WeatherIcon/svgs/wi-day-storm-showers.svg';
import { ReactComponent as DaySunny } from 'data/components/WeatherIcon/svgs/wi-day-sunny.svg';
import { ReactComponent as Fog } from 'data/components/WeatherIcon/svgs/wi-fog.svg';
import { ReactComponent as Hail } from 'data/components/WeatherIcon/svgs/wi-hail.svg';
import { ReactComponent as Rain } from 'data/components/WeatherIcon/svgs/wi-rain.svg';
import { ReactComponent as Showers } from 'data/components/WeatherIcon/svgs/wi-showers.svg';
import { ReactComponent as Sleet } from 'data/components/WeatherIcon/svgs/wi-sleet.svg';
import { ReactComponent as Snow } from 'data/components/WeatherIcon/svgs/wi-snow.svg';
import { ReactComponent as SnowWind } from 'data/components/WeatherIcon/svgs/wi-snow-wind.svg';
import { ReactComponent as Sprinkle } from 'data/components/WeatherIcon/svgs/wi-sprinkle.svg';
import { ReactComponent as StormShowers } from 'data/components/WeatherIcon/svgs/wi-storm-showers.svg';
import { ReactComponent as Thunderstorm } from 'data/components/WeatherIcon/svgs/wi-thunderstorm.svg';
import React from 'react';
// https://erikflowers.github.io/weather-icons/

export default function WeatherIcon({ code, ...props }) {
    const getComponent = () => {
        switch (code) {
            case '113':
                return DaySunny;
            case '116':
                return Cloud;
            case '119':
                return DayCloudy;
            case '122':
            case '248':
            case '260':
                return Fog;
            case '143':
            case '176':
            case '263':
            case '266':
            case '293':
            case '296':
            case '353':
                return Sprinkle;
            case '179':
            case '227':
            case '323':
            case '326':
            case '329':
            case '332':
            case '335':
            case '338':
            case '368':
            case '371':
                return Snow;
            case '182':
            case '185':
            case '281':
            case '284':
            case '311':
            case '314':
            case '317':
            case '320':
            case '362':
            case '365':
            case '374':
                return Sleet;
            case '200':
                return DayStormShowers;
            case '230':
                return SnowWind;
            case '299':
            case '300':
                return Showers;
            case '305':
            case '308':
            case '356':
            case '359':
                return Rain;
            case '350':
            case '377':
                return Hail;
            case '386':
            case '392':
            case '395':
                return StormShowers;
            case '389':
                return Thunderstorm;
            default:
                return NA;
        }
    };

    const Component = getComponent();

    return <Component {...props} />;
}
