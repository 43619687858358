import Header from '../Header';
import NavBar from '../NavBar';
import React, { useEffect, useRef, useState } from 'react';
import Grid from 'data/components/Grid';
import Modals from 'data/components/Modals';
import Messages from '../Messages';
import { makeStyles } from '@mui/styles';
import { AppBar } from '@mui/material';
import BackgroundImage from './components/backgroundImage';
import snow from 'assets/beach.jpg';
import Footer from '../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { init } from 'store/auth/thunks';
import BackgroundImageLogin from '../Login/components/BackgroundImageLogin';
import Loader from '../Login/components/Loader';
import { useHistory } from 'react-router';

export default function Layout({ component: Component }, props) {
    const history = useHistory();
    const { loading } = useSelector(state => state.auth);
    const [isExpanded, setIsExpanded] = useState(true);
    const classes = useStyles({ isExpanded });
    const sidePanelContainerRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(async () => {
        const { error } = await dispatch(init());
        if (error) history.push('/login');
    }, []);

    if (loading) {
        return (
            <div>
                <BackgroundImageLogin />
                <Loader loading={loading} />
            </div>
        );
    }

    return (
        <>
            <AppBar className={classes.appBar}>
                <Grid container item xs={12} className={classes.container}>
                    <Grid item xs={12}>
                        <Header
                            isExpanded={isExpanded}
                            setIsExpanded={setIsExpanded}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <NavBar />
                    </Grid>
                    <Grid item xs={12} className={classes.slideContainer}>
                        <div ref={sidePanelContainerRef} />
                    </Grid>
                </Grid>
            </AppBar>

            <Grid container className={classes.contentContainer}>
                <Grid item xs={12}>
                    <BackgroundImage />
                    <Component
                        headerExpanded={isExpanded}
                        setHeaderExpanded={setIsExpanded}
                        sidePanelContainerRef={sidePanelContainerRef}
                    />
                </Grid>
            </Grid>
            <Modals />
            <Footer headerExpanded={isExpanded} />
            <Messages />
        </>
    );
}
const useStyles = makeStyles(theme => ({
    container: {},
    slideContainer: {
        position: 'relative',
    },
    appBar: {
        boxShadow: 'none',
        overflowX: 'clip',
        backgroundImage: `url(${snow})`,
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
    },
    contentContainer: {
        overflowX: 'clip',
        paddingTop: ({ isExpanded }) => (isExpanded ? 372 + 48 : 72 + 48),
        transition: 'margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        height: '100%',
    },
}));
