import { createSlice } from '@reduxjs/toolkit';
import { login, init, linkProvider, unlinkProvider } from './thunks';
import {
    removeTokenFromStorages,
    sanitizeNullValues,
    setTokenToHttpHeader,
    setTokenToStorages,
} from 'utils';
import api from './api';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {
            email_google: null,
            email_facebook: null,
        },
        token: null,
        loading: false,
        error: null,
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.profile = action.payload;
        },
        logout: () => {},
        setToken: (state, action) => {
            const token = action.payload;
            setTokenToHttpHeader(token);
            state.token = token;
        },
    },
    extraReducers: builder => {
        // Login
        builder.addCase(login.pending, state => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const { access_token, rememberMe } = action.payload;
            setTokenToHttpHeader(access_token);
            setTokenToStorages(rememberMe, access_token);
            state.token = access_token;
            state.loading = false;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.error = action?.error?.message || 'error';
            state.loading = false;
        });
        // Initialize
        builder.addCase(init.pending, state => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(init.fulfilled, (state, action) => {
            const { profile } = action.payload;
            profile.email_facebook = sanitizeNullValues(profile.email_facebook);
            profile.email_google = sanitizeNullValues(profile.email_google);
            state.user = profile;
            state.loading = false;
        });
        builder.addCase(init.rejected, (state, action) => {
            state.error = action?.error?.message || true;
            if (state.error === 'Your token is not valid') {
                removeTokenFromStorages();
            }
            state.loading = false;
        });
        // Link provider
        builder.addCase(linkProvider.pending, state => {
            state.error = null;
        });
        builder.addCase(linkProvider.fulfilled, (state, action) => {
            const { provider, provider_email } = action.payload;
            state.user[`email_${provider}`] = provider_email;
        });
        builder.addCase(linkProvider.rejected, (state, action) => {
            state.error = action?.error?.message || true;
        });
        // Unlink provider
        builder.addCase(unlinkProvider.pending, state => {
            state.error = null;
        });
        builder.addCase(unlinkProvider.fulfilled, (state, action) => {
            const { provider } = action.payload;
            state.user[`email_${provider}`] = null;
        });
        builder.addCase(unlinkProvider.rejected, (state, action) => {
            state.error = action?.error?.message || true;
        });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = state => state.auth.user;

// TODO: need refac thunk
export const { setCurrentUser } = authSlice.actions;

export const recoverPassword = async email => {
    return await api.recoverPassword(email);
};

export const setNewPassword = async (email, recoveryPassword, newPassword) => {
    const { profile, accessToken, expiresIn } = await api.setNewPassword(
        email,
        recoveryPassword,
        newPassword,
    );

    return {
        payload: {
            profile,
            accessToken,
            expiresIn,
        },
    };
};

export const updateUser = (userId, user) => async dispatch => {
    try {
        const updatedUser = await api.update(userId, user);

        await dispatch(setCurrentUser(updatedUser));
    } catch (e) {
        console.log('an error occurred while updating user', e);
    }
};
