import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';
import React from 'react';
import ListItem from 'data/components/ListItem';
import ListItemText from 'data/components/ListItemText';
import { getModuleKey } from '../../../../utils';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const PlanningCell = ({ planningEvent, planningModal }) => {
    const classes = useStyles();

    const product = useSelector(
        state =>
            planningEvent?.product_identifier &&
            productsSelectors.selectById(state, getModuleKey(planningEvent)),
    );

    return (
        <>
            <ListItem
                key={product?.id}
                style={
                    planningModal
                        ? { height: 46, width: 160 }
                        : { height: 46, width: 180 }
                }
            >
                <ListItemText
                    style={{
                        textOverflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                    key={product?.id}
                    primary={product?.label}
                    secondary={
                        (planningEvent?.type === 'position' && (
                            <Typography className={classes.secondary}>
                                {planningEvent?.type_value}%
                            </Typography>
                        )) ||
                        (planningEvent?.type === 'thermostatic' && (
                            <Typography className={classes.secondary}>
                                {planningEvent?.type_value}°C
                            </Typography>
                        )) ||
                        (planningEvent?.type === 'config_clim' && (
                            <Typography className={classes.secondary}>
                                {planningEvent?.type_value.slice(-2)}°C
                            </Typography>
                        )) || (
                            <Typography className={classes.secondary}>
                                {planningEvent?.type_value}
                            </Typography>
                        )
                    }
                />
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    secondary: {
        color: theme.palette.text.primary,
    },
}));

export default PlanningCell;
