import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import React from 'react';
import Text from '../../../Text';
import { makeStyles } from '@mui/styles';
import { connectToAP } from '../../../../../MiddlewareSocket/emitters/widgets';

const InclusionWizardFirstStep = props => {
    const classes = useStyles();

    const connectProduct = async bssid => {
        clearInterval(props.apListIntervalRef.current);
        return await connectToAP(bssid);
    };
    return (
        <List className={classes.list}>
            {props.APList.length > 0 ? (
                props.APList.map(product => {
                    return (
                        <Button
                            onClick={async () => {
                                await connectProduct(product.bssid);
                                props.setFocusedProduct(product);
                                props.handleNext();
                            }}
                        >
                            <ListItem key={product.ssid}>
                                <ListItemText
                                    key={product.ssid}
                                    sx={{
                                        justifyContent: 'center',
                                    }}
                                    primary={product.ssid}
                                    secondary={product.bssid}
                                />
                            </ListItem>
                        </Button>
                    );
                })
            ) : (
                <Text sx={{ textAlign: 'center' }}>Aucun produit détecté</Text>
            )}
        </List>
    );
};

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 360,
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default InclusionWizardFirstStep;
