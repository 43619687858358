import React from "react";
import Grid from "../Grid";
import Text from "../Text";
import ProductsList from "../ProductsList";
import { makeStyles } from "@mui/styles";
import Button from "../Button";
import CloseIcon from "../../../assets/svgs/Close";
import Modal from "../Modals/Modal";
import useModal from "../../../utils/hooks/useModal";
import InclusionModal from "../Modals/InclusionModals";

export default function ProductsListContainer({
  filteredGroups,
  sidePanelIsOpen,
  sidePanelContainerRef,
  headerExpanded,
  dashboard,
  filter,
}) {
  const classes = useStyles();
  const [modalIsOpen, toggleModal] = useModal();

  return (
    <>
      {filteredGroups.map(({ label, groups }) => (
        <Grid
          key={label}
          container
          item
          xs={sidePanelIsOpen ? 10 : 12}
          className={classes.container}
        >
          <Grid container justifyContent="center" item xs={12}>
            <Text color="primary" variant="h5">
              {label}
            </Text>
          </Grid>
          <ProductsList
            dashboard={dashboard}
            groups={groups}
            sidePanelContainerRef={sidePanelContainerRef}
            filter={filter}
          />
          {!dashboard && (
            <div
              className={
                headerExpanded
                  ? classes.addButtonContainer
                  : classes.addButtonContainerExpanded
              }
            >
              <Button
                style={{
                  transform: "rotate(45deg)",
                }}
                onClick={() => {
                  toggleModal();
                }}
              >
                <CloseIcon width={30} />
              </Button>
            </div>
          )}

          <Modal open={modalIsOpen} closeFunction={toggleModal} modalWizard>
            <InclusionModal closeFunction={toggleModal} color={"red"} />
          </Modal>
        </Grid>
      ))}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 24,
    transition: "all 0.3s",
    marginBottom: 25,
  },
  addButtonContainer: {
    position: "fixed",
    right: 20,
    bottom: 20,
    display: "flex",
    justifyContent: "center",
    borderRadius: 45,
    backgroundColor: theme.palette.primary.main,
    width: 60,
    height: 60,
    zIndex: 99999,
  },
  addButtonContainerExpanded: {
    position: "fixed",
    right: 20,
    bottom: 20,
    display: "flex",
    justifyContent: "center",
    borderRadius: 45,
    backgroundColor: theme.palette.primary.main,
    width: 60,
    height: 60,
    zIndex: 99999,
  },
}));
