import React from 'react';
import Grid from 'data/components/Grid';
import PlanningTable from './components/PlanningTable';

const Plannings = props => {
    return (
        <Grid container justifyContent={'center'}>
            <PlanningTable
                sidePanelContainerRef={props.sidePanelContainerRef}
                headerExpanded={props.headerExpanded}
                setHeaderExpanded={() => props.setHeaderExpanded()}
            />
        </Grid>
    );
};

export default Plannings;
