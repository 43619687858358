import MiddlewareSocket from 'MiddlewareSocket/index';
import { isHeatAndCool_temperature } from 'store/products/utils/predicates';
import { getMode } from 'utils';

export const getAPWifiProductsList = () => {
    return MiddlewareSocket.emit(
        ['getAPWifiProductsList', 'APWifiProductsList'],
        {},
    );
};

export const connectToAP = productId => {
    return MiddlewareSocket.emit(['connectToAP', 'connectToAPFeedback'], {
        product_identifier: productId,
    });
};

export const startProductInclusion = (
    productId,
    ssid,
    password,
    encryption,
    encryptionType,
) => {
    return MiddlewareSocket.emit(
        ['startProductInclusion', 'startProductInclusionFeedback'],
        {
            product_identifier: productId,
            ssid,
            password,
            encryption,
            encryption_type: encryptionType,
        },
    );
};

export const setShutterPosition = (product, position) => {
    const { product_identifier, type, code } = product;
    return MiddlewareSocket.emit(
        ['fheProductSetPosition', 'fheProductSetPositionFeedback'],
        {
            product_identifier,
            product_type: type,
            code,
            position,
        },
    );
};

export const setBSODirection = (
    product_identifier,
    product_type,
    code,
    direction,
) => {
    return MiddlewareSocket.emit(
        ['fheProductActionBSO', 'fheProductFeedbackBSO'],
        {
            product_identifier,
            product_type,
            code,
            direction,
        },
    );
};

export const setSwitchState = (product, state) => {
    const { product_identifier, type, code } = product;
    if (isHeatAndCool_temperature(product)) {
        if (state === 'ON') {
            const { ventilation, consign_temp, remote_ref } = product;
            const mode = getMode(product);
            state = `${mode}_${ventilation}_${consign_temp} ${remote_ref}`;
        }
        return MiddlewareSocket.emit(
            ['fheProductSetClimConfig', 'fheProductFeedbackClimConfig'],
            {
                product_identifier,
                product_type: type,
                config: state,
                custom: false,
            },
        );
    }

    return MiddlewareSocket.emit(
        ['fheProductSetRelayState', 'fheProductFeedbackRelayState'],
        {
            product_identifier,
            product_type: type,
            code,
            state,
        },
    );
};

export const setInelioState = (product, state) => {
    const { product_identifier, code } = product;
    return MiddlewareSocket.emit(
        ['inelioStartAction', 'inelioStartActionFeedback'],
        {
            product_identifier,
            code,
            action: state === 'STDBY' ? 'START_CHARGE' : 'FORCE_STOP',
        },
    );
};

export const setSDM18HCLConfig = (product, mode, speed, temp) => {
    const { product_identifier, type, remote_ref } = product;
    const config = `${mode}_${speed}_${temp} ${remote_ref}`;
    return MiddlewareSocket.emit(
        ['fheProductSetClimConfig', 'fheProductFeedbackClimConfig'],
        {
            product_identifier,
            product_type: type,
            config,
            custom: false,
        },
    );
};

export const setSDM19TMTSetPoint = (product, temp) => {
    const { product_identifier, type } = product;

    return MiddlewareSocket.emit(
        ['fheProductSetBasicConsignTemp', 'fheProductFeedbackBasicConsignTemp'],
        {
            product_identifier,
            product_type: type,
            consign_temp: temp,
        },
    );
};
