import ListItem from 'data/components/ListItem';
import Grid from 'data/components/Grid';
import {
    isTemperatureWithMeasures,
    isTemperatureWithModes,
    isTemperatureWithSetPoint,
    isTemperatureWithSwitch,
} from '../utils/productPredicates';
import SetPoint from 'data/components/SetPoint';
import { getTemp } from 'utils';
import SelectMode from 'data/components/SelectMode';
import React from 'react';
import ProductLabel from '../ProductLabel';
import GroupInputSwitch from 'data/components/ProductsList/ProductsGroup/components/GroupInputSwitch';

export default function Temperature({ product, setOpenModal }) {
    const renderModes = () =>
        isTemperatureWithModes(product) && (
            <Grid item>
                <SelectMode product={product} />
            </Grid>
        );

    const renderMeasures = () =>
        isTemperatureWithMeasures(product) && (
            <Grid item xs={3}>
                {getTemp(product)}
            </Grid>
        );

    const renderSwitch = () =>
        isTemperatureWithSwitch(product) && (
            <Grid item xs={4}>
                <GroupInputSwitch product={product} />
            </Grid>
        );

    const renderSetPoint = () =>
        isTemperatureWithSetPoint(product) && (
            <Grid item xs={5}>
                <SetPoint product={product} />
            </Grid>
        );

    return (
        <ListItem>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={6}>
                    <ProductLabel product={product} />
                </Grid>
                <Grid item xs={6}>
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {renderMeasures()}
                        {renderModes()}
                        {renderSwitch()}
                        {renderSetPoint()}
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}
