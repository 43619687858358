import React from 'react';
import { getModuleKey } from 'utils';
import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';
import { probesLinkAdd } from 'store/products/thunks';
import InputLinkProduct from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkProduct';
import t from 'utils/Translator';

const getSlaves = (products, product) => {
    return Object.entries(products).reduce((acc, [moduleKey, relayOrWire]) => {
        if (
            getModuleKey(product) !== moduleKey &&
            relayOrWire.thermostatic_master === getModuleKey(product)
        ) {
            acc.push(moduleKey);
        }
        return acc;
    }, []);
};

export default function LinkProbe({ product }) {
    const products = useSelector(productsSelectors.selectRelaysAndWires);
    const slaves = getSlaves(products, product);
    const optionIsDisabled = (products, moduleKey, masterKey) => {
        const { thermostatic_master } = products[moduleKey];
        return thermostatic_master && thermostatic_master !== masterKey;
    };

    return (
        <InputLinkProduct
            label={t.get('inputs.linkProbes')}
            product={product}
            products={products}
            isMaster={product.is_master_thermostatic}
            slaves={slaves}
            optionIsDisabled={optionIsDisabled}
            submitFunc={probesLinkAdd}
        />
    );
}
