import {
    AreaChart,
    Area,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import React, { useEffect, useState } from 'react';

const InstantWattAreaChart = props => {
    const [lastData, setLastData] = useState([]);

    useEffect(() => {
        let newData = [...lastData, { instant: props.instantData }];
        setLastData(newData);
    }, [props.instantData]);

    return (
        <ResponsiveContainer width={props.width} height={300}>
            <AreaChart
                width={500}
                height={450}
                data={lastData}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    tickFormatter={() => {
                        return '';
                    }}
                />
                <YAxis
                    tickFormatter={value => {
                        return value;
                    }}
                />
                <Tooltip
                    labelFormatter={label => {
                        return `Données instantanées`;
                    }}
                    formatter={(value, name, props) => {
                        return value;
                    }}
                />
                <Area
                    type="monotone"
                    dataKey="instant"
                    unit={' W'}
                    stroke="#00988E"
                    fill="#00988E"
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default InstantWattAreaChart;
