import Modal from 'data/components/Modals/Modal';
import MessageCard from 'data/components/MessageCard';
import React, { useEffect, useState } from 'react';
import useModal from 'utils/hooks/useModal';
import moment from 'moment';
import { getFromLS, setToLS } from 'utils/storage';
import Message from 'data/messages/api';

const Messages = props => {
    const [isShowingMessage, toggleModal] = useModal();
    const [message, setMessage] = useState([]);

    const getMessages = async () => {
        return await Message.getLastMessage();
    };

    const isMessageStored = message => {
        let storedMessages = getFromLS('messages') || [];

        if (storedMessages.includes(message.date_debut)) {
            return true;
        } else {
            storedMessages.push(message.date_debut);
            setToLS('messages', storedMessages);

            return false;
        }
    };

    const isMessageOutdated = message => {
        let dateDebut = moment(message.date_debut).format('MM/DD/YYYY');
        let dateFin = moment(message.date_fin).format('MM/DD/YYYY');
        let currentDate = moment().format('MM/DD/YYYY');

        if (moment(currentDate).isBetween(dateDebut, dateFin)) {
            setMessage(message);
            return false;
        } else {
            return true;
        }
    };

    const toggleMessage = message => {
        !isMessageStored(message) &&
            !isMessageOutdated(message) &&
            toggleModal();
    };

    useEffect(() => {
        const getLastMessage = async () => {
            return await getMessages();
        };

        getLastMessage().then(message => toggleMessage(message));
    }, []);

    return (
        <Modal
            open={isShowingMessage}
            onClose={toggleModal}
            title={message ? message.titre : 'Message'}
        >
            <MessageCard message={message} hide={toggleModal} />
        </Modal>
    );
};

export default Messages;
