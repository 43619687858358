import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    color: '#fff',
    height: 30,
    width: 30,
};

function RightArrow(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill={props.color}
            viewBox="0 0 401.948 401.949"
            {...props}
        >
            <path d="M397 190.734L228.738 73.444c-6.599-5.632-11.996-3.147-11.996 5.528v49.068c0 8.672-7.099 15.77-15.77 15.77l-104.176.156H15.69v.125C7.051 144.139.002 151.214 0 159.857l.002 82.789c0 8.673 7.095 15.771 15.765 15.771l183.426-.275h1.834c8.647.028 15.717 7.107 15.717 15.765v49.067c0 8.675 5.397 11.163 11.993 5.535l168.265-117.294c6.596-5.636 6.596-14.848-.002-20.481z" />
        </svg>
    );
}

RightArrow.propTypes = propTypes;
RightArrow.defaultProps = defaultProps;

export default RightArrow;
