import React from 'react';
import InputWrapper from '../InputWrapper';
import Grid from 'data/components/Grid';
import InputSwitch from 'data/components/InputSwitch';
import t from 'utils/Translator';

export default function InputExcludeRT2012({ formik }) {
    return (
        <InputWrapper>
            <Grid
                container
                alignItems="flex-end"
                justifyContent="space-between"
            >
                <div>Exclude RT2012</div>
                <InputSwitch
                    name="stats_rt2012_excluded"
                    label={t.get('inputs.RT2012Excluded')}
                    onChange={formik.handleChange}
                    value={formik.values.stats_rt2012_excluded}
                    checked={formik.values.stats_rt2012_excluded}
                />
            </Grid>
        </InputWrapper>
    );
}
