const axios = require('axios');
const isPlainObject = require('./isPlainObject');
const deepMerge = require('./deepMerge');

class BaseHttp {
    constructor(defaultOptions = {}) {
        this.axios = axios;
        this.headers = {};
        this.defaultOptions = defaultOptions;
    }

    setRootUrl(url) {
        this.rootUrl = url;
    }

    setHeader(name, value) {
        this.headers[name] = value;
    }

    clearHeaders() {
        this.headers = {};
    }

    clearHeader(name) {
        delete this.headers[name];
    }

    get(uriOrUriParams, queryParams, userOptions = {}) {
        // On insère les données passée dans l'objet config à passer axios
        if (isPlainObject(queryParams)) {
            userOptions.params = isPlainObject(userOptions.params)
                ? { ...userOptions.params, ...queryParams }
                : { ...queryParams };
        }

        return this.axios
            .get(
                this.getURl(uriOrUriParams),
                this._getHttpClientOptions(userOptions),
            )
            .then(this._parseResponse)
            .catch(this._parseError);
    }

    post(uriOrUriParams, data, options = {}) {
        return this.axios
            .post(
                this.getURl(uriOrUriParams),
                data ? this._formatData(data) : null,
                this._getHttpClientOptions(options),
            )
            .then(this._parseResponse)
            .catch(this._parseError);
    }

    put(uriOrUriParams, data = {}, options = {}) {
        return this.axios
            .put(
                this.getURl(uriOrUriParams),
                data,
                this._getHttpClientOptions(options),
            )
            .then(this._parseResponse)
            .catch(this._parseError);
    }

    delete(uriOrUriParams, data = {}, options = {}) {
        return this.axios
            .delete(
                this.getURl(uriOrUriParams),
                this._getHttpClientOptions({
                    data,
                    ...options,
                }),
            )
            .then(this._parseResponse)
            .catch(this._parseError);
    }

    getURl(uriOrUriParams) {
        if (typeof uriOrUriParams === 'string') {
            return this.rootUrl + uriOrUriParams;
        }

        const [uri, params] = uriOrUriParams;

        return Object.keys(params).reduce(
            (url, key) =>
                url.replace(new RegExp(`\\:${key}`, 'g'), params[key]),
            this.rootUrl + uri,
        );
    }

    _formatData(data) {
        return Object.keys(data).reduce((formData, key) => {
            const fieldData = data[key];

            if (
                fieldData !== null &&
                (isPlainObject(fieldData[0]) || Array.isArray(fieldData[0]))
            ) {
                formData.append(key, JSON.stringify(fieldData));
            } else if (fieldData !== null && Array.isArray(fieldData)) {
                fieldData.map(field => formData.append(`${key}[]`, field));
            } else if (fieldData !== null && isPlainObject(fieldData)) {
                Object.keys(fieldData).map(fieldName =>
                    formData.append(
                        `${key}[${fieldName}]`,
                        fieldData[fieldName],
                    ),
                );
            } else {
                formData.append(key, fieldData);
            }

            return formData;
        }, new FormData());
    }

    _parseResponse(resp) {
        return resp.data || {};
    }

    _parseError(e) {
        throw e.response ? e.response.data.errors[0] : e;
    }

    _getHttpClientOptions(userOptions = {}) {
        const options = deepMerge(this.defaultOptions, {
            ...userOptions,
            headers: this.headers,
            params: {},
        });

        if (isPlainObject(userOptions.params)) {
            options.params = {
                ...options.params,
                ...userOptions.params,
            };
        }

        return options;
    }
}

export default BaseHttp;
