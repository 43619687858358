import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Grid from 'data/components/Grid';
import { makeStyles } from '@mui/styles';
import PrimaryInputs from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/PrimaryInputs';
import { useSelector } from 'react-redux';
import SecondaryInputs from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/SecondaryInputs';
import LinkInputs from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/LinkInputs';
import Loader from 'navigation/screens/Login/components/Loader';

export default function SettingsTab({ product, setMaxWidth }) {
    const { loading } = useSelector(state => state.products);
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            label: product.label || '',
            brand: product.brand || '',
            model: product.model || '',
            year: product.year || '',
            power: product.power || '',
            security_state: product.security_state || 'OFF',
            module_type: product.module_type || '',
            idle_toggle: product.idle_toggle,
            idle_time: product.idle_time || '',
            idle_power: product.idle_power || '',
            load_time: product.load_time || '',
            max_autonomy: product.max_autonomy || '',
            line_type_diversity: product.line_type_diversity,
            stats_rt2012_excluded: product.stats_rt2012_excluded || '',
            remote_ref: product.remote_ref || '',
            operating_mode: product.operating_mode || '',
            threshold: product.threshold || '',
            reverse_polarity: product.reverse_polarity || '',
            fhe_product_mode: product.fhe_product_mode || '',
            type_diversity: product.type_diversity || '',
            remote: {},
        },
        // validationSchema: validationSchema,
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    useEffect(() => {
        setMaxWidth('sm');
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Loader loading={loading} />
            <Grid container className={classes.container}>
                <PrimaryInputs product={product} formik={formik} />
                <SecondaryInputs product={product} formik={formik} />
                <LinkInputs product={product} formik={formik} />
            </Grid>
        </form>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        '& > div': {
            marginBottom: 4,
        },
    },
}));
