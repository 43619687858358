import React from 'react';
import { getModuleKey } from 'utils';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';
import { clampsLinkAdd } from 'store/products/thunks';
import InputLinkProduct from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkProduct';
import t from 'utils/Translator';

const getSlaves = (products, product) => {
    return Object.entries(products).reduce((acc, [moduleKey, clamp]) => {
        if (
            getModuleKey(product) !== moduleKey &&
            clamp.clamp_master === getModuleKey(product)
        ) {
            acc.push(moduleKey);
        }
        return acc;
    }, []);
};

export default function LinkClamp({ product }) {
    const products = _.omit(useSelector(productsSelectors.selectClamps), [
        getModuleKey(product),
    ]);
    const slaves = getSlaves(products, product);
    const optionIsDisabled = (products, moduleKey, masterKey) => {
        const { clamp_master } = products[moduleKey];
        return clamp_master && clamp_master !== masterKey;
    };

    return (
        <InputLinkProduct
            label={t.get('inputs.linkClamps')}
            product={product}
            products={products}
            isMaster={product.is_master_clamp}
            slaves={slaves}
            optionIsDisabled={optionIsDisabled}
            submitFunc={clampsLinkAdd}
        />
    );
}
