import Http from '../../utils/Http';
import EmailValidationRequiredError from '../../utils/errors/EmailValidationRequiredError';
import MustResetPasswordError from '../../utils/errors/MustResetPasswordError';
import InvalidCredentialsError from '../../utils/errors/InvalidCredentialsError';

const api = {
    login: ({ email, password }) =>
        Http.post('auth', {
            email,
            password,
        })
            .then(res => {
                if (res.account_blocked) {
                    console.log('blocked');
                }

                if (res.must_validate_email) {
                    throw new EmailValidationRequiredError();
                }

                if (res.password_recovery) {
                    throw new MustResetPasswordError(res.password_recovery);
                }

                return {
                    accessToken: res.access_token,
                };
            })
            .catch(e => {
                if (
                    [
                        'Invalid credentials provided',
                        'Incorrect user credentials provided',
                    ].includes(e.message)
                ) {
                    throw new InvalidCredentialsError();
                }

                throw e;
            }),
    loginWithProvider: (code, provider) => {
        return Http.post('auth-provider', {
            provider_auth_code: code,
            provider_type: provider,
        }).then(res => {
            if (res.account_blocked) {
                console.log('blocked');
            }

            console.log('loginByProvider', res);

            return {
                accessToken: res.access_token,
            };
        });
    },

    init: () => {
        return Http.get('initialize')
            .then(res => {
                console.log(res);
                return res;
            })
            .catch(e => {
                console.error(e);
                throw e;
            });
    },

    refreshToken: accessToken =>
        Http.get('refresh-token').then(res => res.access_token.access_token),

    recoverPassword: email =>
        Http.post('user/password-recovery', { email }).then(res => {
            return res;
        }),

    setNewPassword: (email, recoveryPassword, newPassword) =>
        Http.post('recover-auth', {
            email,
            password_recover: recoveryPassword,
            new_password: newPassword,
        }).then(res => ({
            profile: res.profile,
            accessToken: res.access_token,
            expiresIn: res.expire_in,
        })),

    update: (userId, user) =>
        Http.put('settings/update', {
            user_id: userId,
            user: {
                firstname: user.firstname || '',
                lastname: user.lastname || '',
                tel_fixe: user.tel_fixe || '',
                tel_port: user.tel_port || '',
                locale: user.locale || 'fr',
                email: user.email,
            },
        }).then(resp => resp.profile),
};

export default api;
