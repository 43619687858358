import React, { useState } from 'react';
import SidePanel from 'data/components/SidePanel';
import ProductsFilter from './components/ProductsFilter';
import { useSelector } from 'react-redux';
import { productsSelectors } from 'store/products/selectors';
import ProductsListContainer from 'data/components/ProductsListContainer';
import { selectBoxStatus } from '../../../store/box/boxSlice';

export default function Products({ sidePanelContainerRef, headerExpanded }) {
    const [sidePanelIsOpen, setSidePanelIsOpen] = useState(true);
    const boxStatus = useSelector(selectBoxStatus);
    const [filter, setFilter] = useState('all');

    const allProducts = useSelector(
        productsSelectors.selectProductsByGroups,
        () => true,
    );
    const productByTypes = useSelector(
        productsSelectors.selectProductsByTypes,
        () => true,
    );
    const productByCategories = useSelector(
        productsSelectors.selectProductsByCategories,
        () => true,
    );

    let filteredGroups = [];
    if (filter === 'all') filteredGroups = allProducts;
    if (filter === 'types') filteredGroups = productByTypes;
    if (filter === 'categories') filteredGroups = productByCategories;

    return (
        <>
            {boxStatus !== 'connected' && (
                <div
                    style={{
                        zIndex: 100,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'white',
                        opacity: 0.7,
                        width: '100%',
                        height: '100%',
                    }}
                />
            )}

            <ProductsListContainer
                filteredGroups={filteredGroups}
                sidePanelIsOpen={sidePanelIsOpen}
                setSidePanelIsOpen={setSidePanelIsOpen}
                sidePanelContainerRef={sidePanelContainerRef}
                headerExpanded={headerExpanded}
                filter={filter}
            />
            {boxStatus === 'connected' && (
                <SidePanel
                    sidePanelContainerRef={sidePanelContainerRef}
                    sidePanelIsOpen={sidePanelIsOpen}
                    setSidePanelIsOpen={setSidePanelIsOpen}
                >
                    <ProductsFilter
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                    />
                </SidePanel>
            )}
        </>
    );
}
