import React from 'react';
import { makeStyles } from '@mui/styles';
import ShuttersIcon from 'assets/svgs/ShuttersIcon';
import t from 'utils/Translator';
import SwitchesIcon from 'assets/svgs/SwitchesIcon';
import LightingIcon from 'assets/svgs/LightingIcon';
import TemperaturesIcon from 'assets/svgs/TemperaturesIcon';
import SecuritiesIcon from 'assets/svgs/SecuritiesIcon';
import MeasuresIcon from 'assets/svgs/MeasuresIcon';
import Text from 'data/components/Text';
import InelioIcon from '../../../../../assets/svgs/InelioIcon';

export default function GroupTitle(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Text>
                {props.filter !== 'types'
                    ? t.get(groups[props.group]?.title).toUpperCase()
                    : props.group.toUpperCase()}
            </Text>
            {groups[props.group]?.icon || null}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.common.white,
        borderRadius: '3px 3px 0 0',
        alignItems: 'center',
    },
}));

const fontSize = 'medium';
const groups = {
    shutters: {
        title: 'products.groups.shutters',
        icon: <ShuttersIcon fontSize={fontSize} height={30} width={30} />,
    },
    switches: {
        title: 'products.groups.switches',
        icon: <SwitchesIcon fontSize={fontSize} height={30} width={30} />,
    },
    lighting: {
        title: 'products.groups.lighting',
        icon: <LightingIcon fontSize={fontSize} height={30} width={30} />,
    },
    temperatures: {
        title: 'products.groups.temperatures',
        icon: <TemperaturesIcon fontSize={fontSize} height={30} width={30} />,
    },
    securities: {
        title: 'products.groups.securities',
        icon: <SecuritiesIcon fontSize={fontSize} height={30} width={30} />,
    },
    measures: {
        title: 'products.groups.measures',
        icon: <MeasuresIcon fontSize={fontSize} height={30} width={30} />,
    },
    inelio: {
        title: 'products.groups.inelio',
        icon: <InelioIcon fontSize={fontSize} height={30} width={30} />,
    },
};
