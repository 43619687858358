import * as React from 'react';

const PowerIcon = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 238.126 238.126"
        style={{
            enableBackground: 'new 0 0 238.126 238.126',
        }}
        xmlSpace="preserve"
        width={props.width}
        height={props.height}
        {...props}
    >
        <path
            fill={props.color}
            d="M205.071 98.678a7.498 7.498 0 0 0-6.869-4.49h-62.33l30.023-84.167a7.501 7.501 0 0 0-12.579-7.605L34.411 131.355a7.498 7.498 0 0 0-1.355 8.094 7.498 7.498 0 0 0 6.869 4.49h40.521c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5H57.043l82.365-89.315-21.24 59.543c-.818 2.297-.432 4.849.974 6.842 1.405 1.993 3.73 3.178 6.169 3.178h55.772L98.73 198.492l21.243-59.533a7.501 7.501 0 0 0-7.064-10.021h-.074c-3.525 0-6.455 2.432-7.25 5.709l-33.348 93.456a7.5 7.5 0 0 0 12.578 7.605L203.714 106.77a7.495 7.495 0 0 0 1.357-8.092z"
        />
    </svg>
);

export default PowerIcon;
