import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'navigation/components/PrivateRoute';
import { BrowserRouter as Router } from 'react-router-dom';
import Login from '../screens/Login';
import Dashboard from '../screens/Dashboard';
import Products from '../screens/Products';
import Stats from '../screens/Stats';
import Plannings from '../screens/Plannings';
import Weathers from '../screens/Weathers';
import Account from '../screens/Account';
import Support from '../screens/Support';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/login" component={Login} />

                <PrivateRoute path="/products" component={Products} />
                <PrivateRoute path="/stats" component={Stats} />
                <PrivateRoute path="/plannings" component={Plannings} />

                <PrivateRoute path="/weather" component={Weathers} />
                <PrivateRoute path="/account" component={Account} />
                <PrivateRoute path="/support" component={Support} />
                <PrivateRoute
                    path={['/dashboard', '/']}
                    component={Dashboard}
                />
            </Switch>
        </Router>
    );
};

export default Routes;
