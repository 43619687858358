import FormControl from '../FormControl';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../Button';

const GeneratorModalForm = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const initialValues = {
        orientation: props.generator.orientation || '',
        module_type: props.generator.module_type || '',
        power: props.generator.power || '',
        roof_pitch: props.generator.roof_pitch || '',
    };

    const validationSchema = Yup.object({
        orientation: Yup.string(),
        module_type: Yup.string(),
        power: Yup.string(),
        roof_pitch: Yup.string(),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            props.create(values);
            props.closeFunction();
        },
    });

    const handleSubmit = async values => {
        setLoading(true);
        await props.create({
            ...values,
        });
        setLoading(false);
        props.close();
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
            }}
        >
            <FormControl className={classes.form}>
                <TextField
                    fullWidth
                    id="orientation"
                    name="orientation"
                    label={'Orientation'}
                    value={formik.values.orientation}
                    onChange={formik.handleChange}
                    className={classes.input}
                />
                <TextField
                    fullWidth
                    id="module_type"
                    name="module_type"
                    label={'Ventilation'}
                    value={formik.values.module_type}
                    onChange={formik.handleChange}
                    className={classes.input}
                />
                <TextField
                    fullWidth
                    id="power"
                    name="power"
                    label={'Puissance'}
                    value={formik.values.power}
                    onChange={formik.handleChange}
                    className={classes.input}
                />
                <TextField
                    fullWidth
                    id="roof_pitch"
                    name="roof_pitch"
                    label={'Inclinaison'}
                    value={formik.values.roof_pitch}
                    onChange={formik.handleChange}
                    className={classes.input}
                />
                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                    className={classes.submitButton}
                    onClick={() => handleSubmit(formik.values)}
                    loading={loading}
                >
                    Enregistrer
                </Button>
            </FormControl>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        width: 700,
        padding: '35px 50px 50px 50px',
    },
    input: {
        margin: '22px 0 0 0',
    },
    submitButton: {
        margin: '40px 0',
    },
}));

export default GeneratorModalForm;
