import { makeStyles } from '@mui/styles';
import React from 'react';
import LeafIcon from 'assets/svgs/Leaf';
import Text from 'data/components/Text';
import Grid from 'data/components/Grid';
import { useSelector } from 'react-redux';
import Home from 'data/components/Scheme/Home';
import Panel from 'data/components/Scheme/Panel';
import { withTooltip } from 'utils';
import { selectGeneratorsIds } from 'store/generators/generatorSlice';

export default function ConsumptionHeader(props) {
    const classes = useStyles();
    const generators = useSelector(selectGeneratorsIds);
    const co2 = Math.round(props.homeNetworkStats.prodPV * 0.18 * 100) / 100;

    return (
        !props.isExpanded && (
            <Grid container alignItems="center" className={classes.container}>
                <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.co2}
                >
                    {generators.length >= 1 &&
                        withTooltip(
                            <div className={classes.statsContainer}>
                                <div>
                                    <LeafIcon
                                        color={'#4CAF50'}
                                        height={40}
                                        width={40}
                                    />
                                </div>
                                <Text
                                    color="secondary"
                                    variant="h6"
                                    className={classes.stats}
                                >
                                    CO₂ : {co2} kg
                                </Text>
                            </div>,
                            'CO₂ économisé',
                        )}
                </Grid>

                <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.consumptionDetails}
                >
                    {withTooltip(
                        <div
                            style={
                                generators.length >= 1
                                    ? { display: 'flex', flexDirection: 'row' }
                                    : {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          position: 'absolute',
                                          right: 100,
                                      }
                            }
                        >
                            <Home color={'#fff'} width={30} height={30} />
                            <Text
                                color="secondary"
                                variant="h6"
                                className={classes.stats}
                            >
                                {props.homeNetworkStats.consoFoyer} kWh
                            </Text>
                        </div>,
                        'Consommation Foyer',
                    )}
                    {generators.length >= 1 &&
                        withTooltip(
                            <div className={classes.statsContainer}>
                                <Panel width={30} height={30} color={'#fff'} />
                                <Text
                                    color="secondary"
                                    variant="h6"
                                    className={classes.stats}
                                >
                                    {props.homeNetworkStats.prodPV} kWh
                                </Text>
                            </div>,
                            'Production PV',
                        )}
                    {generators.length >= 1 &&
                        withTooltip(
                            <div className={classes.statsContainer}>
                                <Text
                                    color="secondary"
                                    variant="h6"
                                    className={classes.stats}
                                >
                                    {props.homeNetworkStats.autoConsoRate} %
                                </Text>
                            </div>,
                            'Autoconsommation',
                        )}
                </Grid>

                {props.isExpanded && 'expanded'}
            </Grid>
        )
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        color: theme.palette.common.white,
        width: '100%',
        display: 'flex',
        marginRight: 12,
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
    },
    co2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 200,
    },
    consumptionDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 350,
    },
    stats: {
        alignSelf: 'center',
        marginLeft: 4,
        marginRight: 4,
        color: '#fff',
    },
    statsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
}));
