import React from 'react';
import LinkClamp from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkClamp/components/LinkClamp';
import UnlinkClamp from 'data/components/ProductModal/components/ProductModalTabs/SettingsTab/components/inputs/InputLinkClamp/components/UnlinkClamp';

export default function InputLinkClamp({ product }) {
    const { is_master_clamp, clamp_master } = product;

    const canSelect = () => {
        return is_master_clamp || !clamp_master;
    };

    return (
        <>
            {canSelect() ? (
                <LinkClamp product={product} />
            ) : (
                <UnlinkClamp product={product} />
            )}
        </>
    );
}
