import Grid from 'data/components/Grid';
import Paper from 'data/components/Paper';
import AccordionWeathers from 'data/components/AccordionWeathers';
import Text from 'data/components/Text';
import { capitalize, getDate } from 'utils';
import TableWeathers from 'data/components/Table';
import React from 'react';
import CurrentTemperature from './CurrentTemperature';
import Sun from './Sun';
import { makeStyles } from '@mui/styles';

export default function Weather({ weather, index }) {
    const classes = useStyle();
    const isCurrentDay = index === 0;
    return (
        <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={5}>
                <AccordionWeathers
                    defaultExpanded={isCurrentDay}
                    header={
                        <Grid container>
                            <Grid item xs={6}>
                                <Text noWrap className={classes.date}>
                                    {isCurrentDay
                                        ? capitalize(getDate(weather.date))
                                        : capitalize(
                                              getDate(weather.date, 'dddd'),
                                          )}
                                </Text>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                xs={6}
                            >
                                <Text>{`${weather.mintempC}°C - ${weather.maxtempC}°C`}</Text>
                            </Grid>
                        </Grid>
                    }
                    content={
                        <Grid container>
                            <Sun astronomy={weather.astronomy[0]} />
                            {isCurrentDay && <CurrentTemperature />}
                            <TableWeathers
                                weather={weather}
                                isCurrentDay={isCurrentDay}
                            />
                        </Grid>
                    }
                />
            </Paper>
        </Grid>
    );
}

const useStyle = makeStyles({
    date: {
        fontSize: 20,
    },
});
