import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@mui/styles';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: 'white',
    height: '100%',
    width: '100%',
};

function SecuritiesIcon(props) {
    const StyledSvgIcon = withStyles(theme => ({
        root: {
            height: props.height,
            width: props.width,
        },
    }))(SvgIcon);

    return (
        <StyledSvgIcon
            viewBox="0 0 193.03 300"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.color}
                d="M183.88,154.15H8.65a5.22,5.22,0,0,0-5.21,5.22V260.3a5.22,5.22,0,0,0,5.21,5.21H183.88a5.22,5.22,0,0,0,5.21-5.21V159.37A5.22,5.22,0,0,0,183.88,154.15Zm-58.9,41c3.87,4,13.2,13.64,13.63,14.1a20.27,20.27,0,0,1,1.45,1.69,5.7,5.7,0,0,1,.74,5.85,5.79,5.79,0,0,1-4.72,3.59,7,7,0,0,1-1.28,0h-4.88s0,24.09,0,27.32a2.6,2.6,0,0,1-.81,2c-.59.55-1.71,1.7-1.71,1.7a4.69,4.69,0,0,1-1.92.82h0l-43.2,0q-7.05,0-14.1.05h0a5.8,5.8,0,0,1-2.58-1.12l-1.51-1.52a2.36,2.36,0,0,1-.72-1.75c0-4.95,0-27.44,0-27.44H58.93a10.78,10.78,0,0,1-2.18-.1A5.61,5.61,0,0,1,52,215.77a5.75,5.75,0,0,1,2.21-6.05c.32-.25,38.24-39.42,38.24-39.42a3,3,0,0,1,2.28-1h3.92a3.07,3.07,0,0,1,2.28,1l9.06,9.38c.33-.28.54-1.21,2.1-1.21h9a2.19,2.19,0,0,1,1.65.71c.5.55.94,1,1.36,1.37a2.28,2.28,0,0,1,.79,1.83C124.83,184.75,124.92,194.53,125,195.12Z"
            />
            <path
                fill={props.color}
                d="M119.15,203.6A9.33,9.33,0,0,1,119,202c0-2.2,0-10.66,0-14.39a1.27,1.27,0,0,0-1.25-1.27l-3.34,0a1.28,1.28,0,0,0-1.28,1.28c0,1.29,0,3.16,0,3.72,0,.72-.8,1-1.24,1l-3.21,0a2,2,0,0,1-1.5-.62C104.05,188.51,97,181.35,96.6,181L68.11,210.45H70a1.76,1.76,0,0,1,1.18.46c.57.54,1.12,1.1,1.67,1.67a1.72,1.72,0,0,1,.47,1.06c0,.68,0,22.87.06,27.68a.91.91,0,0,0,.91.91h44.79a.92.92,0,0,0,.92-.91c0-4,0-20.31,0-27.48a1.74,1.74,0,0,1,.53-1.32c.5-.51,1-1,1.51-1.52a1.81,1.81,0,0,1,1.34-.57h1.85s-2.82-3.06-5.56-5.9A1.83,1.83,0,0,1,119.15,203.6ZM104.84,218a2,2,0,0,1-2.22-.13s-.56-.5-.74-.45-.28.55-.28.82c0,1.45,0,2.9,0,4.35v11.3c0,.65,0,1.3,0,1.94a2.72,2.72,0,0,1-3.09,2.46,2.92,2.92,0,0,1-1.06-.61c-.28-.19-.5-.6-.76-.62s-.48.38-.7.62a2.1,2.1,0,0,1-2.75.35,2.93,2.93,0,0,1-1.42-2,6,6,0,0,1-.07-1c0-4.95,0-9.9,0-14.86,0-.56,0-1.13,0-1.69a1.07,1.07,0,0,0,0-.4c-.07-.2-.14-.51-.29-.56s-.35.13-.53.23a4.19,4.19,0,0,1-.87.49,1.91,1.91,0,0,1-2.39-1.1,3.12,3.12,0,0,1-.41-1.69c0-3,0-6,0-9.05,0-.62,0-1.24,0-1.85a4.15,4.15,0,0,1,3.88-3.56c.83,0,1.65,0,2.48.05a3.76,3.76,0,0,0,.75,0c.4-.06.49-.24.28-.6a2.35,2.35,0,0,0-.45-.54,4.79,4.79,0,0,1-1.7-3,4.22,4.22,0,0,1,3.52-4.59,4.17,4.17,0,0,1,4.71,4,3.82,3.82,0,0,1-1.35,3.13,6.72,6.72,0,0,0-.86.91c-.26.37-.14.62.3.64s1.08,0,1.61-.06a5.57,5.57,0,0,1,3.41.57,4,4,0,0,1,2.11,3.57c0,2.39,0,4.79,0,7.19,0,1.1,0,2.21.06,3.31A3,3,0,0,1,104.84,218Z"
            />
            <rect
                fill={props.color}
                y="271.2"
                width="193.03"
                height="28.8"
                rx="5.21"
            />
            <path
                fill={props.color}
                d="M187.82,116.2H172.68V70.65C172.6,31.7,138.35,0,96.35,0,46.34,0,20.08,37.92,20,75.39V116.2H5.21A5.21,5.21,0,0,0,0,121.41v21.14a5.22,5.22,0,0,0,5.21,5.21H187.82a5.21,5.21,0,0,0,5.21-5.21V121.41A5.21,5.21,0,0,0,187.82,116.2Zm-61.16,0H65.35V97.72c0-23.93,0-23.93,0-24.06A31.86,31.86,0,0,1,97,44.55c15.32,0,31.71,12.59,31.71,31.34V116.2Z"
            />
        </StyledSvgIcon>
    );
}

SecuritiesIcon.propTypes = propTypes;
SecuritiesIcon.defaultProps = defaultProps;

export default SecuritiesIcon;
