import MiddlewareSocket from 'MiddlewareSocket/index';

const FHE_PRODUCT = 'fhe_product';

export const setProductLabel = (product, form) => {
    return MiddlewareSocket.emit('fheProductSetLabel ', {
        product_identifier: product.product_identifier,
        product_type: product.type,
        code: product.code,
        label: form.label,
    });
};

export const setProductThreshold = (product, form) => {
    return MiddlewareSocket.emit(
        ['setProductModuleThreshold', 'productModuleThresholdFeedback'],
        {
            product_identifier: product.product_identifier,
            module_type: product.type,
            code: product.code,
            product_type: FHE_PRODUCT,
            threshold: form.threshold_calibrating,
        },
    );
};

export const setProductPhase = (product, form) => {
    return MiddlewareSocket.emit(
        ['fheProductSetMode ', 'fheProductModeFeedback'],
        {
            product_identifier: product.product_identifier,
            module_type: product.type,
            code: product.code,
            fhe_product_mode: form.fhe_product_mode,
        },
    );
};

export const setProductConversionRate = (product, form) => {
    return MiddlewareSocket.emit(
        [
            'fheProductFlowmeterConversion',
            'fheProductFeebackFlowmeterConversion',
        ],
        {
            product_identifier: product.product_identifier,
            fm1_rate: form.threshold,
            fm2_rate: form.threshold,
        },
    );
};

export const setProductPolarity = (product, form) => {
    return MiddlewareSocket.emit(
        ['fheProductSetInversion', 'submitReversePolarityFeedback'],
        {
            product_identifier: product.product_identifier,
            inversion: form.reverse_polarity,
        },
    );
};

// /!\ Heat&Cool  /!\
export const setProductRemote = (product, form) => {
    return MiddlewareSocket.emit('fheProductSwitchRemote', {
        product_identifier: product.product_identifier,
        product_type: product.type,
        custom: form?.remote?.custom,
        remote: form?.remote?.ref,
        remote_brand: form?.remote?.brand,
    });
};

export const setProductOperatingMode = (product, form) => {
    return MiddlewareSocket.emit(
        ['fheProductSetOperatingMode', 'fheProductFeedbackOperatingMode'],
        {
            product_identifier: product.product_identifier,
            product_type: product.type,
            operating_mode: form.operating_mode,
        },
    );
};

export const setProductDeltaT = (product, form) => {
    return MiddlewareSocket.emit(
        ['fheProductSetDeltaT', 'fheProductSetDeltaT'],
        {
            product_identifier: product.product_identifier,
            product_type: product.type,
            deltaT: form.threshold,
        },
    );
};

export const updateDS18B20Group = product => {
    return MiddlewareSocket.emit(
        [
            'fheProductUpdateDS18B20Group',
            'fheProductFeedbackUpdateDS18B20Group',
        ],
        {
            consignTemp: product.consignTemp,
            fhePlugOrigin: product.fhePlugOrigin,
            product_identifiers: [product.identifier],
            product_types: [product.type],
        },
    );
};
