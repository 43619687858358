/**
 * Prend un nombre variable d'objets en argument, et renvoie un nouvel objet où toutes les propriétés ont été combinées
 *
 * Volé ici: https://vanillajstoolkit.com/helpers/deepmerge/
 *
 * @param {Object} objs     Objets à fusionner
 *
 * @return {Object} Nouvel objet
 */
const deepMerge = (...objs) => {
    const merged = {};

    const merge = obj => {
        for (const prop in obj) {
            if (obj[prop] !== undefined) {
                if (
                    Object.prototype.toString.call(obj[prop]) ===
                    '[object Object]'
                ) {
                    merged[prop] = deepMerge(merged[prop], obj[prop]);
                } else {
                    merged[prop] = obj[prop];
                }
            }
        }
    };

    let i = 0;

    for (i; i < objs.length; i++) {
        merge(objs[i]);
    }

    return merged;
};

module.exports = deepMerge;
