// types
export const SDM17DEC = 'SDM17DEC';
export const SDM17SPG = 'SDM17SPG';
export const SDM18HCL = 'SDM18HCL';
export const SDM18HCN = 'SDM18HCN';
export const SDM18MVR = 'SDM18MVR';
export const SDM22MVR = 'SDM22MVR';
export const SDM18SP2 = 'SDM18SP2';
export const SDM19DAF = 'SDM19DAF';
export const SDM19DEE = 'SDM19DEE';
export const SDM19DRS = 'SDM19DRS';
export const SDM19PIR = 'SDM19PIR';
export const SDM19SG2 = 'SDM19SG2';
export const SDM19STM = 'SDM19STM';
export const SDM19TMT = 'SDM19TMT';
export const SDM19INL = 'SDM19INL';
export const SDM19WTS = 'SDM19WTS';
export const SDM20LHT = 'SDM20LHT';
export const SDM22LHT = 'SDM22LHT';

// product_type
export const AIRZONE = 'airzone';

// line_types
export const FLOWMETER = 'flowmeter';
export const CLAMP = 'clamp';
export const RELAY = 'relay';
export const PILOTWIRE = 'pilotwire';
export const PROBE = 'probe';

// module_types
export const SOCKET = 'socket';
export const LIGHTING = 'lighting';
export const ECS = 'ecs';
export const THERMOSTAT = 'thermostat';

// operating_modes
export const SPLIT = 'split';

// Switches
export const isSwitch = product =>
    isSmartPlug_switch(product) ||
    isRelay_switch(product) ||
    isRelayDE_switch(product);

export const isSmartPlug_switch = ({ type, module_type }) =>
    [SDM17SPG, SDM19SG2].includes(type) && [SOCKET, ECS].includes(module_type);

export const isRelay_switch = ({ type, module_type, line_type }) =>
    [SDM18HCN, SDM18SP2].includes(type) &&
    [SOCKET, ECS].includes(module_type) &&
    line_type === RELAY;

export const isRelayDE_switch = ({ type, module_type, line_type }) =>
    [SDM19DEE, SDM17DEC].includes(type) &&
    [SOCKET, ECS].includes(module_type) &&
    line_type === RELAY;

// Temperatures
export const isTemperature = product =>
    isSmartPlug_temperature(product) ||
    isRelay_temperature(product) ||
    isRelayDE_temperature(product) ||
    isPilotWire_temperature(product) ||
    isProbe_temperature(product) ||
    isHeatAndCool_temperature(product) ||
    isThermostat_temperature(product) ||
    isAirzone_temperature(product);

export const isSmartPlug_temperature = ({ type, module_type }) =>
    [SDM17SPG, SDM19SG2].includes(type) && [THERMOSTAT].includes(module_type);

export const isRelay_temperature = ({ type, module_type, line_type }) =>
    [SDM18HCN, SDM18SP2].includes(type) &&
    [THERMOSTAT].includes(module_type) &&
    line_type === RELAY;

export const isRelayDE_temperature = ({ type, module_type, line_type }) =>
    [SDM19DEE, SDM17DEC].includes(type) &&
    [THERMOSTAT].includes(module_type) &&
    line_type === RELAY;

export const isPilotWire_temperature = ({ type, line_type }) =>
    [SDM18HCN, SDM17DEC].includes(type) && line_type === PILOTWIRE;

export const isProbe_temperature = ({ type, line_type }) =>
    [SDM18HCN, SDM18SP2, SDM19DEE, SDM19STM, SDM17DEC].includes(type) &&
    line_type === PROBE;

export const isHeatAndCool_temperature = ({ type, operating_mode }) =>
    type === SDM18HCL && operating_mode === SPLIT;

export const isThermostat_temperature = ({ type }) => type === SDM19TMT;

export const isAirzone_temperature = ({ product_type }) =>
    product_type === AIRZONE;

// Lighting
export const isLighting = product =>
    isLightModule_lighting(product) ||
    isSmartPlug_lighting(product) ||
    isRelay_lighting(product) ||
    isRelayDE_lighting(product);

export const isLightModule_lighting = ({ type }) =>
    [SDM20LHT, SDM22LHT].includes(type);

export const isSmartPlug_lighting = ({ type, module_type }) =>
    [SDM17SPG, SDM19SG2].includes(type) && module_type === LIGHTING;

export const isRelay_lighting = ({ type, module_type, line_type }) =>
    [SDM18HCN, SDM18SP2, SDM19STM].includes(type) &&
    module_type === LIGHTING &&
    line_type === RELAY;

export const isRelayDE_lighting = ({ type, module_type, line_type }) =>
    [SDM19DEE, SDM17DEC].includes(type) &&
    module_type === LIGHTING &&
    line_type === RELAY;

// Measures
export const isMeasure = product =>
    isClamp_measures(product) || isFlowMeter_measures(product);

export const isClamp_measures = ({ type, line_type }) =>
    [SDM18HCN, SDM18SP2, SDM19DEE, SDM17DEC, SDM19STM].includes(type) &&
    line_type === CLAMP;

export const isFlowMeter_measures = ({ type, line_type }) =>
    [SDM18HCN, SDM18SP2].includes(type) && line_type === FLOWMETER;

// Securities
export const isSecurity = product =>
    isSmokeSensor_securities(product) ||
    isWaterSensor_securities(product) ||
    isDoorSensor_securities(product) ||
    isMotionSensor_securities(product);

export const isSmokeSensor_securities = ({ type }) => type === SDM19DAF;

export const isWaterSensor_securities = ({ type }) => type === SDM19WTS;

export const isDoorSensor_securities = ({ type }) => type === SDM19DRS;

export const isMotionSensor_securities = ({ type }) => type === SDM19PIR;

// Shutters
export const isShutter = product => isRollingShutter_shutters(product);

export const isRollingShutter_shutters = ({ type }) =>
    [SDM18MVR, SDM22MVR].includes(type);

// Misc
export const isFavorite = product => product.favorite;

export const productIsType = (product, type) =>
    product.type === type || product.product_type === type;

export const productIsInCategory = (product, categoryId) =>
    product?.categories?.some(
        category => String(category.id) === String(categoryId),
    );

export const isNotLinkedClamp = product => !product.clamp_master;

export const isSlaveClamp = (product, moduleKey) =>
    product.clamp_master === moduleKey;

export const isInelio = ({ type }) => type === SDM19INL;
