import ListItem from 'data/components/ListItem';
import { makeStyles } from '@mui/styles';
import Slider from 'data/components/Slider';
import Grid from 'data/components/Grid';
import React, { useState } from 'react';
import ProductLabel from '../ProductLabel';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import Button from '../../../../Button';
import { setBSODirection } from '../../../../../../MiddlewareSocket/emitters/widgets';

export default function Shutter({ product, setOpenModal }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const setBsoDirection = async (product, direction) => {
        try {
            setLoading(true);
            await setBSODirection(
                product.product_identifier,
                product.product_type,
                product.code,
                direction,
            );
        } catch (e) {
            console.log('🌟 ==>e', e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ListItem>
            <Grid container alignItems="center">
                <Grid item xs={4}>
                    <ProductLabel product={product} />
                </Grid>
                <Grid item xs={8} className={classes.container}>
                    <Grid
                        container
                        justifyContent="flex-end"
                        spacing={2}
                        alignItems="center"
                    >
                        {product.type === 'SDM18MVR' ? (
                            <Grid
                                item
                                container
                                spacing={2}
                                className={classes.slider}
                            >
                                <div className={classes.buttons}>
                                    <Button
                                        color={'#000'}
                                        onClick={async () => {
                                            await setBsoDirection(
                                                product,
                                                'down',
                                            );
                                        }}
                                        loading={loading}
                                    >
                                        <RotateLeftIcon />
                                    </Button>
                                    <Button
                                        color={'#000'}
                                        onClick={async () => {
                                            await setBsoDirection(
                                                product,
                                                'up',
                                            );
                                        }}
                                        loading={loading}
                                    >
                                        <RotateRightIcon />
                                    </Button>
                                </div>

                                <Slider product={product} />
                            </Grid>
                        ) : (
                            <Grid item>
                                <Slider product={product} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}

const useStyles = makeStyles(theme => ({
    container: { paddingRight: 4 },
    slider: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 30,
    },
}));
