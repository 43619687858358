import Http from 'utils/Http';

const api = {
    all: () =>
        Http.get('planning/all').then(resp => {
            return resp;
        }),
    create: () => {},
    delete: () => {},
    update: () => {},
};

export default api;
