import BaseHttp from './BaseHttp';
import { config } from '../config';
import { v4 as uuidv4 } from 'uuid';
import { getFromLS, setToLS } from './storage';

class Http extends BaseHttp {
    constructor() {
        /*        super({
            params: {
                XDEBUG_SESSION_START: 'PHPSTORM',
            },
        });*/
        super();
        this.setRootUrl(config.API_URL);
        let deviceId = getFromLS('deviceId');
        if (deviceId === undefined) {
            deviceId = uuidv4();
            setToLS('deviceId', deviceId);
        }

        this.setHeader('Device-Id', deviceId);
    }

    _parseError(e) {
        console.group('HTTP ERROR');
        console.log('http exception:', e);
        console.log('response', e.response);
        console.log('data', e.response?.data);
        console.groupEnd();

        super._parseError(e);
    }
}

export default new Http();
