import { isHeatAndCool_temperature } from 'store/products/utils/predicates';
import moment from 'moment';
import Tooltip from '../data/components/Tooltip';
import t from 'utils/Translator';
import Http from './Http';
import React from 'react';

export const getModuleKey = data => {
    let moduleKey = `fhe_product_${data.product_identifier}`;
    if (data.code) {
        moduleKey += `_${data.code}`;
    }
    return moduleKey;
};

export const getPlanningEventId = ({ num_of_day, event_time, ...task }) => {
    return `${getModuleKey(task)}_${num_of_day}_${event_time}`;
};

export const getWeather = weather => {
    weather = weather?.weather || [];
    return weather.map((x, i) => ({ ...x, id: `day${i}` }));
};

export const getModuleChanges = product => {
    const { measures } = product;
    const { clamp, relay } = measures;
    const changes = [];
    const moduleKey = getModuleKey(product);

    for (const [key, value] of Object.entries(clamp)) {
        const change = {
            id: `${moduleKey}_${key}`,
            changes: {
                instant: value,
            },
        };
        changes.push(change);
    }
    for (const [key, value] of Object.entries(relay)) {
        const change = {
            id: `${moduleKey}_${key}`,
            changes: {
                changes: {
                    instant: value,
                },
            },
        };
        changes.push(change);
    }

    return changes;
};

export const getTemp = product => {
    let temp =
        product?.last_data?.instant?.temp_out ||
        product?.last_data?.instant?.temp_in;
    if (temp) {
        return temp.toFixed(1) + '°C';
    }
};

export const getMode = product => (product.clim_mode === 'COLD' ? 'F' : 'C');

export const isDisconnected = product => {
    return product?.fhe_product_status === 'disconnected';
};

export const reduceModuleKeys = (products, predicatesArray) => {
    return products.reduce((acc, product) => {
        const results = predicatesArray.map(predicate => predicate(product));
        if (results.every(bool => bool)) {
            acc.push(getModuleKey(product));
        }
        return acc;
    }, []);
};

export const reduceProducts = (products, predicatesArray) => {
    return products.reduce((acc, product) => {
        const results = predicatesArray.map(predicate => predicate(product));
        if (results.every(bool => bool)) {
            acc[getModuleKey(product)] = product;
        }
        return acc;
    }, {});
};

export const productValueToBool = product => {
    if (isHeatAndCool_temperature(product)) {
        return product?.clim_state === 'ON';
    }
    return product?.value === 'ON';
};
export const boolToProductValue = bool => (bool ? 'ON' : 'OFF');

export const round10 = value => Math.round(Number(value) / 10) * 10;

export const getDate = (date, format = 'dddd DD MMMM YYYY') => {
    if (date) return moment(date).format(format);
    return moment().format(format);
};

export const getCurrentWeatherWindow = weather => {
    const index = parseInt(moment().format('H') / 3);
    return weather?.hourly[index];
};

export const getWeatherDescription = weather => {
    return weather.lang_fr[0].value;
};

export const withTooltip = (component, title) => {
    return <Tooltip title={t.get(title)}>{component}</Tooltip>;
};

export const capitalize = str =>
    str && str.charAt(0).toUpperCase() + str.substring(1);

export const warningsFilter = () => {
    const backup = console.warn;
    console.warn = function filterWarnings(msg) {
        const suppressedWarnings = ['componentWillReceiveProps'];

        if (!suppressedWarnings.some(entry => msg.includes(entry))) {
            backup.apply(console, arguments);
        }
    };
};

export const setTokenToHttpHeader = token => {
    Http.setHeader('Authorization', 'Access-Token ' + token);
};

export const setTokenToStorages = (rememberMe, token) => {
    if (rememberMe) localStorage.setItem('token', token);
    else sessionStorage.setItem('token', token);
};

export const getTokenFromStorages = () => {
    let token = localStorage.getItem('token');
    if (!token) {
        token = sessionStorage.getItem('token');
    }
    return token;
};

export const removeTokenFromStorages = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
};

export const sanitizeNullValues = string => {
    return string === 'null' || string === '' ? null : string;
};
