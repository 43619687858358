import MUIList from '@mui/material/List';
import React from 'react';
import { ListSubheader } from '@mui/material';

export default function List({ subheader, ...props }) {
    if (subheader) {
        return (
            <MUIList
                subheader={
                    <ListSubheader
                        id="nested-list-subheader"
                        sx={{
                            backgroundColor: theme =>
                                theme.palette.background.default,
                        }}
                    >
                        {subheader}
                    </ListSubheader>
                }
                {...props}
            />
        );
    }
    return <MUIList {...props} />;
}
