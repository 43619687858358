import React, { createContext, useEffect, useState } from 'react';
import { Slide, Snackbar } from '@mui/material';
import Paper from '../Paper';
import { makeStyles } from '@mui/styles';
import Grid from '../Grid';
import { Cancel, Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export const SnackbarContext = createContext(null);

export default function SnackbarProvider(props) {
    const authError = useSelector(state => state.auth.error);
    const productsError = useSelector(state => state.products.error);
    // const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        const error = authError || productsError;
        if (error) {
            setIsOpen(true);
            setContent(error);
        }
    }, [authError, productsError]);

    const onClose = (e, reason) => {
        if (reason === 'clickaway') return;
        setIsOpen(false);
        setContent(null);
    };

    return (
        <SnackbarContext.Provider value={{ setIsOpen, onClose }}>
            {props.children}
            <Snackbar
                key={Date.now()}
                open={isOpen}
                onClose={onClose}
                TransitionComponent={TransitionUp}
                // className={classes.snackBar}
                autoHideDuration={5000}
            >
                <div
                    // className={classes.container}
                >
                    <Paper elevation={10}
                           // className={classes.paper}
                    >
                        <Grid
                            container
                            alignContent="center"
                            // className={classes.gridContainer}
                        >
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                xs={1}
                            >
                                <Cancel color="error" />
                            </Grid>
                            <Grid item container direction="column" xs={10}>
                                <Grid item
                                      // className={classes.title}
                                >
                                    Erreur
                                </Grid>
                                <Grid item
                                      // className={classes.message}
                                >
                                    {content || "Une erreur s'est produite"}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                xs={1}
                                // className={classes.message}
                                onClick={onClose}
                            >
                                <Close />
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Snackbar>
        </SnackbarContext.Provider>
    );
}

// const useStyles = makeStyles(theme => {
//     console.log('ayayaya', theme)
//     return {
//         container: {
//             width: 600,
//             height: 70,
//             display: 'flex',
//             justifyContent: 'center',
//         },
//         gridContainer: {
//             width: '100%',
//             height: '100%',
//         },
//         snackBar: {
//             width: '100%',
//         },
//         paper: {
//             width: '100%',
//             height: '100%',
//             borderLeft: '4px solid #f44336',
//             borderTopLeftRadius: 2,
//             borderBottomLeftRadius: 2,
//         },
//         title: { fontWeight: 'bold' },
//         message: { fontWeight: 'normal', color: 'grey' },
//     };
// });
