import React from 'react';
import InputWrapper from '../InputWrapper';
import Grid from 'data/components/Grid';
import InputSwitch from 'data/components/InputSwitch';
import t from 'utils/Translator';

export default function InputReverse({ formik }) {
    return (
        <InputWrapper>
            <Grid
                container
                alignItems="flex-end"
                justifyContent="space-between"
            >
                <div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                    {t.get('inputs.reverse')}
                </div>
                <InputSwitch
                    name="reverse_polarity"
                    label="reverse_polarity"
                    onChange={formik.handleChange}
                    value={formik.values.reverse_polarity}
                    checked={formik.values.reverse_polarity}
                />
            </Grid>
        </InputWrapper>
    );
}
