import { useSelector } from 'react-redux';
import { weathersSelectors } from 'store/weather/weatherSlice';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from 'data/components/Grid';
import Text from 'data/components/Text';
import WeatherIcon from 'data/components/WeatherIcon';
import React from 'react';
import { capitalize, getDate, getWeatherDescription, withTooltip } from 'utils';
import t from 'utils/Translator';
import Sun from 'navigation/screens/Weathers/components/Sun';
import Tooltip from 'data/components/Tooltip';

export default function WeatherContent(props) {
    const classes = useStyles();
    const currentDay = useSelector(state =>
        weathersSelectors.selectById(state, 'day0'),
    );
    const currentWeather = useSelector(weathersSelectors.selectCurrentWeather);
    const history = useHistory();

    if (!currentWeather) return null;
    return (
        <Grid container className={classes.container} spacing={2}>
            <Grid
                item
                xs={6}
                md={4}
                container
                direction="column"
                alignItems="center"
                onClick={() => history.push('/weather')}
                className={classes.leftBlock}
            >
                {withTooltip(
                    <div>
                        <WeatherIcon
                            code={currentWeather.weatherCode}
                            height={100}
                            fill="white"
                        />
                    </div>,
                    getWeatherDescription(currentWeather),
                )}
                <Text
                    variant="h3"
                    color="secondary"
                >{`${currentWeather.tempC}°C`}</Text>
            </Grid>
            <Grid item xs={6} md={8} alignItems="flex-end" container>
                <Grid item container xs={12}>
                    <Text variant="h4" color="secondary">
                        {capitalize(getDate())}
                    </Text>
                </Grid>
                <Grid container alignItems="flex-end" item xs={12}>
                    <Tooltip title={t.get('weathers.cloudCover')}>
                        <div className={classes.cloudCover}>
                            <WeatherIcon code="116" height={32} fill="white" />
                            <Text
                                color="secondary"
                                variant="h6"
                            >{`${currentWeather.cloudcover}%`}</Text>
                        </div>
                    </Tooltip>
                    <Sun
                        variant="h6"
                        color="secondary"
                        astronomy={currentDay?.astronomy[0]}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: 50,
    },
    text: {
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    cloudCover: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        marginRight: 12,
    },
    leftBlock: { cursor: 'pointer' },
}));
