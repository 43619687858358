import {
    FormControlLabel,
    Radio,
    RadioGroup as MUIRadioGroup,
} from '@mui/material';
import React from 'react';

export default function RadioGroup({ radios, ...props }) {
    return (
        <MUIRadioGroup {...props}>
            {radios.map(({ value, label }) => (
                <FormControlLabel
                    key={label}
                    control={<Radio color="primary" />}
                    label={label}
                    value={value}
                />
            ))}
        </MUIRadioGroup>
    );
}
