import React, { useState } from 'react';
import themes from 'theme';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

export const CustomThemeContext = React.createContext(null);

const CustomThemeProvider = props => {
    const [theme, setTheme] = useState('light');

    return (
        <CustomThemeContext.Provider value={{ setTheme, theme }}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes[theme]}>
                    <CssBaseline />
                    {props.children}
                </ThemeProvider>
            </StyledEngineProvider>
        </CustomThemeContext.Provider>
    );
};
export default CustomThemeProvider;
