import React from 'react';
import Divider from 'data/components/Divider';

export default function FormDivider() {
    return (
        <div style={{ width: '100%', margin: '16px 0' }}>
            <Divider />
        </div>
    );
}
