import * as React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fafafa',
    height: '100%',
    width: '100%',
};

function AFKIcon(props) {
    return (
        <svg
            height={props.height}
            width={props.width}
            viewBox="0 0 300 290.99"
            {...props}
        >
            <g fill={props.color}>
                <path
                    fill={props.color}
                    d="M287.86 232.25c-.41-2.67-3-5.13-5.38-7.23-6.79-6-13.58-11.76-20.77-17.87l-7.53-6.41s9.17-32.28 12-42.18c3.56-12.48 2-15.41-10.68-19.6-7.22-2.4-13.53-.91-20.22.66l-.74.18c-17.63 4.13-30 13.06-37.71 27.3a29.45 29.45 0 01-3.26 4.5c-.79 1-1.61 2-2.34 3-4.05 5.81-3.55 11.24 1.39 14.9 4.5 3.33 10.22 2.23 14.23-2.72 2.8-3.46 9.91-13.08 9.91-13.08 7.19-9.69 10.67-11.84 23.62-14-.81 2.85-6.08 22-8.23 28.49-3.57 10.84-.88 19.68 8.22 27 3.69 3 19.53 16.77 24.06 20.31 3.43 2.67 5.13 5.84 5.66 10.6 1 8.83 2.1 17.8 3.18 26.47l.91 7.31a25.11 25.11 0 00.65 3.17c1.31 5.16 4.53 7.92 9.15 7.92a12.51 12.51 0 001.8-.13c5.85-.82 9.09-4.84 8.45-10.49-1.99-17.41-4.07-33.15-6.37-48.1z"
                />
                <path
                    fill={props.color}
                    d="M228.25 209.76c-.4.64-.82 1.28-1.25 1.94-1 1.47-24.17 40.17-33.5 55.93-4.94 8.33-1.56 12.91 2.13 15.28a9.76 9.76 0 005.28 1.67 8.42 8.42 0 001.86-.21c2.82-.66 5.4-2.83 7.48-6.27 7.86-13.06 29.77-49.73 32.88-54.94l.31-.52L228.73 209zM292.18 176.58c-5.41-1.2-25-4.6-25-4.6l-5.69 19.21s21.28 3.94 27.18 4.79a10.78 10.78 0 001.69.13 9.35 9.35 0 009.57-8.46c.69-5.78-2.12-9.81-7.75-11.07zM266 138.1a16.82 16.82 0 0016.88-16.62 16.25 16.25 0 00-4.77-11.57 16.91 16.91 0 00-12.06-5 16.74 16.74 0 00-16.85 16.53 16.5 16.5 0 004.8 11.67 16.79 16.79 0 0012 4.99z"
                />
                <g fill={props.color}>
                    <path
                        fill={props.color}
                        d="M161 81.38c0-.16-.12-.3-.14-.46a4.4 4.4 0 00-.58-1.71 10.82 10.82 0 00-2.45-2.91c-4.16-3.36-59.36-47.82-60.43-48.6a6 6 0 00-3.24-1 2.46 2.46 0 00-.6 0 7 7 0 00-3.31 1.41c-4.57 3.51-57.45 43.02-64.47 48.26a10.2 10.2 0 00-2.61 3 3.51 3.51 0 00-.33.77 8.39 8.39 0 00-.42 2.49v79.72a2.17 2.17 0 000 .44 4.77 4.77 0 00.17.93 6 6 0 002.07 2.66 4.44 4.44 0 002.39 1.1H68.42a3.84 3.84 0 001.33-.38 8.77 8.77 0 002-1.58 4.85 4.85 0 001.33-3.37V113.1v-.31a.63.63 0 01.18-.48 1.2 1.2 0 011-.42h39.49a1.08 1.08 0 00.18 0 .72.72 0 01.51.17 1.07 1.07 0 01.47.94v49.71a3.54 3.54 0 00.47 1.65 6.83 6.83 0 001.67 1.93 4.7 4.7 0 002.5 1.18h36.92a3.67 3.67 0 001.37-.4 8.42 8.42 0 002-1.56 4.87 4.87 0 001.34-3.37c-.09-26.56-.18-80.43-.18-80.76z"
                    />
                    <path
                        fill={props.color}
                        d="M186 71.58c-.1-.61-.31-1.19-.47-1.79a7.61 7.61 0 00-2.49-3.7 8 8 0 00-3.41-1.88 11.38 11.38 0 00-3.16-.54 1.22 1.22 0 01-.95-.37c-.27-.26-.56-.51-.85-.75C173.89 61.93 97.5.61 95.87.31A13.42 13.42 0 0094.09 0a7.41 7.41 0 00-3 .61c-1.29.51-17.3 12.83-17.7 13.12l-.12.09v-.09-8.88a3.17 3.17 0 00-.45-1.72A6.42 6.42 0 0068.45 0H48.32A7.61 7.61 0 0047 .45a7 7 0 00-2 1.57 4.75 4.75 0 00-1.33 3.35c0 8.2.07 29.55.07 30.78 0 .16-27.85 21.85-28.57 22.39l-2.81 2.09a.7.7 0 01-.68.13 6.45 6.45 0 00-1.91-.28 12 12 0 00-2.86.41 9.59 9.59 0 00-3.6 1.86 8.88 8.88 0 00-2 2.36 6.18 6.18 0 00-.89 2.23c-.09.51-.22 1-.33 1.51v1.47a2.27 2.27 0 01.06.25A9.42 9.42 0 00.68 73a8.43 8.43 0 002 2.92 9.75 9.75 0 004.67 2.47 10.42 10.42 0 004.81-.08 8.87 8.87 0 003.54-1.76A8.5 8.5 0 0018.82 72a.83.83 0 01.26-.44c.74-.58 1.5-1.14 2.25-1.71s71.18-53.75 72.4-54.64a.19.19 0 01.09 0c.92.72 71.15 55.93 71.66 56.44a7.77 7.77 0 00.74.54c.17.12.33.26.5.39a.5.5 0 01.21.46 8.44 8.44 0 00.51 2.53 2.23 2.23 0 00.16.59c.25.47.51.94.81 1.39a9 9 0 004.75 3.64c.53.18 1.1.26 1.65.4a7.41 7.41 0 003 .09 9.94 9.94 0 004.62-1.84 8.52 8.52 0 003.27-4.68c.08-.3.14-.6.23-.9a6.23 6.23 0 00.07-2.68z"
                    />
                </g>
                <path
                    fill={props.color}
                    d="M176.7 288.82c-4.77 0-9.42-.2-13.81-.61a7.5 7.5 0 011.4-14.94c3.92.37 8.1.55 12.42.55a7.5 7.5 0 110 15zM139.25 283a7.28 7.28 0 01-2.58-.46 72.65 72.65 0 01-23.79-14.08 7.51 7.51 0 0110.05-11.15 57.53 57.53 0 0018.9 11.15 7.5 7.5 0 01-2.58 14.54zm-36-32.61a7.51 7.51 0 01-6.78-4.28 100.6 100.6 0 01-8-25.71 7.5 7.5 0 1114.76-2.66 85.92 85.92 0 006.77 21.93 7.49 7.49 0 01-3.55 10 7.41 7.41 0 01-3.22.72zm-9.76-48.7a7.5 7.5 0 01-7.49-7.26c-.12-3.46-.1-17.25-.1-19.25a7.5 7.5 0 0115 0c0 2 0 15.47.09 18.76a7.51 7.51 0 01-7.26 7.74z"
                />
            </g>
        </svg>
    );
}

AFKIcon.propTypes = propTypes;
AFKIcon.defaultProps = defaultProps;

export default AFKIcon;
