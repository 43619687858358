import Http from 'utils/Http';

const Stats = {
    getPrevisionalStats: (homeId, day) =>
        Http.get('stats/previsional-prod', { home_id: homeId, day }).then(
            resp => {
                return {
                    detail: resp.detail,
                    global: resp.global,
                };
            },
        ),
    getHomeNetworkStats: (homeId, by) =>
        Http.get('stats/home-network-stats', {
            home_id: homeId,
            by,
        }).then(resp => {
            return resp;
        }),
    getStatsWeb: (details, by, prod) =>
        Http.get('stats/get-stats-web', {
            details,
            by,
            prod,
        }).then(resp => {
            return resp;
        }),
    getProductStats: (product_type, product_identifier, code, timestamp, by) =>
        Http.get('stats/product-module', {
            product_type,
            product_identifier,
            code,
            timestamp,
            by,
        }).then(resp => {
            return resp;
        }),
};

export default Stats;
