import MUIButton from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import React from 'react';

export default function Button({ loading, ...props }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <MUIButton
                    {...props}
                    color="primary"
                    disabled={loading || props.disabled}
                />
                {loading && (
                    <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                    />
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    wrapper: {
        position: 'relative',
        width: '100%',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
