import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const FinaliteIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 300 300">
            <g fill={props.color}>
                <path d="M248.54,113.67l-.58,0h-.29l-13.74-1.06-5.14,5.12A110.18,110.18,0,0,1,242,170c0,61.07-49.9,110.76-111.23,110.76S19.44,231,19.44,170,69.35,59.19,130.68,59.19a111.33,111.33,0,0,1,52.53,13.12l4-3.93-1.25-16.23v-.07a129.69,129.69,0,0,0-55.26-12.17C58.61,39.91,0,98.25,0,170S58.62,300,130.68,300s130.69-58.34,130.69-130A129.89,129.89,0,0,0,248.54,113.67Z" />
                <path d="M70.79,169.92a59.9,59.9,0,1,0,118.39-12.83l-13.76,13.69a44.76,44.76,0,1,1-45.52-45.43l13.68-13.62a60,60,0,0,0-72.79,58.19Z" />
                <path d="M274.38,46.2,282.65,38a9.55,9.55,0,0,0,0-13.57l-5.73-5.74a9.62,9.62,0,0,0-13.61,0l-9.47,9.43L251.89,3a3.2,3.2,0,0,0-3.26-3,3.16,3.16,0,0,0-2.27.94L209.09,38a16.19,16.19,0,0,0-4.72,12.7l.1,1.46,1.79,23.23L192.78,88.83,168.54,113l-.56.55-23.46,23.36L134.1,147.25a7.94,7.94,0,0,0-2.34,5.08l-.65,8.13a8.11,8.11,0,0,0,8.1,8.75h.42l8.63-.42a8.07,8.07,0,0,0,5.34-2.36l10.31-10.27,23.47-23.36.55-.55,24.25-24.14,14.65-14.58,21.28,1.62.95.07c.42,0,.84,0,1.23,0a16.45,16.45,0,0,0,11.53-4.73l37.23-37.06A3.23,3.23,0,0,0,297,48Z" />
            </g>
        </svg>
    );
};

FinaliteIcon.propTypes = propTypes;
FinaliteIcon.defaultProps = defaultProps;

export default FinaliteIcon;
