import { combineReducers } from '@reduxjs/toolkit';
import { weathersReducer } from './weather/weatherSlice';
import { productsReducer } from './products/productsSlice';
import { planningEventsReducer } from './planningEvents/planningEventsSlice';
import { messagesReducer } from './messages/messagesSlice';
import { statsReducer } from './stats/statsSlice';
import { generatorsReducer } from './generators/generatorSlice';
import { categoriesReducer } from './categories/categoriesSlice';
import authReducer from './auth/authSlice';
import { boxReducer } from './box/boxSlice';

const combinedReducers = combineReducers({
    weathers: weathersReducer,
    generators: generatorsReducer,
    products: productsReducer,
    planningEvents: planningEventsReducer,
    messages: messagesReducer,
    stats: statsReducer,
    categories: categoriesReducer,
    auth: authReducer,
    box: boxReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'counter/logout') {
        state = undefined;
    }
    return combinedReducers(state, action);
};

export default rootReducer;
