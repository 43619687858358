import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProductModalTabs from './components/ProductModalTabs';

export default function ProductModal({ open, setOpen, product }) {
    const classes = useStyles();
    const [maxWidth, setMaxWidth] = useState('sm');

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            disableScrollLock
            className={classes.container}
            maxWidth={maxWidth}
        >
            <ProductModalTabs product={product} setMaxWidth={setMaxWidth} />
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    container: {},
}));
