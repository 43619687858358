import React, { useEffect } from 'react';
import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectEventsByProduct } from '../../../../../../store/planningEvents/planningEventsSlice';
import Button from '../../../../Button';
import PlanningCell from '../../../../../../navigation/screens/Plannings/components/PlanningCell';
import MUITableCell from '@mui/material/TableCell';

const renderEvents = (planningByHour, hour, column) => {
    return (
        <TableCell component="th" sx={{ padding: 0 }}>
            {planningByHour[hour].map(event => {
                return event.num_of_day === column ? (
                    <Button key={event.product_identifier} onClick={() => {}}>
                        <PlanningCell planningEvent={event} planningModal />
                    </Button>
                ) : (
                    <div />
                );
            })}
        </TableCell>
    );
};

export default function PlanningTab({ product, setMaxWidth }) {
    const classes = useStyles();
    const productEvents = useSelector(state =>
        selectEventsByProduct(state, product),
    );

    const planningHours = [
        ...new Set(productEvents.map(event => event.event_time)),
    ];

    const planningByHours = productEvents.reduce((acc, it) => {
        if (!acc[it.event_time]) {
            acc[it.event_time] = [];
        }

        acc[it.event_time].push(it);

        return acc;
    }, []);

    useEffect(() => {
        setMaxWidth('lg');
    }, []);

    const columns = [
        { id: '0', label: 'Lundi' },
        { id: '1', label: 'Mardi' },
        { id: '2', label: 'Mercredi' },
        { id: '3', label: 'Jeudi' },
        { id: '4', label: 'Vendredi' },
        { id: '5', label: 'Samedi' },
        { id: '6', label: 'Dimanche' },
    ];

    return (
        <TableContainer sx={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell key={7} align={'left'}>
                            Horaires
                        </StyledTableCell>
                        {columns.map(column => (
                            <StyledTableCell key={column.id} align={'left'}>
                                {column.label}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {planningHours.map(hour => (
                        <TableRow>
                            <StyledTableCell
                                key={hour}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 18,
                                    border: 0,
                                }}
                            >
                                {hour}
                            </StyledTableCell>
                            {columns.map(column =>
                                renderEvents(planningByHours, hour, column.id),
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const StyledTableCell = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        width: 200,
        fontSize: 18,
    },
}))(MUITableCell);

const useStyles = makeStyles(props => ({
    hours: {
        display: 'flex',
        marginTop: 24,
        fontSize: 21,
    },
    tableContainer: {
        maxHeight: '100%',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
        },
        scrollbarColor: 'transparent transparent',
    },
}));
