import MUISwitch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { boolToProductValue, isDisconnected, productValueToBool } from 'utils';
import { setSwitchState } from 'MiddlewareSocket/emitters/widgets';
import { useAsync } from 'utils/hooks/useAsync';
import React from 'react';

export default function GroupInputSwitch({ product, func, ...props }) {
    const { execute, error, isLoading } = useAsync(setSwitchState);
    const [value, setValue] = useState(productValueToBool(product));

    useEffect(() => {
        setValue(productValueToBool(product));
    }, [product?.value, product?.clim_state]);

    useEffect(() => {
        if (error) {
            setValue(productValueToBool(product));
        }
    }, [error]);

    const handleChange = async ({ target: { checked } }) => {
        setValue(checked);
        await execute(product, boolToProductValue(checked));
    };

    return (
        <MUISwitch
            {...props}
            checked={value}
            onChange={handleChange}
            disabled={isLoading || isDisconnected(product)}
            color="primary"
            edge="end"
        />
    );
}
