import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import Stats from 'data/stats/api';

const adapter = createEntityAdapter();
const selectors = adapter.getSelectors(({ stats }) => stats);

export const statsSlice = createSlice({
    name: 'stats',
    initialState: {
        autoConsoRate: 0,
        autoConsoValue: 0,
        prodPV: 0,
        exported: 0,
        exportType: 'renvoi',
        consoFoyer: 0,
        imported: 0,
    },
    reducers: {
        setHomeNetworkStats: (state, action) => {
            state.autoConsoRate = action.payload.autoConsoRate;
            state.autoConsoValue = action.payload.autoConsoValue;
            state.consoFoyer = action.payload.consoFoyer;
            state.exportType = action.payload.exportType;
            state.exported = action.payload.exported;
            state.imported = action.payload.imported;
            state.prodPV = action.payload.prodPV;
        },
    },
});

export const { setConsoProdStats } = statsSlice.actions;
export const { setHomeNetworkStats } = statsSlice.actions;

export const selectHomeNetworkStats = state => ({
    autoConsoRate: state.stats.autoConsoRate,
    autoConsoValue: state.stats.autoConsoValue,
    prodPV: state.stats.prodPV,
    exported: state.stats.exported,
    exportType: state.stats.exportType,
    consoFoyer: state.stats.consoFoyer,
    imported: state.stats.imported,
});

export const statsReducer = statsSlice.reducer;
export const statsActions = statsSlice.actions;
export const statsSelectors = { ...selectors };

export const getHomeNetworkStats = (details, by, prod) => async dispatch => {
    const HomeNetworkStats = await Stats.getStatsWeb(details, by, prod);

    dispatch(setHomeNetworkStats(HomeNetworkStats));
};
