import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const adapter = createEntityAdapter();
const selectors = adapter.getSelectors(({ categories }) => categories);
const slice = createSlice({
    name: 'categories',
    initialState: adapter.getInitialState(),
    reducers: {
        setAll: adapter.setAll,
    },
});

export const categoriesActions = slice.actions;
export const categoriesReducer = slice.reducer;
export const categoriesSelectors = { ...selectors };
