import React, { useEffect, useState } from 'react';
import ListItem from 'data/components/ListItem';
import ListItemText from 'data/components/ListItemText';
import Grid from 'data/components/Grid';
import ProductLabel from '../ProductLabel';

export default function Security({ product, setOpenModal }) {
    const [value, setValue] = useState('idle');

    useEffect(() => {
        setValue(product.alarm_state);
    }, [product.alarm_state]);

    const getAlarmState = () => {
        return value === 'idle' ? 'OK' : 'ALERTE';
    };

    return (
        <ListItem>
            <ListItemText>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6}>
                        <ProductLabel product={product} />
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={6}>
                        {getAlarmState()}
                    </Grid>
                </Grid>
            </ListItemText>
        </ListItem>
    );
}
