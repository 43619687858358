import {
    Table,
    TableBody,
    TableCell as MUITableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import t from 'utils/Translator';
import { makeStyles, withStyles } from '@mui/styles';
import { getCurrentWeatherWindow, withTooltip } from 'utils';
import { ReactComponent as Cloud } from './svgs/wi-cloud.svg';
import { ReactComponent as Humidity } from './svgs/wi-raindrop.svg';
import { ReactComponent as Wind } from './svgs/wi-strong-wind.svg';
import { ReactComponent as Thermometer } from './svgs/wi-thermometer.svg';
import { ReactComponent as Direction } from './svgs/wi-wind-deg.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Text from '../Text';
import React from 'react';

export default function TableWeathers({ weather, isCurrentDay }) {
    const classes = useStyle({ isCurrentDay });
    const currentWindow = getCurrentWeatherWindow(weather);

    const getRisks = hourly => {
        const risksKeys = [
            'fog',
            'frost',
            'hightemp',
            'overcast',
            'rain',
            'snow',
            'thunder',
            'windy',
        ];
        const risks = [];
        const riskLimit = 70;
        risksKeys.forEach(key => {
            const value = hourly[`chanceof${key}`];
            if (value > riskLimit) {
                risks.push(t.get(`weathers.${key}`));
            }
        });
        return <Text variant="caption">{risks.join(', ')}</Text>;
    };

    const hourlies = weather.hourly.filter(
        x => x.time >= 600 && x.time <= 1800,
    );

    const height = 38;
    const heads = [''];
    const temp = {
        name: withTooltip(
            <Thermometer height={height} />,
            'weathers.temperature',
        ),
    };
    const humidity = {
        name: withTooltip(<Humidity height={height} />, 'weathers.humidity'),
    };
    const cloudcover = {
        name: withTooltip(<Cloud height={height} />, 'weathers.cloudCover'),
    };
    const wind = {
        name: withTooltip(<Wind height={height} />, 'weathers.windSpeed'),
    };
    const direction = {
        name: withTooltip(
            <Direction height={height} />,
            'weathers.windDirection',
        ),
    };
    const risks = {
        name: withTooltip(
            <ErrorOutlineIcon fontSize="large" />,
            'weathers.Risks',
        ),
    };

    hourlies.forEach(hourly => {
        heads.push(hourly.time);
        temp[hourly.time] = hourly.tempC + '°C';
        humidity[hourly.time] = hourly.humidity + '%';
        cloudcover[hourly.time] = hourly.cloudcover + '%';
        wind[hourly.time] = hourly.windspeedKmph + ' km/h';
        direction[hourly.time] = hourly.winddir16Point;
        risks[hourly.time] = getRisks(hourly);
    });
    const data = {
        heads: heads,
        rows: [temp, humidity, cloudcover, wind, direction, risks],
    };

    const className = (row, col, rowIndex) => {
        let className =
            rowIndex === 5 && col !== 'name' ? classes.red : classes.blank;
        if (col === currentWindow.time && rowIndex !== 5 && isCurrentDay) {
            className += ` ${classes.currentCell}`;
        }
        return className;
    };

    return (
        <TableContainer className={classes.container}>
            <Table>
                <TableHead>
                    <TableRow>
                        {data.heads.map(head => (
                            <TableCell
                                align="center"
                                className={
                                    head === currentWindow.time && isCurrentDay
                                        ? classes.currentHead
                                        : classes.heads
                                }
                                key={head}
                            >
                                {head && `${head / 100}h`}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {['name', '600', '900', '1200', '1500', '1800'].map(
                                col => (
                                    <TableCell
                                        align="center"
                                        key={col}
                                        className={className(
                                            row,
                                            col,
                                            rowIndex,
                                        )}
                                    >
                                        {row[col]}
                                    </TableCell>
                                ),
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const TableCell = withStyles(theme => ({
    root: {
        padding: theme.spacing(0.5),
        width: 54,
    },
}))(MUITableCell);

const useStyle = makeStyles(theme => ({
    container: {
        marginTop: ({ isCurrentDay }) => (isCurrentDay ? 0 : theme.spacing(2)),
    },
    heads: {
        fontSize: 18,
    },
    currentHead: {
        fontSize: 20,
        color: theme.palette.primary.main,
    },
    currentCell: {
        fontWeight: 500,
    },
    blank: {},
    red: { color: theme.palette.warning.main },
    primary: { color: theme.palette.primary.main },
}));
