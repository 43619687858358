import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import MUIAccordionDetails from '@mui/material/AccordionDetails';
import { useHistory } from 'react-router';

export default function Accordion(props) {
    const [shouldCollapse, setShouldCollapse] = useState(true);
    const history = useHistory();
    const menuArray = ['/account', '/support'];

    useEffect(() => {
        window.addEventListener(
            'scroll',
            e => {
                if (
                    shouldCollapse &&
                    !menuArray.includes(history.location.pathname)
                ) {
                    props.onChange(e, false);
                    setShouldCollapse(false);
                }
            },
            {
                once: true,
            },
        );
    }, [shouldCollapse]);

    useEffect(() => {
        window.addEventListener(
            'popstate',
            e => {
                if (
                    shouldCollapse &&
                    !menuArray.includes(history.location.pathname)
                ) {
                    props.onChange(e, false);
                    setShouldCollapse(false);
                }
            },
            {
                once: true,
            },
        );
    }, [shouldCollapse]);

    return (
        <AccordionWithStyles
            onChange={props.onChange}
            expanded={props.isExpanded}
            defaultExpanded
            square
        >
            <Summary>{props.header}</Summary>
            <Details>{props.content}</Details>
        </AccordionWithStyles>
    );
}

const AccordionWithStyles = withStyles(theme => ({
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    expanded: {},
}))(MUIAccordion);

const Summary = withStyles({
    root: {
        height: 82,
    },
})(MUIAccordionSummary);

const Details = withStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 300,
    },
}))(MUIAccordionDetails);
