import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    height: 20,
    width: 20,
};

const ConversationIcon = props => {
    return (
        <svg height={props.height} width={props.width} viewBox="0 0 249.9 300">
            <g fill={props.color}>
                <path d="M125,300A124.89,124.89,0,0,0,147.1,52.18L142,51.26V28.08h28.54A31.9,31.9,0,0,0,138.83,0H111.07A31.92,31.92,0,0,0,79.42,28.08h29.17v23.1l-5.18.9A124.89,124.89,0,0,0,125,300Zm0-232.37A107.42,107.42,0,1,1,17.53,175,107.54,107.54,0,0,1,125,67.63Z" />
                <path d="M247.54,76.11,226.26,54.83a3.78,3.78,0,0,0-2.71-1.14,3.91,3.91,0,0,0-2.72,1.13L208,67.65l26.71,26.7,12.82-12.83A3.83,3.83,0,0,0,247.54,76.11Z" />
                <path d="M36.38,175A88.57,88.57,0,0,0,213.3,181.3H118.24V86.75A88.76,88.76,0,0,0,36.38,175Z" />
            </g>
        </svg>
    );
};

ConversationIcon.propTypes = propTypes;
ConversationIcon.defaultProps = defaultProps;

export default ConversationIcon;
