import React from 'react';
import { useSelector } from 'react-redux';
import PlanningEvent from './PlanningEvent';
import List from 'data/components/List';
import { makeStyles } from '@mui/styles';
import { selectLastEvents } from '../../../../store/planningEvents/planningEventsSlice';
import ListSubheader from '@mui/material/ListSubheader';

const PlanningEvents = () => {
    const classes = useStyles();
    const planningEvents = useSelector(state => selectLastEvents(state));

    const lastEvents = Object.values(planningEvents.lastEvents);

    return (
        <List
            dense
            subheader={
                <ListSubheader className={classes.listSubheader}>
                    Actions Planifiées
                </ListSubheader>
            }
            className={classes.container}
        >
            {lastEvents.map((planningEvent, i) => (
                <PlanningEvent planningEvent={planningEvent} key={i} />
            ))}
        </List>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    listSubheader: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
    },
}));

export default PlanningEvents;
